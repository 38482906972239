import React from "react";
import Sidebar from "../Components/Sidebar";
import AddRoomType from "../Components/AddRoomsType";
import NewSidebar from "../Components/NewSidebar";

const RoomTypeAddPage = () => {
  return (
    <>
      <div className="main_container">
        <AddRoomType />
      </div>
    </>
  );
};

export default RoomTypeAddPage;
