import React from "react";
import Salutation from "../Components/Salutation";

const SalutationPage = () => {
  return (
    <>
      <div className="main_container">
        <Salutation />
      </div>
    </>
  );
};

export default SalutationPage;
