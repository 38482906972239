import React from "react";
import Sidebar from "../Components/Sidebar";
import Agent from "../Components/Agent";
import Agent2 from "../Components/Agent2";
import NewSidebar from "../Components/NewSidebar";

const AgentPage = () => {
  return (
    <>
      <div className="main_container">
        {/* <Agent /> */}
        <Agent2 />
      </div>
    </>
  );
};

export default AgentPage;
