import React from "react";
import ChannelDetails from "../Components/ChannelDetails";

const ChannelDetailsPage = () => {
  return (
    <div className="main_container">
      <ChannelDetails />
    </div>
  );
};

export default ChannelDetailsPage;
