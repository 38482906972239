import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  ChoiceList,
  RangeSlider,
  Button,
  Icon,
  Select,
  Page,
} from "@shopify/polaris";

import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";

const Purchase = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "Past 7 Days",
    "Past 30 Days",
    "Past 90 Days",
    "Select Date Range",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => { },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
          {
            type: "rename",
            onAction: () => { },
            onPrimaryAction: async (value) => {
              const newItemsStrings = tabs.map((item, idx) => {
                if (idx === index) {
                  return value;
                }
                return item.content;
              });
              await sleep(1);
              setItemStrings(newItemsStrings);
              return true;
            },
          },
          {
            type: "duplicate",
            onPrimaryAction: async (value) => {
              await sleep(1);
              duplicateView(value);
              return true;
            },
          },
          {
            type: "edit",
          },
          {
            type: "delete",
            onPrimaryAction: async () => {
              await sleep(1);
              deleteView(index);
              return true;
            },
          },
        ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];

  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => { };

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
        type: "save-as",
        onAction: onCreateNewView,
        disabled: false,
        loading: false,
      }
      : {
        type: "save",
        onAction: onHandleSave,
        disabled: false,
        loading: false,
      };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const active = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Active
    </div>
  );

  const expired = (
    <div
      style={{
        color: "#888",
        padding: "6px",
        backgroundColor: "#EEE",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Expired
    </div>
  );

  const scheduled = (
    <div
      style={{
        color: "#FFC107",
        padding: "6px",
        backgroundColor: "#FFF5DC",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Scheduled
    </div>
  );

  const orders = [
    {
      id: "1",
      invoice: "UTC-DEC-14",
      date: "2022-12-03",
      supplierName: "Usha Trading Co",
      totalAmount: "13562897",
    },
    {
      id: "2",
      invoice: "UTC-DEC-14",
      date: "2022-12-03",
      supplierName: "Usha Trading Co",
      totalAmount: "13562897",
    },
    {
      id: "3",
      invoice: "UTC-DEC-14",
      date: "2022-12-03",
      supplierName: "Usha Trading Co",
      totalAmount: "13562897",
    },
    {
      id: "4",
      invoice: "UTC-DEC-14",
      date: "2022-12-03",
      supplierName: "Usha Trading Co",
      totalAmount: "13562897",
    },
  ];

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const getItemChecked = (id) => {
    console.log(id);
  };

  getItemChecked(selectedResources[0]);

  const rowMarkup = orders.map(
    ({ id, invoice, date, supplierName, totalAmount, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{invoice}</IndexTable.Cell>
        <IndexTable.Cell>{date}</IndexTable.Cell>
        <IndexTable.Cell>{supplierName}</IndexTable.Cell>
        <IndexTable.Cell>{totalAmount}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    console.log("removed");
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [asignHotelChecked, setAsignHotelChecked] = useState(false);

  const asignHotelCheckboxHandleChange = useCallback(
    (newChecked) => setAsignHotelChecked(newChecked),
    []
  );

  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState(1);
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");

  const setPurchaseHeading = (idx) => {
    setPurchaseHeadingActiveIndex(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };

  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Purchase</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div>
              <div style={{ textDecoration: "none" }}>
                <BgOutlineThemeButton
                  onClick={() =>
                    setShowAddItemModelMenuActive(!showAddItemModelMenuActive)
                  }
                  children={"Issue Item"}
                />
              </div>
            </div>
            <div>
              <div style={{ textDecoration: "none" }}>
                <BgThemeButton
                  onClick={() =>
                    setShowAddStockModelMenuActive(!showAddStockModelMenuActive)
                  }
                  children={"Add Stock"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="purchase-details-section">
          <div className="purchase-details-section-div">
            <div className="purchase-details-section-div--number">95</div>
            <div className="purchase-details-section-div--text">
              Total Items
            </div>
          </div>
          <div className="purchase-details-section-div">
            <div className="purchase-details-section-div--number">99</div>
            <div className="purchase-details-section-div--text">
              Issued Items
            </div>
          </div>
          <div className="purchase-details-section-div">
            <div className="purchase-details-section-div--number">
              909708709715
            </div>
            <div className="purchase-details-section-div--text">
              Out of stock
            </div>
          </div>
        </div>

        <LegacyCard>
          <div
            style={{ marginLeft: "20px" }}
            className="booking-first-row-head"
          >
            <div
              onClick={() => setPurchaseHeading(1)}
              className={`booking-first-row-head-divs ${purchaseHeadingActiveIndex === 1 ? "active" : ""
                }`}
            >
              Past 7 Days
            </div>
            <div
              onClick={() => setPurchaseHeading(2)}
              className={`booking-first-row-head-divs ${purchaseHeadingActiveIndex === 2 ? "active" : ""
                }`}
            >
              Past 30 Days
            </div>
            <div
              onClick={() => setPurchaseHeading(3)}
              className={`booking-first-row-head-divs ${purchaseHeadingActiveIndex === 3 ? "active" : ""
                }`}
            >
              Upcoming 30 Days
            </div>
            <div
              onClick={() => setPurchaseHeading(4)}
              className={`booking-first-row-head-divs ${purchaseHeadingActiveIndex === 4 ? "active" : ""
                }`}
            >
              Select Date Range
            </div>
          </div>

          <IndexTable
            resourceName={resourceName}
            itemCount={orders.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={[
              { title: "Invoice No" },
              { title: "Date" },
              { title: "Supplier Name" },
              { title: "Total Amount" },
            ]}
          >
            {rowMarkup}
          </IndexTable>

          {/* Single select */}
          {showLowerMenuSingleSelect && (
            <div className=" room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>

                  <Link to={`/agent/editprofile`}>
                    <BgThemeButton children={"Edit"} />
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* Multiple select */}
          {showLowerMenuMultipleSelect && (
            <div className=" room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </LegacyCard>
      </div>

      {/* Add stock Button Click */}
      {showAddStockModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="stock-add-model-container">
              <div style={{ marginLeft: "-200px" }}>
                <Page
                  backAction={{
                    content: "Products",
                    url: `/hotelier/purchase`,
                  }}
                  title="Add Stock"
                  compactTitle
                ></Page>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <TextField
                    type="text"
                    className="textfield"
                    focused={false}
                    label="Supplier"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="date"
                    className="textfield"
                    focused={false}
                    label="Company Name"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="Text"
                    className="textfield"
                    focused={false}
                    label="Invoice Number"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    label="Item Name"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Price"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Weight"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Price/Unit"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Qty"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="GST%"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Discount%"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Total Amount"
                    autoComplete="off"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton children={"Add"} />
                  <Button onClick={() => setShowAddStockModelMenuActive(false)}>
                    Discard
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "30px",
                    }}
                  >
                    <div>Sub Total: XXXX</div>
                    <div>Grand Total: XXXX</div>
                    <div>Paid Amount: XXXX</div>
                    <div>Due Amount: XXXX</div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}

      {/* Add Item Button Click */}
      {showAddItemModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="stock-add-model-container">
              <div style={{ marginLeft: "-200px" }}>
                <Page
                  backAction={{
                    content: "Products",
                    url: `/hotelier/purchase`,
                  }}
                  title="Add Issue Items"
                  compactTitle
                ></Page>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <TextField
                    type="text"
                    className="textfield"
                    focused={false}
                    label="User Type"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="text"
                    className="textfield"
                    focused={false}
                    label="Issued To"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="date"
                    className="textfield"
                    focused={false}
                    label="Issued Date"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    label="Item Name"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    label="Select Category"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Qty"
                    autoComplete="off"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    width: "350px",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "350px",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <BgThemeButton children={"Update"} />
                <Button onClick={() => setShowAddItemModelMenuActive(false)}>
                  Cancel
                </Button>
              </div>
            </section>
          </div>
        </>
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="room-rate-plan-edit-model-container">
            <h4>Edit Rate Plan</h4>
            <div className="edit-rooms-form-container">
              <div className="form-container-div-full">
                <Select
                  options={planTypeOptions}
                  value={planTypeSelected}
                  onChange={planTypeHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Plan Type"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Rack Price"
                  autoComplete="off"
                  placeholder="6500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Bar Price"
                  autoComplete="off"
                  placeholder="7000.00"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Extra Adult"
                  autoComplete="off"
                  placeholder="850.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child With Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child Without Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button primary>Update</Button>
              <Button onClick={() => setShowEditModelMenuActive(false)}>
                Discard
              </Button>
            </div>
          </section>
        </>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={removeItemConfirm} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Purchase;
