import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";

const Suppliers = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => { },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
          {
            type: "rename",
            onAction: () => { },
            onPrimaryAction: async (value) => {
              const newItemsStrings = tabs.map((item, idx) => {
                if (idx === index) {
                  return value;
                }
                return item.content;
              });
              await sleep(1);
              setItemStrings(newItemsStrings);
              return true;
            },
          },
          {
            type: "duplicate",
            onPrimaryAction: async (value) => {
              await sleep(1);
              duplicateView(value);
              return true;
            },
          },
          {
            type: "edit",
          },
          {
            type: "delete",
            onPrimaryAction: async () => {
              await sleep(1);
              deleteView(index);
              return true;
            },
          },
        ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => { };

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
        type: "save-as",
        onAction: onCreateNewView,
        disabled: false,
        loading: false,
      }
      : {
        type: "save",
        onAction: onHandleSave,
        disabled: false,
        loading: false,
      };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const orders = [
    {
      id: "1",
      supplierName: "Usha Trading Co",
      mobileNo: "77788-89990",
      email: "example@email.com",
      gst: "02BQXPS1430A1Z4",
    },
    {
      id: "2",
      supplierName: "Swift Trades",
      mobileNo: "77788-89990",
      email: "example@email.com",
      gst: "02BQXPS1430A1Z4",
    },
    {
      id: "3",
      supplierName: "Usha Trading Co",
      mobileNo: "77788-89990",
      email: "example@email.com",
      gst: "02BQXPS1430A1Z4",
    },
    {
      id: "4",
      supplierName: "Swift Trades",
      mobileNo: "77788-89990",
      email: "example@email.com",
      gst: "02BQXPS1430A1Z4",
    },
  ];

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      // setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(false);
  };

  const getItemChecked = (id) => {
    console.log(id);
  };

  getItemChecked(selectedResources[0]);

  const rowMarkup = orders.map(
    ({ id, supplierName, mobileNo, email, gst, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{supplierName}</IndexTable.Cell>
        <IndexTable.Cell>{mobileNo}</IndexTable.Cell>
        <IndexTable.Cell>{email}</IndexTable.Cell>
        <IndexTable.Cell>{gst}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    console.log("removed");
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Ota Options
  const otasOptions = [
    { label: "Yatra", value: "Yatra" },
    { label: "Goibibo", value: "Goibibo" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
    { label: "Travel Guru", value: "Travel Guru" },
  ];

  const [otaNameSelected, setOtaNameSelected] = useState("Yatra");
  const otaNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Suppliers</div>
          <div>
            <BgThemeButton
              children={"Add Supplier"}
              onClick={addModalButtonClickHandler}
            />
          </div>
        </div>

        <LegacyCard>
          <IndexTable
            resourceName={resourceName}
            itemCount={orders.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={[
              { title: "Supplier Name" },
              { title: "Mobile Number" },
              { title: "Email" },
              { title: "GST NO." },
            ]}
          >
            {rowMarkup}
          </IndexTable>

          {/* Single select */}
          {showLowerMenuSingleSelect && (
            <div className=" room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div>
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
                <form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="room-type-bottom-div">
                    <div>Mobile Number</div>
                    <div style={{ width: "200px" }}>
                      <TextField
                        labelInline
                        options={otasOptions}
                        onChange={otaNameHandleSelectChange}
                      />
                    </div>
                  </div>
                  <div className="room-type-bottom-div">
                    <div>Email</div>
                    <div style={{ width: "200px" }}>
                      <TextField labelInline />
                    </div>
                  </div>
                  <BgThemeButton children={"Update"} />
                  <BgOutlineThemeButton children={"Edit More"} />
                </form>
              </div>
            </div>
          )}

          {/* Multiple select */}
          {showLowerMenuMultipleSelect && (
            <div className=" room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </LegacyCard>
      </div>

      {/* Add Button Click */}
      {showAddModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="supplier-add-model-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">
                Add Supplier
              </div>
              <div
                onClick={removeAddModalContainer}
                style={{ cursor: "pointer" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Name"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="GST NO."
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Phone Number"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Email"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="City"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="State"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Country"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  options={otasOptions}
                  onChange={otaNameHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Zip code"
                  autoComplete="off"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                gap: "10px",
              }}
            >
              <BgThemeButton children={"Update"} />
              <BgOutlineThemeButton
                onClick={removeAddModalContainer}
                children={"Discard"}
              />
            </div>
          </section>
        </>
      )}
      {showModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="room-type-model-container ">
            <h4>Are You Sure?</h4>
            <p>You Want To Remove?</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                onClick={() => setShowModelMenuActive(!showModelMenuActive)}
              >
                Discard
              </Button>
              <Button onClick={removeItemConfirm} destructive>
                Yes, Remove
              </Button>
            </div>
          </section>
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Suppliers;
