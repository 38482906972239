import React from "react";
import AllAmenities from "../Components/AllAmenities";

const AllAmenitiesPage = () => {
  return (
    <>
      <div className="main_container">
        <AllAmenities />
      </div>
    </>
  );
};

export default AllAmenitiesPage;
