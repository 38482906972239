export const SvgMinus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1270_876)">
        <path
          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
          stroke="#3968ED"
        ></path>
        <path
          d="M10.5866 7H3.41333"
          stroke="#3968ED"
          strokeMiterlimit="10"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1270_876">
          <rect width="14" height="14" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export const SvgPlus = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6065_11193)">
        <path
          d="M2.6811 1H11.3189C12.5235 1 13.5 1.97651 13.5 3.1811V11.8189C13.5 13.0235 12.5235 14 11.3189 14H2.6811C1.47651 14 0.5 13.0235 0.5 11.8189V3.1811C0.5 1.97651 1.47651 1 2.6811 1Z"
          stroke="#3968ED"
        />
        <path d="M10.5868 7.5H3.41357" stroke="#3968ED" strokeMiterlimit="10" />
        <path d="M7 3.91344L7 11.0867" stroke="#3968ED" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_6065_11193">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SvgCancel = ({ color, height, width }) => {
  return (
    <svg
      width={height ? height : "14"}
      height={width ? width : "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11302_4819"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <rect width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11302_4819)">
        <path
          d="M3.73366 11.0834L2.91699 10.2667L6.18366 7.00008L2.91699 3.73341L3.73366 2.91675L7.00033 6.18341L10.267 2.91675L11.0837 3.73341L7.81699 7.00008L11.0837 10.2667L10.267 11.0834L7.00033 7.81675L3.73366 11.0834Z"
          fill={color ? color : "white"}
        />
      </g>
    </svg>
  );
};

export const SvgSettings = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      viewBox="0 0 17 18"
    >
      <g clipPath="url(#clip0_1329_1622)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.02247 0.818359C7.61537 0.818359 7.2702 1.11776 7.21268 1.52084L6.90885 3.64704C6.33417 3.86623 5.80383 4.17532 5.33501 4.55712L3.34029 3.75645C2.96236 3.60479 2.5305 3.75404 2.32696 4.10667L1.40031 5.71186C1.19656 6.06448 1.28325 6.51313 1.60366 6.76453L3.29375 8.09121C3.24661 8.38724 3.22204 8.69085 3.22204 9.00018C3.22204 9.30951 3.24661 9.6131 3.29375 9.90913L1.60366 11.2358C1.28325 11.4872 1.19656 11.9359 1.40031 12.2885L2.32696 13.8937C2.5305 14.2463 2.96236 14.3956 3.34029 14.2439L5.33501 13.4432C5.80383 13.825 6.33417 14.1341 6.90885 14.3533L7.21268 16.4795C7.2702 16.8826 7.61537 17.182 8.02247 17.182H9.87616C10.2833 17.182 10.6284 16.8826 10.686 16.4795L10.9898 14.3533C11.5645 14.1341 12.0948 13.8251 12.5636 13.4433L14.5581 14.2439C14.9361 14.3956 15.3679 14.2463 15.5715 13.8937L16.4983 12.2885C16.7019 11.9359 16.6152 11.4872 16.295 11.2358L14.6049 9.9092C14.652 9.61314 14.6766 9.30952 14.6766 9.00018C14.6766 8.89915 14.674 8.79874 14.6688 8.699C14.6582 8.49333 14.6366 8.29054 14.6049 8.09116L16.295 6.76453C16.6152 6.51313 16.7019 6.06448 16.4983 5.71186L15.5715 4.10667C15.3679 3.75404 14.9361 3.60479 14.5581 3.75645L12.5636 4.55707C12.0948 4.17529 11.5645 3.86623 10.9898 3.64704L10.686 1.52084C10.6284 1.11776 10.2833 0.818359 9.87616 0.818359H8.02247ZM8.9494 11.4547C10.305 11.4547 11.4039 10.3558 11.4039 9.00018C11.4039 7.64457 10.305 6.54563 8.9494 6.54563C7.59379 6.54563 6.49485 7.64457 6.49485 9.00018C6.49485 10.3558 7.59379 11.4547 8.9494 11.4547Z"
          fill="#5C5F62"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1329_1622">
          <rect
            width="16.3636"
            height="16.3636"
            fill="white"
            transform="translate(0.63623 0.818359)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export const SvgEdit = () => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#2f59cb"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
          fill="#2f59cb"
        ></path>
      </g>
    </svg>
  );
};

export const SvgDelete = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
        fill="#E03838"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
        fill="#E03838"
      ></path>
    </svg>
  );
};

export const SvgBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect
        x="29.75"
        y="29.75"
        width="29.5"
        height="29.5"
        rx="5.75"
        transform="rotate(180 29.75 29.75)"
        stroke="#666666"
        strokeWidth="0.5"
      ></rect>
      <line x1="21" y1="15.043" x2="9" y2="15.043" stroke="#666666"></line>
      <path
        d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
        stroke="#666666"
        strokeWidth="1.2"
      ></path>
    </svg>
  );
};
export const SvgCopy = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_12703_1996"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12703_1996)">
        <path
          d="M6 12C5.63333 12 5.31944 11.8695 5.05833 11.6084C4.79722 11.3473 4.66667 11.0334 4.66667 10.6667V2.66671C4.66667 2.30004 4.79722 1.98615 5.05833 1.72504C5.31944 1.46393 5.63333 1.33337 6 1.33337H12C12.3667 1.33337 12.6806 1.46393 12.9417 1.72504C13.2028 1.98615 13.3333 2.30004 13.3333 2.66671V10.6667C13.3333 11.0334 13.2028 11.3473 12.9417 11.6084C12.6806 11.8695 12.3667 12 12 12H6ZM6 10.6667H12V2.66671H6V10.6667ZM3.33333 14.6667C2.96667 14.6667 2.65278 14.5362 2.39167 14.275C2.13056 14.0139 2 13.7 2 13.3334V4.66671C2 4.47782 2.06389 4.31948 2.19167 4.19171C2.31944 4.06393 2.47778 4.00004 2.66667 4.00004C2.85556 4.00004 3.01389 4.06393 3.14167 4.19171C3.26944 4.31948 3.33333 4.47782 3.33333 4.66671V13.3334H10C10.1889 13.3334 10.3472 13.3973 10.475 13.525C10.6028 13.6528 10.6667 13.8112 10.6667 14C10.6667 14.1889 10.6028 14.3473 10.475 14.475C10.3472 14.6028 10.1889 14.6667 10 14.6667H3.33333Z"
          fill="#888888"
        />
      </g>
    </svg>
  );
};
export const SvgApp = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_12501_1155"
        style={{maskType:"alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="21"
      >
        <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12501_1155)">
        <path
          d="M4.16667 9.66667C3.70833 9.66667 3.31597 9.50347 2.98958 9.17708C2.66319 8.85069 2.5 8.45833 2.5 8V4.66667C2.5 4.20833 2.66319 3.81597 2.98958 3.48958C3.31597 3.16319 3.70833 3 4.16667 3H7.5C7.95833 3 8.35069 3.16319 8.67708 3.48958C9.00347 3.81597 9.16667 4.20833 9.16667 4.66667V8C9.16667 8.45833 9.00347 8.85069 8.67708 9.17708C8.35069 9.50347 7.95833 9.66667 7.5 9.66667H4.16667ZM4.16667 18C3.70833 18 3.31597 17.8368 2.98958 17.5104C2.66319 17.184 2.5 16.7917 2.5 16.3333V13C2.5 12.5417 2.66319 12.1493 2.98958 11.8229C3.31597 11.4965 3.70833 11.3333 4.16667 11.3333H7.5C7.95833 11.3333 8.35069 11.4965 8.67708 11.8229C9.00347 12.1493 9.16667 12.5417 9.16667 13V16.3333C9.16667 16.7917 9.00347 17.184 8.67708 17.5104C8.35069 17.8368 7.95833 18 7.5 18H4.16667ZM12.5 9.66667C12.0417 9.66667 11.6493 9.50347 11.3229 9.17708C10.9965 8.85069 10.8333 8.45833 10.8333 8V4.66667C10.8333 4.20833 10.9965 3.81597 11.3229 3.48958C11.6493 3.16319 12.0417 3 12.5 3H15.8333C16.2917 3 16.684 3.16319 17.0104 3.48958C17.3368 3.81597 17.5 4.20833 17.5 4.66667V8C17.5 8.45833 17.3368 8.85069 17.0104 9.17708C16.684 9.50347 16.2917 9.66667 15.8333 9.66667H12.5ZM12.5 18C12.0417 18 11.6493 17.8368 11.3229 17.5104C10.9965 17.184 10.8333 16.7917 10.8333 16.3333V13C10.8333 12.5417 10.9965 12.1493 11.3229 11.8229C11.6493 11.4965 12.0417 11.3333 12.5 11.3333H15.8333C16.2917 11.3333 16.684 11.4965 17.0104 11.8229C17.3368 12.1493 17.5 12.5417 17.5 13V16.3333C17.5 16.7917 17.3368 17.184 17.0104 17.5104C16.684 17.8368 16.2917 18 15.8333 18H12.5ZM4.16667 8H7.5V4.66667H4.16667V8ZM12.5 8H15.8333V4.66667H12.5V8ZM12.5 16.3333H15.8333V13H12.5V16.3333ZM4.16667 16.3333H7.5V13H4.16667V16.3333Z"
          fill="#3968ED"
        />
      </g>
    </svg>
  );
};


