import React from "react";
import Channels from "../Components/Channels";
import RateCalender from "../Components/RateCalender/RateCalender";

const RateCalenderPage = () => {
  return (
    <>
      <div className="main_container">
        <RateCalender />
      </div>
    </>
  );
};

export default RateCalenderPage;
