import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: "",
}
export const loginTokenSlice = createSlice({
    name: 'loginToken',
    initialState,
    reducers: {
        saveLoginToken: (state, action) => {
            state.value = action.payload;
            // console.log(action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { saveLoginToken } = loginTokenSlice.actions

export default loginTokenSlice.reducer