import React from "react";
import AddProperty from "../Components/AddProperty";
import AddCustomerProperty from "../Components/AddCustomerProperty";
import RolesPermissions from "../Components/RolesPermissions";

const RolesAndPermissionPage = () => {
  return (
    <>
      <div className="main_container">
        <RolesPermissions />
      </div>
    </>
  );
};

export default RolesAndPermissionPage;
