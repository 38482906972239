import React, { useState } from "react";
import { PrimaryInput } from "../CustomComponents/NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../CustomComponents/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import TimePicker from "../CustomComponents/NewCustomComp/timePicker/TimePicker";
import { Textarea } from "../CustomComponents/NewCustomComp/Testarea/Textarea";

const GeneralDetails = ({ formData, setFormData }) => {
  const handleFormDataInputChange = (e) => {
    const { name, value } = e.target;

    console.log(name, value ,"name");
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTimeChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Select Tags Arrays
  const ratingsOption = [
    { label: "Select", value: "" },
    { label: "1 Star", value: "1 Star" },
    { label: "2 Star", value: "2 Star" },
    { label: "3 Star", value: "3 Star" },
    { label: "4 Star", value: "4 Star" },
    { label: "5 Star", value: "5 Star" },
  ];

  const propertyAreaOptions = [
    { label: "Select", value: "" },
    { label: "Hilly", value: "Hilly" },
    { label: "Coastal", value: "Coastal" },
    { label: "Urban", value: "Urban" },
    { label: "Suburban", value: "Suburban" },
    { label: "Rural", value: "Rural" },
    { label: "Mountainous", value: "Mountainous" },
    { label: "Desert", value: "Desert" },
    { label: "Island", value: "Island" },
    { label: "Forest", value: "Forest" },
    { label: "Lakefront", value: "Lakefront" },
  ];
  const propertyTypeOptions = [
    { label: "Select", value: "" },
    { label: "Budget", value: "budget" },
    { label: "Standard", value: "standard" },
    { label: "Luxury", value: "luxury" },
    { label: "Deluxe", value: "deluxe" },
    { label: "Super Deluxe", value: "super deluxe" },
    { label: "Premium", value: "premium" },
  ];

  return (
    <>
      <div className="flex align_items_center flex_gap_10">
        <div className="w-half">
          <PrimaryInput
            onChange={handleFormDataInputChange}
            titleName={"Property Legal Name"}
            size={"medium"}
    
            value={formData.propertyLegalName}
            name={"propertyLegalName"}
          />
        </div>
        <div className="w-half">
          <SelectBasicWithSelectTag
            onChange={handleFormDataInputChange}
            options={propertyTypeOptions}
            label={"Property Type"}
            size={"medium"}
           
            value={formData.propertyType}
            name={"propertyType"}
          />
        </div>
      </div>
      <div className="flex align_items_center flex_gap_10 mt-10 mb-10">
        <div className="w-half">
          <SelectBasicWithSelectTag
            options={ratingsOption}
            onChange={handleFormDataInputChange}
            label={"Property Rating"}
            size={"medium"}
            // style={{ marginTop: "3px", marginBottom: "10px" }}
            value={formData.propertyRating}
            name={"propertyRating"}
          />
        </div>
        <div className="w-half">
          <SelectBasicWithSelectTag
            onChange={handleFormDataInputChange}
            options={propertyAreaOptions}
            label={"Property Area"}
            size={"medium"}
            // style={{ marginTop: "3px", marginBottom: "10px" }}
            value={formData.propertyArea}
            name={"propertyArea"}
          />
        </div>
      </div>
      <div className="flex align_items_center flex_gap_10 mb-15">
        <div className="w-half">
          <TimePicker
            onChange={handleFormDataInputChange}
            label={"Check-In"}
            style={{ marginTop: "5px" }}
            value={formData.checkInTime}
            name={"checkInTime"}
          />
        </div>
        <div className="w-half">
          <TimePicker
            onChange={handleFormDataInputChange}
            label={"Check-Out"}
            style={{ marginTop: "5px" }}
            value={formData.checkOutTime}
            name={"checkOutTime"}
          />
        </div>
      </div>
      <div className="w-full">
        <Textarea
          name={"propertyDetails"}
          value={formData.propertyDetails}
          label={"Property Details"}
          onChange={handleFormDataInputChange}
          style={{ marginTop: "5px" }}
        />
      </div>
    </>
  );
};

export default GeneralDetails;
