import React, { useState } from "react";
import { Button, Icon, Link, TextField } from "@shopify/polaris";
import logo from "../../assets/images/logo.png";
import SignUpForm from "./stages/SignUpForm";
const Signup = () => {
  const [createSignUpForm, setCreateSignUpForm] = useState(true);
  return (
    <>
      <div className="login-screen-container">
        <div className="login-full-container">
          <div>
            <img className="login-logo" src={logo} alt="logo" />
          </div>

          <div className="login-text-below-logo">
            <h3>Create a RevCatalyst ID</h3>
            <p>One last step before starting your Experience.</p>
          </div>

          {createSignUpForm ? (
            <SignUpForm />
          ) : (
            <div className="credentials-all-buttons">
              <div>
                <Button
                  onClick={() => setCreateSignUpForm(true)}
                  fullWidth={true}
                >
                  Continue With Email
                </Button>
              </div>
              {/* <div>
                <Button fullWidth={true}>Continue With Google</Button>
              </div>
              <div>
                <Button fullWidth={true}>Continue With Facebook</Button>
              </div>
              <div>
                <Button fullWidth={true}>Continue With Apple</Button>
              </div> */}
            </div>
          )}

          <div
            style={{
              marginBottom: "20px",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            By proceeding, you agree to the <Link>Term & Conditions</Link> and{" "}
            <Link>Privacy Policy</Link>
          </div>
          <div
            style={{
              marginBottom: "20px",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            Already have a Shopify ID? <Link url="/login">Log In</Link>
          </div>

          <div
            className="login-privacy-buttons"
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <div>Help</div>
            <div>Privacy</div>
            <div>Terms</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
