import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
  ChoiceList,
} from "@shopify/polaris";
import { Link, useLocation } from "react-router-dom";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import { useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import Loader from "./UI/Loader/Loader";
import { getDecryptedData } from "../assets/encryptStorage";
const AddCustomerProperty = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  let { customerId } = useParams();
  console.log(customerId);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );
  const [loader, setLoader] = useState(false);

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const [formErrors, setFormErrors] = useState({});

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag

  const [selectedTags, setSelectedTags] = useState([
    "Rustic",
    "Antique",
    "Vinyl",
    "Refurbished",
    "Singh",
  ]);
  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    []
  );
  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const navigate = useNavigate();

  const [addPropertyData, setAddPropertyData] = useState({
    property_name: "", //
    property_phone: "", //
    property_email: "", //
    property_hotel_type: "", //
    property_address: "", //
    property_country: "", //
    property_city: "", //
    property_state: "", //
    property_zip: null, //
    property_description: "", //
    plan_id: "1",
    payment_status: "",

    frequency: "", //
    price: "",
    payment_status: "paid",
    account_id: customerId,
  });

  console.log(addPropertyData);

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    // console.log(event.target.name);
    // if ( === "amenities") {
    //   console.log("amenities");
    // }
    // se
    // console.log(formErrors);
    setAddPropertyData({
      ...addPropertyData,
      [event.target.name]: event.target.value,
    });

    // setFormErrors(validate(addPropertyData));
  };

  // Fetch the Plans

  const [revPlans, setRevPlans] = useState([]);
  // console.log(revPlans);
  const fetchPlans = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/plans/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      console.log(data.success);
      if (data.success) {
        setLoader(false);
        showSuccessToast(data.message);
        setRevPlans(data.data.data);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }

      // if (success === true) {
      // }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const checkValidation = (values) => {
    console.log("Validation");
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberRegex = /^([+]\d{2})?\d{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;

    if (!values.property_email) {
      // errors.property_email = "Email is required!";
    } else {
      if (emailRegex.test(values.property_email)) {
        errors.property_email = "";
      } else {
        errors.property_email = "This is not a valid email format!";
      }
    }

    if (!values.billing_phone) {
      errors.billing_phone = "Phone number is required!";
    } else {
      if (phoneNumberRegex.test(values.property_phone)) {
        errors.billing_phone = "";
      } else {
        errors.billing_phone = "This is not a valid Phone Number";
      }
    }

    if (!values.property_zip) {
      errors.property_zip = "Pincode is required!";
    } else {
      if (phoneNumberRegex.test(values.property_zip)) {
        errors.property_zip = "";
      } else {
        errors.property_zip = "This is not a valid Pin Code";
      }
    }

    return errors;
  };

  // Validate function
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberRegex = /^([+]\d{2})?\d{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;

    if (!values.property_email) {
      // errors.property_email = "Email is required!";
    } else {
      if (emailRegex.test(values.property_email)) {
        errors.property_email = "";
      } else {
        errors.property_email = "This is not a valid email format!";
      }
    }

    if (!values.property_phone) {
      errors.property_phone = "Phone number is required!";
    } else {
      if (phoneNumberRegex.test(values.property_phone)) {
        errors.property_phone = "";
      } else {
        errors.property_phone = "This is not a valid Phone Number!";
      }
    }

    if (!values.property_zip) {
      errors.property_zip = "Pincode is required!";
    } else {
      if (pincodeRegex.test(values.property_zip)) {
        errors.property_zip = "";
      } else {
        errors.property_zip = "This is not a valid Pin Code";
      }
    }

    return errors;
  };

  const sourceOptions = [
    { label: "Select Option", value: "" },
    { label: "Direct Booking", value: "Direct Booking" },
    { label: "Agent Booking", value: "Agent Booking" },
  ];
  const propertyTypeOptions = [
    { label: "1 Star", value: "1 Star" },
    { label: "2 Star", value: "2 Star" },
    { label: "3 Star", value: "3 Star" },
    { label: "4 Star", value: "4 Star" },
    { label: "5 Star", value: "5 Star" },
  ];

  const frequencyOptions = [
    // { label: "Select Option", value: "" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
  ];

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const handleSubmitAddRoomType = (event) => {
    event.preventDefault();
    const errors = validate(addPropertyData);
    setFormErrors(errors);

    const hasErrors = Object.values(errors).some((error) => !!error);

    console.log(hasErrors, "hasErrors");
    if (hasErrors === true) {
      showErrorToast("Please check the above errors.");
      return;
    }

    setLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property/create`, {
      method: "POST",
      body: JSON.stringify({ ...addPropertyData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        console.log(data);
        if (data.success) {
          setLoader(false);
          showSuccessToast(data.message);
          navigate("/customers");
          // setRevPlans(data.data.data);
        } else if (data.error) {
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong....!!!");
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  const planOptions = revPlans?.map((plans) => {
    // console.log(plans);
    return { label: plans.name, value: plans.id };
  });

  const onChangeSelectHandle = (e) => {
    console.log(e.target.value);
    setPlanId(e.target.value);

    getOneData(e.target.value);
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        // ${process.env.REACT_APP_BASE_URL}/api/v1/admin/plans/details?plan_id=1
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/plans/details?plan_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.details);
      // console.log(data.room, "data.room");
      const updatedData = {
        ...addPropertyData,
        price: data.details.price,
      };

      setAddPropertyData(updatedData);
      // console.log(addRoomPlans);
    } catch (error) {
      console.error(error);
    }
  };
  const [planId, setPlanId] = useState("");
  console.log(planId, "planId");
  useEffect(() => {
    if (planId) {
      setAddPropertyData({
        ...addPropertyData,
        plan_id: planId,
      });
    }
  }, [planId]);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="edit-rooms-full-container">
          <div>
            <Page
              backAction={{ content: "Products", url: `/customers` }}
              title="Add Customer Property"
              compactTitle
            >
              <form
                onSubmit={handleSubmitAddRoomType}
                className="edit-rooms-full-container-div"
              >
                <div className="edit-rooms-full-container-left">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      width: "591px",
                    }}
                  >
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle1}
                            aria-expanded={open1}
                            aria-controls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>General Details</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open1}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-wrapper-container">
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-full">
                                  <VerticalInputFieldCustom
                                    required={true}
                                    options={sourceOptions}
                                    name={"property_name"}
                                    className="textfield"
                                    focused={false}
                                    titleName="Legal Name"
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addPropertyData.property_name}
                                  />
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    error={
                                      formErrors.property_phone ? true : false
                                    }
                                    required={true}
                                    maxLength={10}
                                    type={"number"}
                                    name="property_phone"
                                    className="textfield"
                                    focused={false}
                                    titleName="Phone Number"
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addPropertyData.property_phone}
                                    min={1}
                                    placeholder={"77XXXXXXXX"}
                                  />
                                  {formErrors.property_phone && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        gap: "5px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                          fill="none"
                                        >
                                          <g clip-path="url(#clip0_1_301)">
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                              fill="#E03838"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_1_301">
                                              <rect
                                                width="14"
                                                height="14"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </div>
                                      <p style={{ color: "red" }}>
                                        {formErrors.property_phone}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    error={
                                      formErrors.property_email ? true : false
                                    }
                                    required={true}
                                    name="property_email"
                                    className="textfield"
                                    titleName="Email"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addPropertyData.property_email}
                                  />
                                  <p style={{ color: "red" }}>
                                    {formErrors.property_email}
                                  </p>
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputSelectCustomCopy
                                    required={true}
                                    options={frequencyOptions}
                                    maxLength={10}
                                    type={"number"}
                                    name="frequency"
                                    className="textfield"
                                    focused={false}
                                    titleName="Frequency"
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addPropertyData.frequency}
                                  />
                                </div>
                                <div className="form-container-div">
                                  <VerticalInputSelectCustomCopy
                                    required={true}
                                    options={propertyTypeOptions}
                                    name="property_hotel_type"
                                    className="textfield"
                                    titleName="Property Type"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addPropertyData.property_hotel_type}
                                  />
                                </div>
                              </div>

                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <div style={{ marginBottom: "4px" }}>
                                    Select Plan
                                    <span style={{ color: "red" }}>*</span>
                                  </div>

                                  <select
                                    style={{
                                      width: "100%",
                                      minHeight: "2.25rem",
                                      border:
                                        "1px solid rgba(171, 177, 186, 1)",
                                      borderRadius: "3px",
                                    }}
                                    onChange={(e) => onChangeSelectHandle(e)}
                                    value={planId}
                                    name="plan_id"
                                  >
                                    <option value="">Select Option</option>
                                    {revPlans &&
                                      revPlans?.map((plan, i) => {
                                        return (
                                          <option key={i} value={plan.id}>
                                            {plan.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                {addPropertyData.price && (
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      type={"number"}
                                      required={true}
                                      name="price"
                                      className="textfield"
                                      titleName="Price"
                                      focused={false}
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      value={+addPropertyData.price}
                                    />
                                  </div>
                                )}
                              </div>
                              {/* <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  required={true}
                                  type="date"
                                  name="property_checkin"
                                  className="textfield"
                                  focused={false}
                                  titleName="Check-In"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_checkin}
                                />
                              </div>
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  required={true}
                                  type="date"
                                  name="property_checkout"
                                  className="textfield"
                                  titleName="Check-Out"
                                  focused={false}
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_checkout}
                                />
                              </div>
                            </div> */}
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle2}
                            aria-expanded={open2}
                            aria-controls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>Address</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open2}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-wrapper-container">
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-half">
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      required={true}
                                      name="property_city"
                                      className="textfield"
                                      titleName="City"
                                      focused={false}
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      value={addPropertyData.property_city}
                                    />
                                  </div>
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      required={true}
                                      name="property_state"
                                      className="textfield"
                                      titleName="State"
                                      focused={false}
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      value={addPropertyData.property_state}
                                    />
                                  </div>
                                </div>
                                <div className="form-container-div-half">
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      required={true}
                                      name="property_country"
                                      className="textfield"
                                      titleName="Country"
                                      focused={false}
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      value={addPropertyData.property_country}
                                    />
                                  </div>
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      onBlur={checkValidation}
                                      error={
                                        formErrors.property_zip ? true : false
                                      }
                                      type={"number"}
                                      required={true}
                                      name="property_zip"
                                      className="textfield"
                                      titleName="Pincode"
                                      focused={false}
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      value={addPropertyData.property_zip}
                                    />
                                    {formErrors.property_zip && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                          gap: "5px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                          >
                                            <g clip-path="url(#clip0_1_301)">
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                                fill="#E03838"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1_301">
                                                <rect
                                                  width="14"
                                                  height="14"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                        <p style={{ color: "red" }}>
                                          {formErrors.property_zip}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="form-container-div-half">
                                  <div className="form-container-div">
                                    <VerticalTextAreaCustom
                                      required={true}
                                      name="property_address"
                                      className="textfield"
                                      titleName="Address"
                                      focused={false}
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      value={addPropertyData.property_address}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>
                    {/* <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle3}
                          aria-expanded={open3}
                          aria-controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Social Media</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open3}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  name="property_facebook"
                                  className="textfield"
                                  titleName="Facebook"
                                  focused={false}
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_facebook}
                                />
                              </div>
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  name="property_instagram"
                                  className="textfield"
                                  titleName="Instagram"
                                  focused={false}
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_instagram}
                                />
                              </div>
                            </div>
                            <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  name="property_threads"
                                  className="textfield"
                                  titleName="Threads"
                                  focused={false}
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_threads}
                                />
                              </div>
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  name="property_linkedin"
                                  className="textfield"
                                  titleName="Linkedin"
                                  focused={false}
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_linkedin}
                                />
                              </div>
                            </div>
                            <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  name="property_twitter"
                                  className="textfield"
                                  titleName="Twitter"
                                  focused={false}
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_twitter}
                                />
                              </div>
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  name="property_whatsapp"
                                  className="textfield"
                                  titleName="Whatsapp"
                                  focused={false}
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addPropertyData.property_whatsapp}
                                />
                              </div>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div> */}
                  </div>
                  <div className="rooms-edit-left-container-buttons">
                    <div>
                      <BgThemeButton type={"submit"} children={"Create"} />
                    </div>
                    <div>
                      <Link style={{ textDecoration: "none" }} to={"/booking"}>
                        <BgOutlineThemeButton children={"Cancel"} />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="edit-rooms-full-container-right">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle4}
                            aria-expanded={open4}
                            aria-controls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>About</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open4}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-wrapper-container">
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalTextAreaCustom
                                    required={true}
                                    name="property_description"
                                    className="textfield"
                                    titleName="Description"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addPropertyData.property_description}
                                  />
                                </div>
                              </div>
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle7}
                            aria_expanded={open7}
                            aria_controls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>Status</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open7}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <VerticalInputSelectCustomCopy
                              required={true}
                              options={statusOptions}
                              name="property_status"
                              value={addPropertyData.status}
                              focused={false}
                              titleName="Status"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>
                    {/* <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle8}
                          aria_expanded={open8}
                          aria_controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Channel Manager Hotel ID</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open8}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <VerticalInputFieldCustom
                            name="property_hotel_id"
                            value={addPropertyData.property_hotel_id}
                            focused={false}
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle5}
                          aria-expanded={open5}
                          aria-controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Bill Prefix</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open5}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-container">
                            <div className="form-container-div-full">
                              <VerticalInputFieldCustom
                                required={true}
                                name="booking_prefix"
                                className="textfield"
                                titleName="Booking Prefix"
                                focused={false}
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                                value={addPropertyData.booking_prefix}
                              />
                            </div>
                          </div>
                          <div className="edit-rooms-form-container">
                            <div className="form-container-div-full">
                              <VerticalInputFieldCustom
                                required={true}
                                name="food_prefix"
                                className="textfield"
                                titleName="Food Prefix"
                                focused={false}
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                                value={addPropertyData.food_prefix}
                              />
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div> */}
                  </div>
                </div>
              </form>
            </Page>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCustomerProperty;
