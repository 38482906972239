import {
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";
import styles from "./channelsNew.module.scss";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NoDataFound from "../NoDataFound";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import Loader from "../../UI/Loader/Loader";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import CustomCssComponentTableText from "../CustomCssComponentTableText";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import UpliftMenuButton from "../NewCustomComp/UpliftMenuButton/UpliftMenuButton";
import { SecondaryButton } from "../NewCustomComp/Buttons/Buttons";
import EditChannelPopup from "./EditChannelPopup";
import InventoryChannelMapping from "./InventoryChannelMapping";
import { getDecryptedData } from "../../../assets/encryptStorage";
const ChannelsNew = () => {
  const navigate = useNavigate();

  // Channel Mapping Popup Code

  const [channelMappingPopup, setChannelMappingPopup] = useState(false);
  const handleChannelMappingPopup = (item) => {
    setChannelMappingPopup(!channelMappingPopup);
    setSelectedChannelMapping(item);
  };

  // Channel Mapping Popup Code end

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const [loader, setLoader] = useState(true);
  const [orders, setOrders] = useState([]);
  const fetchInfo = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        //{{development}}/api/v1/admin/channels/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channels/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [properties, setProperties] = useState([]);

  const fetchProperties = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setProperties(data.properties);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
    fetchProperties();
  }, []);

  const [addChannelData, setAddChannelData] = useState(
    {
      "id": "",
      "channel_name": "",
      "slug": "",
      "channel_dev_requestor_id": "",
      "channel_live_requestor_id": "",
      "channel_dev_token": "",
      "channel_live_token": "",
      "channel_dev_bearer_token": "",
      "channel_live_bearer_token": "",
      "channel_live_url": "",
      "channel_dev_url": "",
      "our_live_url": "",
      "our_dev_url": "",
      "channel_authorization_type": "",
      "our_authorization_type": "",
      "channel_live_secret_key": "",
      "channel_dev_secret_key": "",
      "our_live_api_key": "",
      "our_dev_api_key": "",
      "channel_live_username": "",
      "channel_dev_username": "",
      "channel_live_password": "",
      "channel_dev_password": "",
      "our_live_username": "",
      "our_dev_username": "",
      "our_live_password": "",
      "our_dev_password": "",
      "active_environment": "dev",
     
  }
  );

  const menuOptions = ["Edit", "Delete"];

  const handleUpliftMenuClick = (option, id) => {
    switch (option) {
      case "Edit":
        editCompetitorsRatesHandler(id);
        break;
      case "Delete":
        deleteChannel(id);
        break;
      default:
        console.log("Invalid option:", option);
    }
  };
  const editCompetitorsRatesHandler = async (id, secret_key) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channels/edit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setShowEditPopup(true);
        setAddChannelData(data.channel);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteChannel = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channel/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ ids: [id] }),
      }
    );

    const data = await response.json();

    // Handle response data as needed
    fetchInfo();
  };

  const [selectedChannelMapping, setSelectedChannelMapping] = useState(null);

  const [showAddPopup, setShowAddPopup] = useState(false);
  const addModalButtonClickHandler = () => {
    setShowAddPopup(true);
    setAddChannelData(
      {
        "id": "",
        "channel_name": "",
        "slug": "",
        "channel_dev_requestor_id": "",
        "channel_live_requestor_id": "",
        "channel_dev_token": "",
        "channel_live_token": "",
        "channel_dev_bearer_token": "",
        "channel_live_bearer_token": "",
        "channel_live_url": "",
        "channel_dev_url": "",
        "our_live_url": "",
        "our_dev_url": "",
        "channel_authorization_type": "basic_auth",
        "our_authorization_type": "basic_auth",
        "channel_live_secret_key": "",
        "channel_dev_secret_key": "",
        "our_live_api_key": "",
        "our_dev_api_key": "",
        "channel_live_username": "",
        "channel_dev_username": "",
        "channel_live_password": "",
        "channel_dev_password": "",
        "our_live_username": "",
        "our_dev_username": "",
        "our_live_password": "",
        "our_dev_password": "",
        "active_environment": "dev",
       
    }
    )
  };

  const [showEditPopup, setShowEditPopup] = useState(false);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Channels</div>
              <div style={{ display: "flex", gap: "10px" }}>
                <BgThemeButton
                  onClick={addModalButtonClickHandler}
                  children={"Add Channel"}
                />
              </div>
            </div>

            <div className="flex flex_gap_10 flex_wrap ">
              {orders && orders.length > 0 ? (
                orders.map((item) => (
                  <div className={styles.channel_box} key={item?.id}>
                    <div
                      className={styles.channel_content}
                      onClick={() => navigate(`/channels/${item?.id}`)}
                    >
                      {item?.channel_logo_url ? (
                        <div style={{ width: "120px", height: "60px" }}>
                          <img
                            src={item?.channel_logo_url}
                            alt=""
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      ) : (
                        <div
                          className="flex align_items_center justify_content_center"
                          style={{ width: "120px", height: "60px" }}
                        >
                          <h2
                            style={{
                              width: "fit-content",
                              height: "fit-content",
                            }}
                          >
                            {" "}
                            {item?.channel_name}
                          </h2>
                        </div>
                      )}
                    </div>
                    <div className={styles.options_icon}>
                      <UpliftMenuButton
                        menuOptions={menuOptions}
                        handleUpliftMenuClick={handleUpliftMenuClick}
                        id={item?.id}
                      />
                    </div>

                    {/* Channel Mapping Code */}
                    <SecondaryButton
                      onClick={() => handleChannelMappingPopup(item)}
                      size="small"
                      title={"Mapping"}
                    />
                    {/* Channel Mapping Code End */}
                  </div>
                ))
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>

          {/* Channel Mapping Code */}
          {channelMappingPopup && (
            <InventoryChannelMapping
              show={channelMappingPopup}
              setShow={setChannelMappingPopup}
              selectedChannelMapping={selectedChannelMapping}
              properties={properties}
            />
          )}

          {/* Channel Mapping Code End */}

          {showAddPopup && (
            <div className="bg-backdrop">
              <div
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <EditChannelPopup
                  addChannelData={addChannelData}
                  setAddChannelData={setAddChannelData}
                  show={showAddPopup}
                  setShow={setShowAddPopup}
                  fetchInfo={fetchInfo}
                  isEdit={false}
                />
              </div>
            </div>
          )}

          {/* Edit Button Click */}
          {showEditPopup && (
            <>
              <div className="bg-backdrop">
                <div
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <EditChannelPopup
                    addChannelData={addChannelData}
                    setAddChannelData={setAddChannelData}
                    show={showEditPopup}
                    setShow={setShowEditPopup}
                    fetchInfo={fetchInfo}
                    isEdit={true}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default ChannelsNew;
