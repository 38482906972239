import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { useNavigate } from "react-router-dom";
import CustomToggleButton from "./UI/CustomToggleButton";
import { useSelector } from "react-redux";
import DropZoneFileUploader from "./CustomComponents/DropZoneFileUploader";
import { getDecryptedData } from "../assets/encryptStorage";

const AddRoomType = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  // errors Handle

  const [formErrors, setFormErrors] = useState({});

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  // console.log(files);
  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const bedTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Futon", value: "Futon" },
    { label: "King", value: "King" },
    { label: "Murphy Bed", value: "Murphy Bed" },
    { label: "Queen", value: "Queen" },
    { label: "Sofa Bed", value: "Sofa Bed" },
    { label: "Tatami Mats", value: "Tatami Mats" },
    { label: "Twin", value: "Twin" },
    { label: "Single", value: "Single" },
    { label: "Full", value: "Full" },
    { label: "Run of the House", value: "Run of the House" },
    { label: "Dorm Bed", value: "Dorm Bed" },
  ];

  const roomAreaTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Square Meters", value: "Square Meters" },
    { label: "Square Feet", value: "Square Feet" },
  ];
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const roomViewTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Airport view", value: "Airport view" },
    { label: "Bay view", value: "Bay view" },
    { label: "City view", value: "City view" },
    { label: "Courtyard view", value: "Courtyard view" },
    { label: "Golf view", value: "Golf view" },
    { label: "Harbor view", value: "Harbor view" },
    { label: "Intercoastal view", value: "Intercoastal view" },
    { label: "Lake view", value: "Lake view" },
    { label: "Marina view", value: "Marina view" },
    { label: "Mountain view", value: "Mountain view" },
    { label: "Ocean view", value: "Ocean view" },
    { label: "Pool view", value: "Pool view" },
    { label: "River view", value: "River view" },
    { label: "Water view", value: "Water view" },
    { label: "Beach view", value: "Beach view" },
    { label: "Garden view", value: "Garden view" },
    { label: "Park view", value: "Park view" },
    { label: "Forest view", value: "Forest view" },
    { label: "Rain fores", value: ">Rain" },
    { label: "Various views", value: "Various views" },
    { label: "Limited view", value: "Limited view" },
    { label: "Slope view", value: "Slope view" },
    { label: "Strip view", value: "Strip view" },
    { label: "Countryside view", value: "Countryside view" },
    { label: "Sea view", value: "Sea view" },
    { label: "Valley view", value: "Valley view" },
  ];

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag
  const [selectedTags, setSelectedTags] = useState([]);
  console.log(selectedTags);
  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    [selectedTags]
  );

  const [onkeyPressValue, setOnKeyPressValue] = useState("");
  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && onkeyPressValue.trim() !== "") {
        setOnKeyPressValue("");
        // valueArray.push(inputValue)
        setSelectedTags((prevArray) => [...prevArray, onkeyPressValue]);

        // onkeyPressValue = ""
        setOnKeyPressValue("");
      }
    },
    [onkeyPressValue, selectedTags]
  );

  const setOnKeyPressValueHandleChange = useCallback(
    (e) => {
      if (e.target.name === "amenities") {
        setSelectedTags(selectedTags);
      }
      setOnKeyPressValue(e.target.value);
    },
    [selectedTags]
  );

  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));
  // console.log(selectedTags);

  const property_id = useSelector((state) => state.property.value);
  // const [property, setProperty] = useState(property_id.id);
  console.log(property_id.id, "property");
  useEffect(() => {
    if (property_id) {
      // setProperty(property_id.id);

      setAddRoomTypeData({
        ...addRoomTypeData,
        amenities: selectedTags,
        property_id: property_id.id,
      });
    }
  }, [property_id]);

  const [bedding, setBedding] = useState("enabled");
  const beddingFinalValue = useCallback((data) => {
    setBedding(data);
    // setAddRoomTypeData({ ...addRoomTypeData, extra_bedding: bedding })
    // setAddRoomTypeData({ ...addRoomTypeData, extra_bedding: data })
    console.log(data);
  }, []);
  console.log(bedding);

  useEffect(() => {
    if (selectedTags) {
      console.log(selectedTags);
      setAddRoomTypeData({
        ...addRoomTypeData,
        extra_bedding: bedding,
        amenities: selectedTags,
        property_id: property_id.id,
      });
    }
  }, [selectedTags, bedding]);

  const [addRoomTypeData, setAddRoomTypeData] = useState({
    room_total: null,
    rack_price: null,
    max_child: null,
    room_name: "",
    max_adults: null,
    base_child: 0,
    base_adult: null,
    infants: 0,
    extra_bed_limit: null,
    room_size: null,
    room_size_unit: "",
    room_bed_type: "",
    room_description: "",
    amenities: [],
    breakfast: 0,
    lunch: 100,
    dinner: 100,
    extra_adults: null,
    child_with_Bed: null,
    child_without_Bed: null,
    extra_bedding: "",
    page_title: "",
    meta_description: "",
    url_handle: "",

    room_view_type: "",
    room_images: [],
    // status: "active",

    property_id: property_id.id,
  });

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    console.log(event.target.name);
    // if ( === "amenities") {
    //   console.log("amenities");
    // }
    setAddRoomTypeData({
      ...addRoomTypeData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  console.log(addRoomTypeData);
  const navigate = useNavigate();

  const [roomTypeAddImages, setRoomTypeAddImages] = useState([]);

  // Validate function
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    const urlPattern =
      /^(http(s)?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (!values.url_handle) {
      // errors.url_handle = "url is required!";
    } else {
      if (urlPattern.test(values.url_handle)) {
        errors.url_handle = "";
      } else {
        errors.url_handle = "This is not a valid url format!";
      }
    }

    return errors;
  };

  const updateDataFromChild = (updatedImages) => {
    setRoomTypeAddImages(updatedImages);
  };

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const urls = roomTypeAddImages.map((image) => URL.createObjectURL(image));
    setImageUrls(urls);

    // Clean up object URLs when component unmounts
    return () => {
      urls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [roomTypeAddImages]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const imageDataArray = files.map((file) => file);
    setRoomTypeAddImages(imageDataArray);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setRoomTypeAddImages([...roomTypeAddImages, ...newFiles]);
    // updateDataFromChild(selectedImages);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // TEST
  const handleAddRooms = (e) => {
    e.preventDefault();

    console.log(roomTypeAddImages);
    setAddRoomTypeData({
      ...addRoomTypeData,
      room_images: roomTypeAddImages,
    });
    console.log(addRoomTypeData);

    setFormErrors(validate(addRoomTypeData));

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const formData = new FormData();
    roomTypeAddImages.forEach((file, index) => {
      formData.append(`room_images[${index}]`, file);
    });

    // formData.append("room_images", addRoomTypeData.room_images);
    formData.append("room_total", addRoomTypeData.room_total);
    formData.append("rack_price", addRoomTypeData.rack_price);
    formData.append("max_child", addRoomTypeData.max_child);
    formData.append("room_name", addRoomTypeData.room_name);
    formData.append("max_adults", addRoomTypeData.max_adults);
    formData.append("base_child", addRoomTypeData.base_child);
    formData.append("base_adult", addRoomTypeData.base_adult);
    formData.append("extra_bed_limit", addRoomTypeData.extra_bed_limit);
    formData.append("room_size", addRoomTypeData.room_size);
    formData.append("room_size_unit", addRoomTypeData.room_size_unit);
    formData.append("room_bed_type", addRoomTypeData.room_bed_type);
    formData.append("room_view_type", addRoomTypeData.room_view_type);
    formData.append("room_description", addRoomTypeData.room_description);

    // formData.append("amenities", addRoomTypeData.amenities);

    addRoomTypeData.amenities.forEach((amenities, index) => {
      formData.append(`amenities[${index}]`, amenities);
    });

    formData.append("breakfast", addRoomTypeData.breakfast);
    formData.append("lunch", addRoomTypeData.lunch);
    formData.append("dinner", addRoomTypeData.dinner);
    formData.append("extra_adults", addRoomTypeData.extra_adults);
    formData.append("child_with_Bed", addRoomTypeData.child_with_Bed);
    formData.append("child_without_Bed", addRoomTypeData.child_without_Bed);
    formData.append("extra_bedding", addRoomTypeData.extra_bedding);
    formData.append("page_title", addRoomTypeData.page_title);
    formData.append("meta_description", addRoomTypeData.meta_description);

    formData.append("url_handle", addRoomTypeData.url_handle);
    formData.append("property_id", property_id.id);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    // ,
    // "https://app.revcatalyst.in/api/v1/rooms/store"
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/store`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        navigate("/roomstype");
      })
      .catch((error) => {
        console.log("error", error);
        // setResponse("An error occurred.");
      });
  };

  return (
    <>
      <form
        onSubmit={(e) => handleAddRooms(e)}
        className="edit-rooms-full-container"
      >
        <div>
          <Page
            backAction={{ content: "Products", url: `/roomstype` }}
            title="Add Room"
            compactTitle
          ></Page>
        </div>
        <div className="edit-rooms-full-container-div">
          <div className="edit-rooms-full-container-left">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "591px",
              }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle1}
                      aria-expanded={open1}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      <div>Room details</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open1}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              focused={false}
                              name="room_name"
                              titleName="Room Type Name"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_name}
                            />
                          </div>
                        </div>
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              type="number"
                              required={true}
                              focused={false}
                              name="room_total"
                              titleName="Total Rooms"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_total}
                              min={1}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputSelectCustomCopy
                              required={true}
                              options={bedTypeOptions}
                              name="room_bed_type"
                              focused={false}
                              titleName="Bed Type"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_bed_type}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputSelectCustomCopy
                              required={true}
                              options={roomViewTypeOptions}
                              name="room_view_type"
                              value={addRoomTypeData.room_view_type}
                              focused={false}
                              titleName="Room View Type"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div">
                          <VerticalTextAreaCustom
                            required={true}
                            name="room_description"
                            value={addRoomTypeData.room_description}
                            focused={false}
                            titleName="Descriptions"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            cols={10}
                            rows={5}
                          />
                        </div>
                        <div className="form-container-div"></div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle2}
                      aria_expanded={open2}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Guest</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open2}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              type={"number"}
                              name="base_adult"
                              value={addRoomTypeData.base_adult}
                              focused={false}
                              titleName="Base Adult"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              min={1}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type={"number"}
                              name="max_adults"
                              value={addRoomTypeData.max_adults}
                              focused={false}
                              titleName="Max Adult"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              min={1}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type={"number"}
                              name="max_child"
                              value={addRoomTypeData.max_child}
                              focused={false}
                              titleName="Max Child"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              min={1}
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle3}
                      aria_expanded={open3}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Size</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open3}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type={"number"}
                              name="room_size"
                              value={addRoomTypeData.room_size}
                              focused={false}
                              titleName="Room Size"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              min={1}
                            />
                          </div>
                          <div className="form-container-div">
                            {/* <VerticalInputFieldCustom
                              required={true}
                              type={"text"}
                              name="room_size_unit"
                              value={addRoomTypeData.room_size_unit}
                              focused={false}
                              titleName="Unit"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            /> */}

                            <VerticalInputSelectCustomCopy
                              options={roomAreaTypeOptions}
                              required={true}
                              type={"text"}
                              name="room_size_unit"
                              value={addRoomTypeData.room_size_unit}
                              focused={false}
                              titleName="Unit"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle4}
                      aria_expanded={open4}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Extra</div>

                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open4}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <CustomToggleButton
                            positive={"Enabled"}
                            negative={"Disables"}
                            func={beddingFinalValue}
                            title={"Bedding"}
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            required={true}
                            type={"number"}
                            name="extra_bed_limit"
                            value={addRoomTypeData.extra_bed_limit}
                            focused={false}
                            titleName="Extra Bed Limit"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            min={1}
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle5}
                      aria_expanded={open5}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Media</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open5}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div
                        className="file_content_block"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <input
                          type="file"
                          name="room_images"
                          id="select-image"
                          multiple
                          onChange={handleFileChange}
                          className="dropzone_file_input"
                        />
                        {roomTypeAddImages.length > 0 ? (
                          <div>
                            {roomTypeAddImages.map((image, index) => (
                              <div key={index} className="dropzone_filesList">
                                <img
                                  src={imageUrls[index]}
                                  alt="error"
                                  height="50px"
                                  width="50px"
                                />
                                <p className="dropzone_display_img_name">
                                  {image.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div style={{ margin: "20px auto" }}>
                            <p className="dropzone_add_files_button">
                              Add Files
                            </p>
                            <p className="dropzone_drop_message">
                              or drop JPG, PNG images here
                            </p>
                          </div>
                        )}
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
              {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle6}
                      aria_expanded={open6}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Search Engine Listing</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>

                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#888",
                      }}
                    >
                      Add a title and description to see how this Room might
                      appear
                      <br />
                      in a search engine listing
                    </p>
                    <Collapsible
                      open={open6}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="page_title"
                            value={addRoomTypeData.page_title}
                            focused={false}
                            titleName="Page Title"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="meta_description"
                            value={addRoomTypeData.meta_description}
                            focused={false}
                            titleName="Meta Description"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="url_handle"
                            value={addRoomTypeData.url_handle}
                            focused={false}
                            titleName="Url Handle"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                          <p style={{ color: "red" }}>
                            {formErrors.url_handle}
                          </p>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}

              <div className="rooms-edit-left-container-buttons">
                <div>
                  <BgThemeButton type="submit" children={"Add"} />
                </div>
                <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                  <Link to="/roomstype" style={{ textDecoration: "none" }}>
                    <BgOutlineThemeButton children={"Discard"} />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="edit-rooms-full-container-right">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle7}
                      aria_expanded={open7}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Status</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open7}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        name="status"
                        value={addRoomTypeData.status}
                        focused={false}
                        titleName="Status"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                      />
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>

              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle8}
                      aria_expanded={open8}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        Amenities <span style={{ color: "red" }}> *</span>
                      </div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open8}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            type="text"
                            name="amenities"
                            value={onkeyPressValue}
                            focused={false}
                            onChange={(e) => setOnKeyPressValueHandleChange(e)}
                            onKeyDown={handleOnKeyDown}
                            autoComplete="off"
                          />
                          {/* <input
                            type="text"
                            name="amenities"
                            value={onkeyPressValue}
                            onChange={(e) => setOnKeyPressValueHandleChange(e)}
                            onKeyDown={handleOnKeyDown}
                          /> */}
                        </div>
                        <LegacyStack spacing="tight">{tagMarkup}</LegacyStack>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle9}
                      aria_expanded={open9}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      Pricing
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open9}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            required={true}
                            type={"number"}
                            name="rack_price"
                            value={addRoomTypeData.rack_price}
                            focused={false}
                            titleName="Rack Price"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            min={1}
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            required={true}
                            name="extra_adults"
                            value={addRoomTypeData.extra_adults}
                            focused={false}
                            titleName="Extra Adults"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            min={1}
                          />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            name="child_with_Bed"
                            value={addRoomTypeData.child_with_Bed}
                            focused={false}
                            titleName="Child With Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            min={1}
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            name="child_without_Bed"
                            value={addRoomTypeData.child_without_Bed}
                            focused={false}
                            titleName="Child Without Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            min={1}
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddRoomType;
