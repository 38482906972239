import React, { useEffect, useState } from "react";

const StatusUpdate = ({ status }) => {
  const booked = (
    <div
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Booked
    </div>
  );

  const available = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Available
    </div>
  );

  useEffect(() => {
    if (status === "booked") {
      setButtonStatus(booked);
    } else {
      setButtonStatus(available);
    }
  }, [status]);
  const [buttonStatus, setButtonStatus] = useState(status);

  return <div>{buttonStatus}</div>;
};

export default StatusUpdate;
