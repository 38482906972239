import React from "react";
import AddProperty from "../Components/AddProperty";
import AddCustomerProperty from "../Components/AddCustomerProperty";

const AddCustomerPropertyPage = () => {
  return (
    <>
      <div className="main_container">
        <AddCustomerProperty />
      </div>
    </>
  );
};

export default AddCustomerPropertyPage;
