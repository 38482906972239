import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
  ChoiceList,
} from "@shopify/polaris";
import { Link } from "react-router-dom";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../assets/encryptStorage";

const CreateBooking = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag

  const [selectedTags, setSelectedTags] = useState([
    "Rustic",
    "Antique",
    "Vinyl",
    "Refurbished",
    "Singh",
  ]);
  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    []
  );
  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const navigate = useNavigate();

  const property_id = useSelector((state) => state.property.value);

  const [createBookingData, setCreateBookingData] = useState({
    // first one
    source: "",
    check_in: "",
    check_out: "",

    // room_id: "99f1268f-8a99-401d-93be-7453e0323744",
    property_id: property_id?.id,

    // room_category: [], //roomId

    // second one
    customer_name: "",
    customer_phone: "",
    customer_email: "",
    customer_city: "",
    customer_state: "",
    customer_country: "",
    pincode: "",
    customer_address: "",

    // third one
    property_discount_percent: "",
    tac_percent: "",
    tax_applied: "",
    gst_type: "",
    grand_total: "",

    // no_nights: 5,
    // mode_of_payment: "",
    // paid_amount: "",
    // balance_amount: 7,
    // channel: "",
    // status: "",
    // customer_identity: "",
    // payment_type: "",
    // taxable_amount: 90,
    // tax_percent: 90,

    // plan_type: "99f1268f-8e89-4b2e-b4e9-bfe28f5a4ae4",
    // rack_price: "4000",
    // breakfast: "100",
    // lunch: "200",
    // dinner: "100",
    // bar_price: "4400",
    // room_plan_total: "10800",
    // adultRoomCost: "0",
    // extra_adult_no: "0",
    // childRoomCost: "2",
    // extra_child_no: "2",
    // no_of_adult: "2",
    // no_of_child: "2",
    // no_of_rooms: "90",
  });

  const initialRoomTypeDetails = [
    // Define your initial room type details here
    // Example:
    {
      source: "",
      rack_price: "",
      breakfast: "",
      lunch: "",
      dinner: "",
      bar_price: "",
      no_of_rooms: "",
      room_plan_total: "",
      no_of_adult: "",
      no_of_child: "",
    },
    // Add more items as needed
  ];

  const [inputDataState, setInputDataState] = useState([
    // {
    //   id: "99f359c5-a16a-4e66-a977-198a02c461de",
    //   property_id: "99f12663-7d6d-4d28-8f08-786b7daa73f1",
    //   room_id: "99f359c4-76bc-479a-bc05-19442c6850b2",
    //   mealplan_id: "99f35659-bd44-4b04-8a2c-463b47cc0123",
    //   rate_plan_name: "Room + Complimentary Breakfast",
    //   rate_plan_type: "CP",
    //   date: "2023-08-01",
    //   breakfast: "100.00",
    //   lunch: "0.00",
    //   dinner: "0.00",
    //   rate_rack_price: "400.00",
    //   rate_bar_price: "4100.00",
    //   extra_adult: 4200,
    //   child_with_Bed: 4200,
    //   child_without_Bed: 4150,
    //   base_category: "no",
    //   rate_plan_status: 1,
    // },
  ]);

  const [customRoomTypeDetails, setCustomRoomTypeDetails] = useState([]);
  // console.log(customRoomTypeDetails);

  // add new
  const addNewRow = () => {
    setCustomRoomTypeDetails((prevDetails) => [
      ...prevDetails,
      {
        index: Math.random(),
        source: "",
        rack_price: "",
        breakfast: "",
        lunch: "",
        dinner: "",
        bar_price: "",
        no_of_rooms: "",
        room_plan_total: "",
        no_of_adult: "",
        no_of_child: "",
      },
    ]);
    console.log(customRoomTypeDetails);
  };

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  // console.log(property_id.id);

  // FETCH DATA

  const [roomTypeData, setRoomTypeData] = useState([]);
  // console.log(roomTypeData, "ddddddddddddddddddddddd");
  const [roomTypeRateRoomData, setRoomTypeRateRoomData] = useState([]);
  // console.log(roomTypeRateRoomData);
  // console.log(roomTypeRateRoomData, "roomTypeRateRoomData");
  const fetchRoomType = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setRoomTypeData(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(roomTypeData);
  const [roomTypeFetchedName, setRoomTypeFetchedName] = useState("");
  const fetchRoomRatePlans = async (id, roomType, breakfast) => {
    console.log(id);
    console.log(roomType);
    setRoomTypeFetchedName(roomType);
    // `https://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/get/rooms/rates/99f359c4-76bc-479a-bc05-19442c6850b2`
    // `https://phplaravel-634084-3806003.cloudwaysapps.com//api/v1/rooms/99f359c4-76bc-479a-bc05-19442c6850b2`
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data");

      // setRoomTypeRateRoomData(data.roomRates);

      // forObject
      const roomTypeIndex = roomTypeRateRoomData.findIndex((item) =>
        item.hasOwnProperty(roomType)
      );
      if (roomTypeIndex !== -1) {
        // If roomType exists, remove that property from the object
        const updatedItem = { ...roomTypeRateRoomData[roomTypeIndex] };
        delete updatedItem[roomType];

        // If the object becomes empty after removing the property, remove the entire object
        if (Object.keys(updatedItem).length === 0) {
          roomTypeRateRoomData.splice(roomTypeIndex, 1);
        } else {
          roomTypeRateRoomData[roomTypeIndex] = updatedItem;
        }

        setRoomTypeRateRoomData([...roomTypeRateRoomData]);
      } else {
        // If roomType doesn't exist, add it to the array
        setRoomTypeRateRoomData([
          ...roomTypeRateRoomData,
          { [roomType]: data.roomRates },
        ]);
      }
      const roomTypeExists = inputDataState.some((room) => room.id === id);

      if (roomTypeExists) {
        setInputDataState(inputDataState.filter((room) => room.id !== id));
      } else {
        inputDataState.filter((room) => room.id !== 1);
        setInputDataState([
          ...inputDataState,
          {
            id: id,
            roomType: roomType,
            //   // roomType: roomType,
            //   property_id: "",
            //   room_id: "",
            //   mealplan_id: "",
            //   rate_plan_name: "",
            //   rate_plan_type: "",
            //   date: "",
            //   breakfast: "",
            //   lunch: "",
            //   dinner: "",
            //   rate_rack_price: "",
            //   rate_bar_price: "",
            //   extra_adult: 4200,
            //   child_with_Bed: 4200,
            //   child_without_Bed: 4150,
            //   base_category: "no",
            //   rate_plan_status: 1,
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(roomTypeData, "roomTypeData");

  useEffect(() => {
    if (property_id) {
      fetchRoomType(property_id.id);
    }
  }, [property_id]);

  // Onchange Function
  const setAddItemDataHandleChange = (event, i) => {
    setCreateBookingData({
      ...createBookingData,
      [event.target.name]: event.target.value,
    });
  };

  console.log(createBookingData, "createBookingData");

  const [selectedGstType, setSelectedGstType] = useState("inclusive");

  const selectedGstTypeHandleChange = (e) => setSelectedGstType(e.target.value);

  // console.log(selectedGstType);
  const sourceDirect = [
    { label: "Select Option", value: "" },
    { label: "Direct", value: "Direct" },
    { label: "Agent", value: "Agent" },
    { label: "Ota", value: "Ota" },
  ];

  // const roomTypeOptions = fetchedRoomType.map((roomType) => {
  //   // console.log(roomType);
  //   return { label: roomType.room_name, value: roomType.id };
  // });

  const RoomCategory = roomTypeData?.map((roomType) => {
    return {
      id: roomType.id,
      roomType: roomType.room_name,
      breakfast: roomType.breakfast,
    };
  });
  const [roomUniqueId, setRoomUniqueId] = useState("");

  const [allSourceOptions, setAllSourceOptions] = useState([]);
  // const [allSourceOptions, setAllSourceOptions] = useState([])

  // const pushObjectIfIDNotExists = () => {
  //   const doesExist = objectArray.some(existingObject => existingObject.id === newObject.id);

  //   if (!doesExist) {
  //     setObjectArray(prevArray => [...prevArray, newObject]);
  //   }
  // };

  const sourceOptions = roomTypeRateRoomData?.map((roomType) => {
    return { label: roomType.rate_plan_type, value: roomType.id };
  });

  // console.log(allSourceOptions);

  // console.log(roomUniqueId, "roomUniqueId");

  const [selectedRooms, setSelectedRooms] = useState([]);
  const [trackIndex, setTrackIndex] = useState("");
  const handleChangeRadioRoomCategory = (id, roomType, index, room) => {
    setTrackIndex(index);
    console.log(room);
    const roomExists = selectedRooms.some((room) => room.id === id);
    if (roomExists) {
      setSelectedRooms(selectedRooms.filter((room) => room.id !== id));
    } else {
      setSelectedRooms([...selectedRooms, { id, roomType }]);
    }

    const roomTypeExists = customRoomTypeDetails.some((room) => room.id === id);
    if (roomTypeExists) {
      setCustomRoomTypeDetails(
        customRoomTypeDetails.filter((room) => room.id !== id)
      );
    } else {
      customRoomTypeDetails.filter((room) => room.id !== 1);
      setCustomRoomTypeDetails([
        ...customRoomTypeDetails,
        {
          id: room.id,
          roomType: roomType,
        },
      ]);
    }
  };
  // console.log(customRoomTypeDetails, "customRoomTypeDetails");
  // const handleChangeDivRoomCategory = (id, roomType) => {};
  const [mealId, setMealId] = useState("");

  const combinedData = customRoomTypeDetails.map((roomType, index) => ({
    room_plan: roomType.roomType,
    room_id: roomType.id,
    selected_option: roomTypeRateRoomData[index] || [],
    meal_id: mealId,
    inputValues: inputDataState[index] || [],
  }));

  console.log(combinedData, "combinedData");
  const [combinedDataIndex, setCombinedDataIndex] = useState("0");

  const onChangeFunc = (e, index) => {
    // console.log(e);
    setCombinedDataIndex(index);
    console.log(index, "index");
    // const selectedIndex = e.target.selectedIndex;
    setMealId(e.target.value, "e");
    // console.log(selectedIndex, "selectedIndex");
    // console.log(index);
    getEmptyInputFields(combinedData, e.target.value, index);
  };

  // useEffect(() => {
  //   getEmptyInputFields(combinedData, mealId, combinedDataIndex);
  // }, [combinedData, mealId]);

  // console.log(combinedData);
  const [detailsData, setDetailsData] = useState({
    room_id: "",
    mealplan_id: "",
    check_out: "2023-08-03",
    check_in: "2023-08-01",
  });

  const [roomId, setRoomId] = useState("");
  const [mealIId, setMealIId] = useState("");

  useEffect(() => {
    setDetailsData({
      room_id: roomId,
      mealplan_id: mealIId,
      check_out: "2023-08-03",
      check_in: "2023-08-01",
    });
  }, [roomId, mealIId]);

  const getEmptyInputFields = useCallback(
    (combinedData, e, index) => {
      const roomId = combinedData[index].room_id;

      // Set the new state and then call the API
      setDetailsData({
        room_id: roomId,
        mealplan_id: e,
        check_out: "2023-08-03",
        check_in: "2023-08-01",
      });
    },
    [combinedData, detailsData]
  );

  const [lastSendingPostDate, setLastSendingPostDate] = useState([]);

  const [handleChangeIndex, setHandleChangeIndex] = useState("");
  const customRoomTypeDetailsHandleChange = (e, index) => {
    setHandleChangeIndex(index);
    const { name, value } = e.target;

    const updatedDetails = [...inputDataState];
    updatedDetails[index] = { ...updatedDetails[index], [name]: value };

    if (["breakfast", "lunch", "dinner", "rate_rack_price"].includes(name)) {
      const { breakfast, lunch, dinner, rate_rack_price } =
        updatedDetails[index];
      const total = +breakfast + +lunch + +dinner + +rate_rack_price;
      console.log(total, "total");
      updatedDetails[index] = {
        ...updatedDetails[index],
        ["rate_bar_price"]: total,
      };
    }
    setInputDataState(updatedDetails);
    if (
      [
        "no_of_rooms",
        "room_plan_total",
        "rate_bar_price",
        "breakfast",
        "lunch",
        "dinner",
        "rate_rack_price",
      ].includes(name)
    ) {
      const { no_of_rooms, rate_bar_price } = updatedDetails[index];
      const totalRoomRate = no_of_rooms * +rate_bar_price;
      updatedDetails[index] = {
        ...updatedDetails[index],
        room_plan_total: totalRoomRate,
      };
    }

    setInputDataState(updatedDetails);

    // to calculate Total discount
    if (["property_discount_percent", "tac_percent"].includes(name)) {
      const { property_discount_percent, tac_percent } = updatedDetails[index];
      let totalDiscount =
        +property_discount_percent + +tac_percent ||
        +property_discount_percent ||
        +tac_percent ||
        0;

      // console.log(totalDiscount, "totalDiscount");
      updatedDetails[index] = {
        ...updatedDetails[index],
        ["totalDiscount"]: totalDiscount,
      };
    }
    setInputDataState(updatedDetails);

    // to calculate sub total
    if (
      [
        "no_of_rooms",
        "room_plan_total",
        "rate_bar_price",
        "breakfast",
        "lunch",
        "dinner",
        "rate_rack_price",
        "room_plan_total",
        "rate_rack_price",
        "no_of_rooms",
        "subTotal",
        "totalDiscount",
        "property_discount_percent",
        "tac_percent",
        "tax_applied",
        "gst_type",
        "tac_percent",
        "tax_applied",
      ].includes(name)
    ) {
      const { totalDiscount } = updatedDetails[index];

      let subTotal = updatedDetails.reduce((previousValue, currentValue) => {
        if (currentValue.hasOwnProperty("room_plan_total")) {
          return (previousValue += currentValue.room_plan_total);
        }
        return previousValue;
      }, 0);

      setInputDataState(updatedDetails);

      if (totalDiscount !== undefined) {
        // Calculate the discount amount based on the percentage
        const discountAmount = (totalDiscount / 100) * subTotal;

        // Subtract the discount amount from the subtotal
        subTotal -= discountAmount;

        updatedDetails[index] = {
          ...updatedDetails[index],
          ["subTotal"]: subTotal,
        };
      }
      setInputDataState(updatedDetails);

      // tax amount
      const { tax_applied } = updatedDetails[index];
      console.log(tax_applied);
      if (tax_applied === "Select Option") {
        updatedDetails[index] = {
          ...updatedDetails[index],
          ["subTotal"]: subTotal,
          ["taxAmount"]: 0,
        };
      } else if (tax_applied !== undefined) {
        console.log(tax_applied, "tax_applied");
        const appliedTax = (tax_applied / 100) * subTotal;
        subTotal += appliedTax;

        updatedDetails[index] = {
          ...updatedDetails[index],
          ["taxAmount"]: appliedTax,
          ["subTotal"]: subTotal,
        };
      }
      setInputDataState(updatedDetails);

      const { gst_type } = updatedDetails[index];

      // let subTotal = updatedDetails.reduce((previousValue, currentValue) => {
      //   if (currentValue.hasOwnProperty("room_plan_total")) {
      //     return (previousValue += currentValue.room_plan_total);
      //   }
      //   return previousValue;
      // }, 0);
      // console.log(subTotal);
      setInputDataState(updatedDetails);

      // to calculate tax discount
      if (gst_type === "inclusive") {
        updatedDetails[index] = {
          ...updatedDetails[index],
          ["subTotal"]: subTotal,
          ["grandTotal"]: subTotal,
        };
        setInputDataState(updatedDetails);
      } else if (gst_type === "exclusive") {
        console.log(tax_applied);
        const updatedExclusiveValue = (tax_applied / 100) * subTotal;
        console.log(updatedExclusiveValue);
        subTotal = subTotal + updatedExclusiveValue;
        updatedDetails[index] = {
          ...updatedDetails[index],
          ["grandTotal"]: subTotal,
        };
        setInputDataState(updatedDetails);
      }
      setInputDataState(updatedDetails);
    }

    if (
      [
        "gst_type",
        "tac_percent",
        "tax_applied",
        "no_of_rooms",
        "room_plan_total",
        "rate_bar_price",
        "breakfast",
        "lunch",
        "dinner",
        "rate_rack_price",
        "room_plan_total",
        "rate_rack_price",
        "no_of_rooms",
        "subTotal",
        "totalDiscount",
        "property_discount_percent",
        "tac_percent",
        "tax_applied",
        "gst_type",
        "tac_percent",
        "tax_applied",
      ].includes(name)
    ) {
      const {
        subTotal,
        grandTotal,
        gst_type,
        property_discount_percent,
        tac_percent,
        tax_applied,
      } = updatedDetails[index];

      console.log(subTotal, "subTotal", grandTotal, "grandTotal");
      setCreateBookingData({
        ...createBookingData,
        grand_total: grandTotal,
        gst_type: gst_type,
        property_discount_percent: property_discount_percent,
        tac_percent: tac_percent,
        tax_applied: tax_applied,
      });
    }
  };

  // console.log(detailsData, "detailsData");
  useEffect(() => {
    fetchAfterPlans(detailsData);
  }, [detailsData]);
  console.log(inputDataState, "inputDataState");
  const [fetchedDataToBeFilled, setFetchedDataToBeFilled] = useState(null);
  // setCustomRoomTypeDetails
  // console.log(fetchedDataToBeFilled, "setFetchedDataToBeFilled");
  const fetchAfterPlans = async (detailsData) => {
    // if (detailsDataA.mealplan_id.length > 0) {
    //   console.log(true);
    // } else {
    //   console.log(false);
    // }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(detailsData),
        }
      );
      if (response.ok) {
        const jsonResponse = await response.json();
        setFetchedDataToBeFilled(jsonResponse.rate);
        console.log("Post request successful:", jsonResponse);
        if (jsonResponse) {
          // console.log(!jsonResponse?.rate);
          if (!jsonResponse?.rate) {
            console.log("!jsonResponse?.rate");
          } else {
            console.log(jsonResponse);
            const roomTypeExists = inputDataState.some(
              (room) => room.id === jsonResponse.rate.room_id
            );
            // console.log(roomTypeExists, "");
            if (roomTypeExists) {
              const existingRoomIndex = inputDataState.findIndex(
                (room) => room.id === jsonResponse.rate.room_id
              );

              // console.log(existingRoomIndex);

              if (existingRoomIndex !== -1) {
                const updatedData = [...inputDataState];
                updatedData[existingRoomIndex] = jsonResponse.rate;

                setInputDataState(updatedData);
              }
            } else {
              console.log("oooooooooooooo");
            }
          }
        }
        // }
        // console.log(jsonResponse.rate);

        // Handle the jsonResponse as needed
      } else {
        console.log("Post request failed with status:", response.status);
        // Handle the error case
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error case
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const [bookingNewData, setBookingNewData] = useState([]);

  const handleSubmitAddRoomType = (event) => {
    event.preventDefault();
    console.log(createBookingData);
    const sendingPostData = [
      { ...createBookingData, roomInputData: inputDataState },
    ];
    console.log(sendingPostData, "sendingPostData");

    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/properties/store
    // fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/properties/store`, {
    //   method: "POST",
    //   body: JSON.stringify({ ...addPropertyData }),
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${userToken}`,
    //   },
    // }).then((res) => res.json());
    // .then(json => fetchInfo())
    // .then((json) => navigate("/property"))
    // .then((json) =>
    // );
  };

  return (
    <>
      <div className="edit-rooms-full-container">
        <div>
          <Page
            backAction={{ content: "Products", url: `/roomstype` }}
            title="Create Booking"
            compactTitle
            pagination={{
              hasPrevious: true,
              hasNext: true,
            }}
          >
            <form onSubmit={handleSubmitAddRoomType}>
              <div className="edit-rooms-full-container-div">
                <div className="edit-rooms-full-container-left">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      width: "591px",
                    }}
                  >
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle1}
                            ariaExpanded={open1}
                            ariaControls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>General Details</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open1}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-wrapper-container">
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-full">
                                  <VerticalInputSelectCustomCopy
                                    options={sourceDirect}
                                    name={"source"}
                                    className="textfield"
                                    focused={false}
                                    titleName="Source"
                                    onChange={(e) =>
                                      setAddItemDataHandleChange(e)
                                    }
                                    autoComplete="off"
                                    value={createBookingData.source}
                                  />
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    type="date"
                                    name="check_in"
                                    className="textfield"
                                    titleName="Check-In"
                                    min={currentDate}
                                    onChange={setAddItemDataHandleChange}
                                    value={createBookingData.check_in}
                                  />
                                </div>
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    type="date"
                                    name="check_out"
                                    className="textfield"
                                    titleName="Check-Out"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    min={
                                      createBookingData.check_in || currentDate
                                    }
                                    value={createBookingData.check_out}
                                  />
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  {RoomCategory.map((room, index) => (
                                    <div
                                      onClick={() =>
                                        fetchRoomRatePlans(
                                          room.id,
                                          room.roomType,
                                          room.breakfast
                                        )
                                      }
                                      key={room.id}
                                    >
                                      <label className="roomcategories_content">
                                        <div>
                                          <input
                                            type="checkbox"
                                            checked={selectedRooms.some(
                                              (selectedRoom) =>
                                                selectedRoom.id === room.id
                                            )}
                                            onChange={() =>
                                              handleChangeRadioRoomCategory(
                                                room.id,
                                                room.roomType,
                                                index,
                                                room
                                              )
                                            }
                                            className="rooms_checkbox"
                                          />
                                        </div>
                                        <div>
                                          <span className="room_types">
                                            {room.roomType}
                                          </span>
                                        </div>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>

                    {combinedData
                      // .filter((room) =>
                      //   selectedRooms.find(
                      //     (selectedRoom) => selectedRoom.id === room.id
                      //   )
                      // )
                      .map((selectedRoom, i) => {
                        console.log(i, "iiiiiiiiiiiiiiiiiiiiiiiiii");
                        return (
                          <>
                            <div>
                              <LegacyCard sectioned>
                                <LegacyStack vertical>
                                  <div
                                    onClick={handleToggle2}
                                    ariaExpanded={open2}
                                    ariaControls="basic-collapsible"
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      fontWeight: "700",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <div>{selectedRoom.room_plan}</div>
                                    <div>
                                      <Icon source={ChevronDownMinor} />
                                    </div>
                                  </div>
                                  <Collapsible
                                    open={open2}
                                    id="basic-collapsible"
                                    transition={{
                                      duration: "500ms",
                                      timingFunction: "ease-in-out",
                                    }}
                                    expandOnPrint
                                  >
                                    <div className="edit-rooms-form-wrapper-container">
                                      <div className="edit-rooms-form-container">
                                        <div className="form-container-div-half">
                                          <div className="form-container-div">
                                            <select
                                              onChange={(e) =>
                                                onChangeFunc(e, i)
                                              }
                                            >
                                              {selectedRoom.selected_option[
                                                selectedRoom.room_plan
                                              ]?.map((x, i) => {
                                                return (
                                                  <option
                                                    // onChangeCapture={}

                                                    key={x.mealplan_id}
                                                    value={x.mealplan_id}
                                                  >
                                                    {x.rate_plan_type}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                            <VerticalInputSelectCustomCopy
                                              options={sourceOptions}
                                              name={"source"}
                                              className="textfield"
                                              focused={false}
                                              titleName="Room Plan"
                                              onChange={(e) =>
                                                customRoomTypeDetailsHandleChange(
                                                  e,
                                                  i
                                                )
                                              }
                                              autoComplete="off"
                                              value={
                                                combinedData[i]?.inputValues
                                                  ?.source
                                              }
                                            />
                                          </div>
                                          <div className="form-container-div">
                                            <VerticalInputFieldCustom
                                              type="number"
                                              name="rate_rack_price"
                                              className="textfield"
                                              titleName="Rate Per Night"
                                              focused={false}
                                              onChange={(e) =>
                                                customRoomTypeDetailsHandleChange(
                                                  e,
                                                  i
                                                )
                                              }
                                              autoComplete="off"
                                              value={
                                                +combinedData[i]?.inputValues
                                                  ?.rate_rack_price
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-container-div-half">
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            type="number"
                                            name="breakfast"
                                            className="textfield"
                                            titleName="Breakfast"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              +combinedData[i]?.inputValues
                                                ?.breakfast
                                            }
                                          />
                                        </div>
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            type="number"
                                            name="lunch"
                                            className="textfield"
                                            titleName="Lunch"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              +combinedData[i]?.inputValues
                                                ?.lunch
                                            }
                                          />
                                        </div>
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            type="number"
                                            name="dinner"
                                            className="textfield"
                                            titleName="Dinner"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              +combinedData[i]?.inputValues
                                                ?.dinner
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="form-container-div-half">
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            type="number"
                                            // inputId={`$bar_price_${i}`}
                                            disabled={true}
                                            name="rate_bar_price"
                                            className="textfield"
                                            titleName="Rate Per Night + Meals"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              +combinedData[i]?.inputValues
                                                ?.rate_bar_price
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="form-container-div-half">
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            min={1}
                                            type={"number"}
                                            name="no_of_rooms"
                                            className="textfield"
                                            titleName="Number of Rooms"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              customRoomTypeDetails.no_of_rooms
                                            }
                                          />
                                        </div>
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            type={"number"}
                                            disabled={"true"}
                                            name="room_plan_total"
                                            className="textfield"
                                            titleName="Total Room Rate"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              +combinedData[i]?.inputValues
                                                ?.room_plan_total
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="form-container-div-half">
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            name="no_of_adult"
                                            className="textfield"
                                            titleName="Number of Adults"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              customRoomTypeDetails.no_of_adult
                                            }
                                          />
                                        </div>
                                        <div className="form-container-div">
                                          <VerticalInputFieldCustom
                                            name="no_of_child"
                                            className="textfield"
                                            titleName="Child (>6years)"
                                            focused={false}
                                            onChange={(e) =>
                                              customRoomTypeDetailsHandleChange(
                                                e,
                                                i
                                              )
                                            }
                                            autoComplete="off"
                                            value={
                                              customRoomTypeDetails.no_of_child
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Collapsible>
                                </LegacyStack>
                              </LegacyCard>
                            </div>
                          </>
                        );
                      })}

                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle3}
                            ariaExpanded={open3}
                            ariaControls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>Guest Details</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open3}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-wrapper-container">
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-full">
                                  <VerticalInputFieldCustom
                                    name="customer_name"
                                    className="textfield"
                                    titleName="Name of Guest"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.customer_name}
                                  />
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    name="customer_phone"
                                    className="textfield"
                                    titleName="Phone Number"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.customer_phone}
                                  />
                                </div>
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    name="customer_email"
                                    className="textfield"
                                    titleName="Email"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.customer_email}
                                  />
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    name="customer_city"
                                    className="textfield"
                                    titleName="City"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.customer_city}
                                  />
                                </div>
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    name="customer_state"
                                    className="textfield"
                                    titleName="State"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.customer_state}
                                  />
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    name="customer_country"
                                    className="textfield"
                                    titleName="Country"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.customer_country}
                                  />
                                </div>
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    name="pincode"
                                    className="textfield"
                                    titleName="Pincode"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.pincode}
                                  />
                                </div>
                              </div>

                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalTextAreaCustom
                                    name="customer_address"
                                    className="textfield"
                                    titleName="Address"
                                    focused={false}
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={createBookingData.customer_address}
                                  />
                                </div>
                              </div>
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>
                  </div>
                  <div className="rooms-edit-left-container-buttons">
                    <div>
                      <BgThemeButton children={"Create"} type={"submit"} />
                    </div>
                    <div>
                      <Link style={{ textDecoration: "none" }} to={"/booking"}>
                        <BgOutlineThemeButton children={"Cancel"} />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="edit-rooms-full-container-right">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle7}
                            ariaExpanded={open7}
                            ariaControls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>Discount</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open7}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "15px",
                                alignItems: "center",
                                color: "#333",
                                fontSize: "15px",
                                fontStyle: "italic",
                                fontWeight: 500,
                              }}
                            >
                              <div>Discount 50% off</div>
                              <div>2500.00</div>
                            </div>
                            <div className="form-container-div-full">
                              <VerticalInputFieldCustom
                                type="number"
                                name="property_discount_percent"
                                className="textfield"
                                titleName="Change Property Discount"
                                focused={false}
                                onChange={(e) =>
                                  customRoomTypeDetailsHandleChange(
                                    e,
                                    handleChangeIndex
                                  )
                                }
                                autoComplete="off"
                                value={
                                  combinedData[handleChangeIndex]?.inputValues
                                    ?.property_discount_percent
                                }
                              />
                            </div>
                            <div className="form-container-div-full">
                              <VerticalInputFieldCustom
                                type="number"
                                name="tac_percent"
                                className="textfield"
                                titleName="Enter Tac Discount (%)"
                                focused={false}
                                onChange={(e) =>
                                  customRoomTypeDetailsHandleChange(
                                    e,
                                    handleChangeIndex
                                  )
                                }
                                autoComplete="off"
                                value={
                                  combinedData[handleChangeIndex]?.inputValues
                                    ?.tac_percent
                                }
                              />
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>

                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle8}
                            ariaExpanded={open8}
                            ariaControls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>Taxes & Amount</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>
                          <Collapsible
                            open={open8}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <select
                                  name="tax_applied"
                                  onChange={(e) =>
                                    customRoomTypeDetailsHandleChange(
                                      e,
                                      handleChangeIndex
                                    )
                                  }
                                >
                                  <option>Select Option</option>
                                  <option value="18">18%</option>
                                  <option value="12">12%</option>
                                  <option value="5">5%</option>
                                </select>
                              </div>
                            </div>
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                {/* <ChoiceList
                                name="gst_type"
                                title="Room Category"
                                choices={[
                                  {
                                    label: "Inclusive",
                                    value: "inclusive",
                                  },
                                  {
                                    label: "Exclusive",
                                    value: "exclusive",
                                  },
                                ]}
                                selected={selectedGstType}
                                onChange={selectedGstTypeHandleChange}
                              /> */}

                                <div
                                  style={{
                                    display: "flex",
                                    gap: "13px",
                                    color: "#333",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="gst_type"
                                    onChange={(e) =>
                                      customRoomTypeDetailsHandleChange(
                                        e,
                                        handleChangeIndex
                                      )
                                    }
                                    value="inclusive"
                                  />
                                  Inclusive
                                  <input
                                    type="radio"
                                    name="gst_type"
                                    value="exclusive"
                                    onChange={(e) =>
                                      customRoomTypeDetailsHandleChange(
                                        e,
                                        handleChangeIndex
                                      )
                                    }
                                  />
                                  Exclusive
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "15px",
                                  alignItems: "center",
                                  color: "#333",
                                  flexDirection: "column",
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  gap: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <div>Subtotal</div>
                                  <div>
                                    {combinedData[handleChangeIndex]
                                      ?.inputValues?.subTotal || 0}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <div>Tax Amount</div>
                                  <div>
                                    {combinedData[handleChangeIndex]
                                      ?.inputValues?.taxAmount || 0}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>Grand Total</div>
                                  <div>
                                    {combinedData[handleChangeIndex]
                                      ?.inputValues?.grandTotal || 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>
                    {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "300px",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      Pricing
                      <Button
                        onClick={handleToggle9}
                        ariaExpanded={open9}
                        ariaControls="basic-collapsible"
                      >
                        <Icon source={ChevronDownMinor} />
                      </Button>
                    </div>
                    <Collapsible
                      open={open9}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Rack Price"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Extra Adults"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Child with Bed"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Child Without Bed"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
                  </div>
                </div>
              </div>
            </form>
          </Page>
        </div>
      </div>
    </>
  );
};

export default CreateBooking;
