import React from "react";
import Navbar from "../Components/Navbar/Navbar";

import Sidebar from "../Components/Sidebar";
import EditProfile from "../Components/EditProfile";
import NewSidebar from "../Components/NewSidebar";

const Profile = () => {
  return (
    <>
      <div className="main_container">
        <EditProfile />
      </div>
    </>
  );
};

export default Profile;
