import React from "react";
import Property from "../Components/Property";
import AllProperties from "../Components/AllProperties";
import AllAddons from "../Components/AllAddons";

const AddonsPage = () => {
  return (
    <>
      <div className="main_container">
        <AllAddons />
      </div>
    </>
  );
};

export default AddonsPage;
