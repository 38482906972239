import React from "react";

const CustomCssComponentTableText = ({
  innerText,
  subScript,
  onclick,
  style,
}) => {
  return (
    <div
      onClick={onclick}
      style={{ margin: "7px 0", fontSize: "14px", fontWeight: "500" }}
    >
      {subScript}
      {innerText}
    </div>
  );
};

export default CustomCssComponentTableText;
