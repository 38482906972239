import image from "./docx_icon.png";
export const FaPlus = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16667 0.833332H6.83333V6.83333H0.833332V8.16667H6.83333V14.1667H8.16667V8.16667H14.1667V6.83333H8.16667V0.833332Z"
      fill="#333333"
    />
  </svg>
);

export const FaTrashAlt = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.24999 13.75C1.83749 13.75 1.48437 13.6031 1.19062 13.3094C0.896867 13.0156 0.749992 12.6625 0.749992 12.25V2.5H-7.62939e-06V1H3.74999V0.25H8.24999V1H12V2.5H11.25V12.25C11.25 12.6625 11.1031 13.0156 10.8094 13.3094C10.5156 13.6031 10.1625 13.75 9.74999 13.75H2.24999ZM3.74999 10.75H5.24999V4H3.74999V10.75ZM6.74999 10.75H8.24999V4H6.74999V10.75Z"
      fill="#F5F5F5"
    />
  </svg>
);

export const FaEye = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_501_462"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18"
    >
      <rect width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_501_462)">
      <path
        d="M9 12C9.9375 12 10.7344 11.6719 11.3906 11.0156C12.0469 10.3594 12.375 9.5625 12.375 8.625C12.375 7.6875 12.0469 6.89062 11.3906 6.23438C10.7344 5.57812 9.9375 5.25 9 5.25C8.0625 5.25 7.26562 5.57812 6.60937 6.23438C5.95312 6.89062 5.625 7.6875 5.625 8.625C5.625 9.5625 5.95312 10.3594 6.60937 11.0156C7.26562 11.6719 8.0625 12 9 12ZM9 10.65C8.4375 10.65 7.95937 10.4531 7.56562 10.0594C7.17187 9.66563 6.975 9.1875 6.975 8.625C6.975 8.0625 7.17187 7.58437 7.56562 7.19063C7.95937 6.79688 8.4375 6.6 9 6.6C9.5625 6.6 10.0406 6.79688 10.4344 7.19063C10.8281 7.58437 11.025 8.0625 11.025 8.625C11.025 9.1875 10.8281 9.66563 10.4344 10.0594C10.0406 10.4531 9.5625 10.65 9 10.65ZM9 14.25C7.175 14.25 5.5125 13.7406 4.0125 12.7219C2.5125 11.7031 1.425 10.3375 0.749996 8.625C1.425 6.9125 2.5125 5.54688 4.0125 4.52813C5.5125 3.50938 7.175 3 9 3C10.825 3 12.4875 3.50938 13.9875 4.52813C15.4875 5.54688 16.575 6.9125 17.25 8.625C16.575 10.3375 15.4875 11.7031 13.9875 12.7219C12.4875 13.7406 10.825 14.25 9 14.25Z"
        fill="#F5F5F5"
      />
    </g>
  </svg>
);
export const FaClose = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 13L13 1" stroke="#AAAAAA" stroke-width="1.2" />
    <path d="M13 13L0.999999 1" stroke="#AAAAAA" stroke-width="1.2" />
  </svg>
);

export const FaPdf = () => (
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 12V37.2C36 38.75 34.75 40 33.2 40H6.8C5.25 40 4 38.75 4 37.2V2.8C4 1.25 5.25 0 6.8 0H24L36 12Z"
      fill="#F58F8F"
    />
    <path d="M36 12H26.8C25.25 12 24 10.75 24 9.2V0L36 12Z" fill="#ED3939" />
    <path
      d="M27.16 20.1H1.5C0.671573 20.1 0 20.7715 0 21.6V32C0 32.8284 0.671573 33.5 1.5 33.5H27.16C27.9884 33.5 28.66 32.8284 28.66 32V21.6C28.66 20.7715 27.9884 20.1 27.16 20.1Z"
      fill="#ED3939"
    />
    <path
      d="M9.58001 23.64C9.21001 23.44 8.77001 23.34 8.24001 23.34H5.51001V30.25H6.97001V28.01H8.20001C8.73001 28.01 9.18001 27.91 9.56001 27.72C9.93001 27.52 10.22 27.25 10.42 26.9C10.62 26.55 10.72 26.14 10.72 25.69C10.72 25.24 10.62 24.83 10.43 24.47C10.24 24.12 9.95001 23.84 9.58001 23.64ZM9.07001 26.29C8.98001 26.46 8.84001 26.6 8.66001 26.7C8.48001 26.8 8.24001 26.85 7.96001 26.85H6.97001V24.55H7.96001C8.24001 24.55 8.48001 24.6 8.66001 24.69C8.84001 24.78 8.98001 24.92 9.07001 25.09C9.16001 25.26 9.21001 25.46 9.21001 25.69C9.21001 25.92 9.16001 26.12 9.07001 26.29Z"
      fill="white"
    />
    <path
      d="M15.93 23.76C15.43 23.48 14.83 23.35 14.14 23.35H11.67V30.26H14.12C14.82 30.26 15.42 30.12 15.93 29.84C16.43 29.56 16.82 29.17 17.09 28.65C17.36 28.13 17.5 27.51 17.5 26.79C17.5 26.07 17.36 25.46 17.09 24.94C16.82 24.42 16.43 24.03 15.93 23.75V23.76ZM15.82 28.05C15.67 28.38 15.45 28.61 15.15 28.77C14.85 28.92 14.49 29 14.06 29H13.13V24.59H14.06C14.49 24.59 14.86 24.67 15.15 24.82C15.44 24.97 15.67 25.21 15.82 25.53C15.97 25.85 16.04 26.27 16.04 26.78C16.04 27.29 15.97 27.71 15.82 28.04V28.05Z"
      fill="white"
    />
    <path
      d="M23.15 24.55V23.34H18.58V30.25H20.04V27.4H22.85V26.19H20.04V24.55H23.15Z"
      fill="white"
    />
  </svg>
);

export const FaCsv = () => (
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 12V37.2C36 38.75 34.75 40 33.2 40H6.8C5.25 40 4 38.75 4 37.2V2.8C4 1.25 5.25 0 6.8 0H24L36 12Z"
      fill="#7CDE80"
    />
    <path d="M36 12H26.8C25.25 12 24 10.75 24 9.2V0L36 12Z" fill="#2DB232" />
    <path
      d="M27.16 20.1H1.5C0.671573 20.1 0 20.7715 0 21.6V32C0 32.8284 0.671573 33.5 1.5 33.5H27.16C27.9884 33.5 28.66 32.8284 28.66 32V21.6C28.66 20.7715 27.9884 20.1 27.16 20.1Z"
      fill="#2DB232"
    />
    <path
      d="M10.67 25.76H9.18995C9.15995 25.57 9.10995 25.4 9.01995 25.25C8.93995 25.1 8.82995 24.97 8.69995 24.87C8.56995 24.76 8.41995 24.68 8.24995 24.63C8.07995 24.57 7.89995 24.55 7.69995 24.55C7.33995 24.55 7.02995 24.64 6.76995 24.82C6.49995 25 6.29995 25.25 6.14995 25.59C5.99995 25.93 5.92995 26.33 5.92995 26.81C5.92995 27.29 5.99995 27.72 6.14995 28.05C6.29995 28.38 6.49995 28.64 6.76995 28.81C7.03995 28.98 7.33995 29.07 7.68995 29.07C7.88995 29.07 8.06995 29.04 8.22995 28.99C8.39995 28.94 8.54995 28.86 8.67995 28.76C8.80995 28.66 8.91995 28.54 9.00995 28.39C9.09995 28.25 9.15995 28.08 9.18995 27.9H10.67C10.63 28.22 10.54 28.53 10.38 28.82C10.23 29.11 10.02 29.38 9.76995 29.61C9.51995 29.84 9.20995 30.02 8.85995 30.16C8.50995 30.3 8.10995 30.36 7.66995 30.36C7.04995 30.36 6.49995 30.22 6.01995 29.94C5.53995 29.66 5.14995 29.26 4.86995 28.73C4.58995 28.2 4.44995 27.56 4.44995 26.81C4.44995 26.06 4.58995 25.42 4.87995 24.89C5.15995 24.36 5.54995 23.96 6.02995 23.68C6.51995 23.4 7.05995 23.26 7.66995 23.26C8.06995 23.26 8.43995 23.32 8.78995 23.43C9.12995 23.54 9.43995 23.71 9.69995 23.92C9.96995 24.13 10.18 24.4 10.35 24.71C10.52 25.02 10.62 25.38 10.67 25.78V25.76Z"
      fill="white"
    />
    <path
      d="M15.47 25.33C15.44 25.06 15.33 24.85 15.12 24.7C14.92 24.55 14.64 24.47 14.29 24.47C14.05 24.47 13.85 24.5 13.69 24.57C13.53 24.64 13.4 24.73 13.31 24.84C13.22 24.96 13.18 25.09 13.18 25.24C13.18 25.36 13.2 25.47 13.26 25.56C13.32 25.65 13.4 25.73 13.5 25.8C13.6 25.87 13.72 25.92 13.85 25.97C13.98 26.02 14.12 26.06 14.27 26.09L14.89 26.24C15.19 26.31 15.47 26.4 15.72 26.51C15.97 26.62 16.19 26.76 16.37 26.93C16.55 27.09 16.6999 27.29 16.7999 27.51C16.9 27.73 16.9499 27.99 16.9599 28.28C16.9599 28.7 16.8499 29.07 16.6399 29.38C16.43 29.69 16.12 29.93 15.72 30.1C15.32 30.27 14.84 30.35 14.28 30.35C13.72 30.35 13.24 30.27 12.82 30.09C12.41 29.92 12.09 29.67 11.86 29.33C11.63 28.99 11.51 28.58 11.5 28.08H12.91C12.93 28.31 12.99 28.5 13.11 28.66C13.23 28.82 13.39 28.93 13.59 29.01C13.79 29.09 14.01 29.13 14.27 29.13C14.53 29.13 14.73 29.09 14.92 29.02C15.1 28.95 15.25 28.85 15.35 28.72C15.45 28.59 15.5 28.44 15.5 28.28C15.5 28.12 15.45 27.99 15.36 27.89C15.27 27.78 15.14 27.69 14.96 27.62C14.78 27.55 14.57 27.48 14.32 27.42L13.57 27.23C12.99 27.09 12.53 26.87 12.19 26.56C11.85 26.26 11.69 25.85 11.69 25.34C11.69 24.92 11.8 24.56 12.03 24.24C12.26 23.93 12.57 23.68 12.96 23.51C13.36 23.33 13.81 23.25 14.31 23.25C14.81 23.25 15.27 23.34 15.65 23.51C16.03 23.68 16.33 23.93 16.5499 24.24C16.7599 24.55 16.87 24.91 16.88 25.33H15.47Z"
      fill="white"
    />
    <path
      d="M19.19 23.34L20.86 28.59H20.92L22.59 23.34H24.2099L21.83 30.25H19.95L17.56 23.34H19.19Z"
      fill="white"
    />
  </svg>
);

export const FaDoc = () => (
  <div style={{ width: "36px", height: "40px" }}>
    <img src={image} alt="doc_icon" width="100%" height="100%" />
  </div>
);
export const FaFileAlt = () => (
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 12V37.2C36 38.75 34.75 40 33.2 40H6.8C5.25 40 4 38.75 4 37.2V2.8C4 1.25 5.25 0 6.8 0H24L36 12Z"
      fill="#ADADAD"
    />
    <path d="M36 12H26.8C25.25 12 24 10.75 24 9.2V0L36 12Z" fill="#5C5C5C" />
    <path
      d="M27.16 20.1H1.5C0.671573 20.1 0 20.7715 0 21.6V32C0 32.8284 0.671573 33.5 1.5 33.5H27.16C27.9884 33.5 28.66 32.8284 28.66 32V21.6C28.66 20.7715 27.9884 20.1 27.16 20.1Z"
      fill="#5C5C5C"
    />
    <path
      d="M4.93004 24.55V23.35H10.6V24.55H8.48004V30.26H7.04004V24.55H4.92004H4.93004Z"
      fill="white"
    />
    <path
      d="M12.91 23.34L14.3 25.69H14.35L15.75 23.34H17.4L15.29 26.8L17.45 30.25H15.77L14.35 27.89H14.3L12.88 30.25H11.21L13.37 26.8L11.25 23.34H12.91Z"
      fill="white"
    />
    <path
      d="M18.06 24.55V23.35H23.73V24.55H21.61V30.26H20.17V24.55H18.05H18.06Z"
      fill="white"
    />
  </svg>
);
export const FaCancelRounder = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_743_46)">
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
        fill="#ED3939"
      />
      <path
        d="M11.5 5.42895L10.5711 4.5L7.99794 7.069L5.42895 4.5L4.5 5.42895L7.069 7.99794L4.5 10.5711L5.42895 11.5L7.99794 8.931L10.5711 11.5L11.5 10.5711L8.931 7.99794L11.5 5.42895Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_743_46">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
