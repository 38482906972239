import React from "react";
import Sidebar from "../Components/Sidebar";
import Otas from "../Components/Otas";
import NewSidebar from "../Components/NewSidebar";

const OtasPage = () => {
  return (
    <>
      <div className="main_container">
        <Otas />
      </div>
    </>
  );
};

export default OtasPage;
