import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "@shopify/polaris/build/esm/styles.css";
import Layout from "./Pages/Layout";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import "react-datepicker/dist/react-datepicker.css";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<Layout />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
