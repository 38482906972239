import React from "react";
import { Link } from "react-router-dom";
import { BgThemeButton } from "../Components/UI/Buttons";

const NoPropertyPage = () => {
  return (
    <div className="main_container">
      <div
        style={{ marginTop: "40px" }}
        className="dashboard_checkin_modal_container"
      >
        <div className="dashboard_checkin_modal_box">
          <div className="add_first_property common_title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="239"
              height="130"
              viewBox="0 0 239 130"
              fill="none"
            >
              <g clip-path="url(#clip0_1503_787)">
                <path
                  d="M207.189 129.148C207.212 129.079 209.42 122.249 205.513 116.154L205.114 116.409C208.896 122.309 206.762 128.932 206.74 128.998L207.189 129.148Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M202.719 117.03C202.803 117.429 202.965 117.808 203.195 118.145C203.426 118.481 203.721 118.769 204.062 118.992C204.404 119.215 204.787 119.368 205.188 119.443C205.589 119.518 206.001 119.513 206.4 119.429C206.799 119.345 207.178 119.183 207.514 118.952C207.851 118.722 208.139 118.427 208.362 118.085C208.584 117.743 208.738 117.361 208.813 116.96C208.888 116.559 208.883 116.147 208.799 115.748C208.445 114.069 205.443 109.89 203.764 110.245C202.085 110.599 202.365 115.351 202.719 117.03Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M203.285 124.882C204.783 126.502 207.464 126.458 207.464 126.458C207.464 126.458 207.715 123.789 206.217 122.169C204.718 120.55 202.038 120.594 202.038 120.594C202.038 120.594 201.786 123.263 203.285 124.882Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M225.996 129.621C226.049 129.46 231.259 113.34 222.038 98.9563L221.097 99.5596C230.023 113.484 224.986 129.112 224.935 129.268L225.996 129.621Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M215.445 101.025C215.847 102.927 216.987 104.592 218.616 105.653C220.245 106.715 222.229 107.085 224.132 106.684C226.034 106.283 227.699 105.143 228.761 103.514C229.823 101.885 230.194 99.9015 229.793 97.999C228.958 94.0369 221.874 84.1761 217.911 85.0117C213.949 85.8473 214.609 97.0628 215.445 101.025Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M216.781 119.556C220.317 123.377 226.643 123.274 226.643 123.274C226.643 123.274 227.236 116.975 223.7 113.154C220.164 109.332 213.838 109.436 213.838 109.436C213.838 109.436 213.245 115.735 216.781 119.556Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M137.818 128.408L134.361 126.952L131.989 109.621H100.406L97.8348 126.881L94.7417 128.428C94.5937 128.502 94.475 128.624 94.4049 128.773C94.3348 128.923 94.3173 129.092 94.3553 129.254C94.3934 129.415 94.4846 129.558 94.6144 129.661C94.7441 129.763 94.9048 129.819 95.0702 129.819H137.533C137.702 129.819 137.867 129.761 137.998 129.653C138.129 129.546 138.219 129.396 138.253 129.23C138.286 129.064 138.261 128.891 138.182 128.741C138.103 128.591 137.974 128.474 137.818 128.408Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M193.944 111.926H38.7976C37.9836 111.925 37.2035 111.6 36.6286 111.024C36.0536 110.448 35.7307 109.667 35.7307 108.853V86.9751H197.01V108.853C197.01 109.667 196.687 110.447 196.112 111.024C195.538 111.6 194.758 111.925 193.944 111.926Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M197.173 97.315H35.5891V3.7053C35.5902 2.72292 35.981 1.7811 36.6757 1.08647C37.3703 0.391828 38.3122 0.00110111 39.2945 0H193.467C194.449 0.00110047 195.391 0.391826 196.086 1.08646C196.781 1.7811 197.171 2.72292 197.173 3.7053V97.315Z"
                  fill="#3F3D56"
                />
                <path
                  d="M187.523 6.79395H45.2394C44.4821 6.79489 43.7562 7.09631 43.2209 7.63203C42.6856 8.16775 42.3848 8.89397 42.3845 9.65127V87.6638C42.3848 88.4211 42.6856 89.1474 43.2209 89.6831C43.7562 90.2188 44.4821 90.5202 45.2394 90.5212H187.523C188.28 90.5198 189.006 90.2183 189.541 89.6827C190.076 89.147 190.377 88.421 190.378 87.6638V9.65127C190.377 8.89408 190.076 8.16808 189.541 7.63245C189.006 7.09682 188.28 6.79528 187.523 6.79395Z"
                  fill="white"
                />
                <path
                  d="M70.4602 6.79395V90.5212H45.2394C44.4821 90.5202 43.7562 90.2188 43.2209 89.6831C42.6856 89.1474 42.3848 88.4211 42.3845 87.6638V9.65127C42.3848 8.89397 42.6856 8.16775 43.2209 7.63203C43.7562 7.09631 44.4821 6.79489 45.2394 6.79395H70.4602Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M49.6535 49.0902C49.3696 49.0256 49.075 49.024 48.7904 49.0855C48.5058 49.1471 48.2382 49.2703 48.0064 49.4464C47.7746 49.6226 47.5842 49.8475 47.4487 50.1052C47.3132 50.3629 47.2358 50.6472 47.222 50.938L40.4333 52.9394L43.2437 55.3119L49.2595 53.0476C49.7496 53.0383 50.2193 52.8496 50.5796 52.5172C50.9399 52.1848 51.1657 51.7317 51.2143 51.2439C51.2629 50.7561 51.1308 50.2674 50.8431 49.8705C50.5555 49.4735 50.1322 49.1959 49.6535 49.0902Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M22.2238 64.1527C21.5527 64.1518 20.9037 63.9126 20.3925 63.4777C19.8814 63.0428 19.5413 62.4405 19.4329 61.7781C19.3246 61.1158 19.455 60.4365 19.8009 59.8614C20.1468 59.2863 20.6857 58.8527 21.3216 58.638L35.0784 53.9942L45.8094 50.085L47.1263 54.6944L37.653 60.9366L22.8159 64.0903C22.6213 64.1317 22.4228 64.1526 22.2238 64.1527Z"
                  fill="#3968ED"
                />
                <path
                  d="M30.8327 127.654H32.7642L33.6832 120.204H30.8323L30.8327 127.654Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M30.3401 127.023L34.144 127.023C34.7869 127.023 35.4036 127.278 35.8582 127.733C36.3128 128.188 36.5682 128.804 36.5683 129.447V129.526L30.3402 129.526L30.3401 127.023Z"
                  fill="#2F2E41"
                />
                <path
                  d="M16.0232 127.654H17.9546L18.8736 120.204H16.0229L16.0232 127.654Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M15.5303 127.023L19.3342 127.023C19.9771 127.023 20.5938 127.278 21.0484 127.733C21.503 128.188 21.7584 128.804 21.7585 129.447V129.526L15.5304 129.526L15.5303 127.023Z"
                  fill="#2F2E41"
                />
                <path
                  d="M16.004 82.9658C15.12 85.0249 14.9266 87.3148 15.4525 89.493L14.9011 126.45H19.1432L21.5182 106.126L25.457 92.7339L29.7108 103.132V124.804L33.8071 124.559L36.0128 101.557L33.492 85.1715L16.004 82.9658Z"
                  fill="#2F2E41"
                />
                <path
                  d="M31.5227 54.2129L27.8991 53.1101L26.9538 51.2195H22.2992L21.1244 53.2676C21.1244 53.2676 16.3979 55.0007 16.2404 63.3508C16.0828 71.701 15.4526 85.0927 15.4526 85.0927L34.0894 87.7496L31.5227 54.2129Z"
                  fill="#3968ED"
                />
                <path
                  d="M36.8732 86.2543C36.9954 85.99 37.0582 85.7022 37.0572 85.411C37.0562 85.1199 36.9913 84.8325 36.8672 84.5691C36.7431 84.3057 36.5627 84.0727 36.3388 83.8866C36.1149 83.7005 35.8529 83.5657 35.5713 83.4918L35.025 76.4353L32.1201 78.691L33.0842 85.0461C32.9914 85.5274 33.0783 86.0261 33.3286 86.4476C33.5788 86.8691 33.975 87.1842 34.4421 87.3331C34.9091 87.482 35.4145 87.4545 35.8626 87.2556C36.3107 87.0568 36.6702 86.7005 36.8732 86.2543Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M31.9168 82.6174L27.7805 72.0534V56.8847C27.7805 56.1622 28.0567 55.467 28.5525 54.9415C29.0484 54.416 29.7263 54.0999 30.4476 54.058C31.1689 54.0161 31.8789 54.2515 32.4323 54.716C32.9857 55.1805 33.3406 55.8389 33.4243 56.5566L35.1065 70.9784L36.6992 82.2874L31.9168 82.6174Z"
                  fill="#3968ED"
                />
                <path
                  d="M25.5193 48.767C28.0883 48.767 30.1708 46.6844 30.1708 44.1155C30.1708 41.5466 28.0883 39.4641 25.5193 39.4641C22.9504 39.4641 20.8679 41.5466 20.8679 44.1155C20.8679 46.6844 22.9504 48.767 25.5193 48.767Z"
                  fill="#FFB9B9"
                />
                <path
                  d="M25.8238 42.7017C25.6062 42.981 25.3079 43.2332 25.2599 43.5841C25.2244 43.8441 25.338 44.101 25.3488 44.3632C25.3497 44.6278 25.2497 44.8828 25.0691 45.0762C24.8885 45.2695 24.641 45.3868 24.3769 45.404C24.193 45.1726 23.9273 45.0206 23.6346 44.9795C23.4889 44.963 23.3418 44.9937 23.2148 45.067C23.0879 45.1404 22.9878 45.2525 22.9293 45.3869C22.7912 45.7771 23.1109 46.1735 23.1623 46.5842C23.1692 46.888 23.12 47.1905 23.0173 47.4765C22.8995 47.9478 22.868 48.4464 22.6612 48.8859C22.4544 49.3255 21.9947 49.7036 21.5187 49.6064C21.1185 49.5247 20.8477 49.1371 20.7358 48.7443C20.6238 48.3514 20.6289 47.9349 20.5478 47.5346C20.2846 46.2337 19.1649 45.2698 18.7256 44.0174C18.1626 42.4126 18.8389 40.5769 20.0044 39.3384C20.4756 38.7958 21.0907 38.3975 21.7786 38.1897C22.5049 38.0047 23.2729 38.1377 24.0181 38.0586C24.8152 37.974 25.5643 37.6501 26.3389 37.4443C27.1135 37.2384 27.9787 37.1609 28.6828 37.5439C29.1739 37.8109 29.5295 38.2715 29.9871 38.5926C30.5116 38.9581 31.1475 39.1283 31.7845 39.0737L31.0239 39.4062C31.315 39.6734 31.6666 39.866 32.0485 39.9674C31.7377 40.2138 31.3532 40.3487 30.9566 40.3507C31.0393 40.4998 31.1517 40.6303 31.2868 40.7342C31.422 40.8381 31.577 40.9132 31.7423 40.9549C31.1647 41.226 30.5516 41.5038 29.9165 41.4426C29.6054 41.4127 29.3081 41.3021 29.0008 41.2452C28.3458 41.1239 27.6731 41.2496 27.0189 41.3751C26.6754 41.441 26.2537 41.5043 26.0802 41.8346C25.9358 42.1097 26.0463 42.4162 25.8238 42.7017Z"
                  fill="#2F2E41"
                />
                <path
                  d="M56.4225 56.0781C60.5207 56.0781 63.843 52.7558 63.843 48.6576C63.843 44.5593 60.5207 41.2371 56.4225 41.2371C52.3242 41.2371 49.002 44.5593 49.002 48.6576C49.002 52.7558 52.3242 56.0781 56.4225 56.0781Z"
                  fill="#3968ED"
                />
                <path
                  d="M60.8746 48.6578H56.8677V44.6506H55.9771V48.6578H51.97V49.5481H55.9771V53.5553H56.8677V49.5481H60.8746V48.6578Z"
                  fill="white"
                />
                <path
                  d="M119.612 16.5869C119.384 16.5872 119.164 16.6782 119.002 16.84C118.841 17.0018 118.75 17.2212 118.749 17.45V31.902C118.75 32.1309 118.841 32.3503 119.002 32.5121C119.164 32.674 119.384 32.765 119.612 32.7653H177.873C178.102 32.765 178.321 32.674 178.483 32.5121C178.645 32.3503 178.736 32.1309 178.736 31.902V17.45C178.736 17.2212 178.645 17.0018 178.483 16.84C178.321 16.6782 178.102 16.5872 177.873 16.5869H119.612Z"
                  fill="white"
                />
                <path
                  d="M119.612 16.5869C119.384 16.5872 119.164 16.6782 119.002 16.84C118.841 17.0018 118.75 17.2212 118.749 17.45V31.902C118.75 32.1309 118.841 32.3503 119.002 32.5121C119.164 32.674 119.384 32.765 119.612 32.7653H177.873C178.102 32.765 178.321 32.674 178.483 32.5121C178.645 32.3503 178.736 32.1309 178.736 31.902V17.45C178.736 17.2212 178.645 17.0018 178.483 16.84C178.321 16.6782 178.102 16.5872 177.873 16.5869H119.612Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M119.72 31.7941H177.765V17.5579H119.72V31.7941Z"
                  fill="white"
                />
                <path
                  d="M126.32 21.655C126.119 21.655 125.927 21.7346 125.786 21.8762C125.644 22.0179 125.564 22.21 125.564 22.4103C125.564 22.6106 125.644 22.8027 125.786 22.9443C125.927 23.0859 126.119 23.1655 126.32 23.1655H171.164C171.263 23.1655 171.361 23.146 171.453 23.1081C171.544 23.0702 171.627 23.0146 171.698 22.9445C171.768 22.8744 171.823 22.7912 171.861 22.6996C171.899 22.608 171.919 22.5098 171.919 22.4106C171.919 22.3114 171.9 22.2132 171.862 22.1215C171.824 22.0299 171.768 21.9466 171.698 21.8765C171.628 21.8063 171.545 21.7506 171.453 21.7126C171.362 21.6746 171.263 21.6551 171.164 21.655H126.32Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M126.32 26.1865C126.12 26.1869 125.928 26.2666 125.787 26.4082C125.645 26.5498 125.566 26.7417 125.566 26.9418C125.566 27.1418 125.645 27.3337 125.787 27.4753C125.928 27.6169 126.12 27.6966 126.32 27.697H171.164C171.364 27.6974 171.556 27.6183 171.698 27.477C171.84 27.3357 171.92 27.1437 171.921 26.9434C171.921 26.7431 171.842 26.5509 171.701 26.4089C171.559 26.267 171.367 26.187 171.167 26.1865H126.32Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M79.098 41.1086C78.8691 41.1089 78.6498 41.1999 78.4879 41.3617C78.3261 41.5235 78.2351 41.7429 78.2349 41.9718V56.4238C78.2351 56.6526 78.3261 56.872 78.4879 57.0339C78.6497 57.1957 78.8691 57.2867 79.098 57.287H137.359C137.588 57.2867 137.807 57.1957 137.969 57.0339C138.131 56.872 138.222 56.6526 138.222 56.4238V41.9718C138.222 41.7429 138.131 41.5235 137.969 41.3617C137.807 41.1999 137.588 41.1089 137.359 41.1086H79.098Z"
                  fill="white"
                />
                <path
                  d="M79.098 41.1086C78.8691 41.1089 78.6498 41.1999 78.4879 41.3617C78.3261 41.5235 78.2351 41.7429 78.2349 41.9718V56.4238C78.2351 56.6526 78.3261 56.872 78.4879 57.0339C78.6497 57.1957 78.8691 57.2867 79.098 57.287H137.359C137.588 57.2867 137.807 57.1957 137.969 57.0339C138.131 56.872 138.222 56.6526 138.222 56.4238V41.9718C138.222 41.7429 138.131 41.5235 137.969 41.3617C137.807 41.1999 137.588 41.1089 137.359 41.1086H79.098Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M79.2061 56.3158H137.251V42.0796H79.2061V56.3158Z"
                  fill="white"
                />
                <path
                  d="M85.8055 46.1768C85.6052 46.1768 85.4131 46.2563 85.2715 46.398C85.1299 46.5396 85.0503 46.7317 85.0503 46.932C85.0503 47.1323 85.1299 47.3244 85.2715 47.466C85.4131 47.6077 85.6052 47.6872 85.8055 47.6872H130.649C130.749 47.6873 130.847 47.6678 130.938 47.6299C131.03 47.5919 131.113 47.5363 131.183 47.4662C131.254 47.3961 131.309 47.3129 131.347 47.2213C131.385 47.1297 131.405 47.0315 131.405 46.9323C131.405 46.8331 131.385 46.7349 131.348 46.6433C131.31 46.5516 131.254 46.4683 131.184 46.3982C131.114 46.328 131.031 46.2724 130.939 46.2344C130.847 46.1964 130.749 46.1768 130.65 46.1768H85.8055Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M85.8054 50.7083C85.6053 50.7086 85.4136 50.7883 85.2722 50.9299C85.1309 51.0715 85.0515 51.2634 85.0515 51.4635C85.0515 51.6636 85.1309 51.8554 85.2722 51.997C85.4136 52.1386 85.6053 52.2184 85.8054 52.2187H130.649C130.85 52.2192 131.042 52.14 131.184 51.9987C131.326 51.8574 131.406 51.6655 131.406 51.4652C131.407 51.2649 131.327 51.0726 131.186 50.9306C131.045 50.7887 130.853 50.7087 130.653 50.7083H85.8054Z"
                  fill="#E4E4E4"
                />
                <path
                  d="M150.113 79.8963C150.373 79.8677 150.623 79.7826 150.846 79.6469C151.069 79.5113 151.26 79.3284 151.404 79.1111C151.549 78.8938 151.644 78.6474 151.683 78.3894C151.722 78.1313 151.705 77.8677 151.631 77.6173L156.794 73.9292L153.717 72.7444L149.286 76.4283C148.873 76.58 148.531 76.8778 148.323 77.2651C148.115 77.6525 148.057 78.1025 148.159 78.53C148.261 78.9575 148.517 79.3327 148.877 79.5845C149.237 79.8363 149.677 79.9473 150.113 79.8963Z"
                  fill="#FFB7B7"
                />
                <path
                  d="M152.98 77.4674L151.267 74.5419L151.303 74.5005L158.214 66.6325L163.58 57.4817L168.24 58.0756L168.309 59.484L168.295 59.505L160.892 70.6334L160.884 70.6401L152.98 77.4674Z"
                  fill="#3F3D56"
                />
                <path
                  d="M175.814 126.972L173.179 126.972L171.925 116.808L175.814 116.808L175.814 126.972Z"
                  fill="#FFB7B7"
                />
                <path
                  d="M176.486 129.526L167.99 129.526V129.418C167.99 128.541 168.338 127.7 168.958 127.08C169.578 126.46 170.42 126.111 171.297 126.111L176.486 126.112L176.486 129.526Z"
                  fill="#2F2E41"
                />
                <path
                  d="M153.136 126.886L150.573 126.274L151.714 116.096L155.496 117L153.136 126.886Z"
                  fill="#FFB7B7"
                />
                <path
                  d="M153.196 129.526L144.932 127.553L144.957 127.448C145.161 126.595 145.695 125.858 146.442 125.399C147.189 124.94 148.088 124.796 148.942 125L153.989 126.205L153.196 129.526Z"
                  fill="#2F2E41"
                />
                <path
                  d="M163.377 52.8349V48.3994C163.377 46.8231 164.003 45.3113 165.117 44.1966C166.232 43.082 167.744 42.4558 169.32 42.4558C170.897 42.4558 172.408 43.082 173.523 44.1966C174.638 45.3113 175.264 46.8231 175.264 48.3994V52.8349C175.264 53.0466 175.179 53.2495 175.03 53.3992C174.88 53.5489 174.677 53.6331 174.465 53.6333H164.175C163.963 53.6331 163.76 53.5489 163.611 53.3992C163.461 53.2495 163.377 53.0466 163.377 52.8349Z"
                  fill="#2F2E41"
                />
                <path
                  d="M167.853 53.5659C170.26 53.5659 172.211 51.6149 172.211 49.2082C172.211 46.8016 170.26 44.8506 167.853 44.8506C165.447 44.8506 163.496 46.8016 163.496 49.2082C163.496 51.6149 165.447 53.5659 167.853 53.5659Z"
                  fill="#FFB7B7"
                />
                <path
                  d="M161.97 48.7541C161.972 47.5076 162.468 46.3125 163.349 45.4311C164.231 44.5497 165.426 44.0539 166.672 44.0525H167.559C168.806 44.0539 170.001 44.5497 170.882 45.4311C171.764 46.3126 172.259 47.5076 172.261 48.7541V48.8428H170.386L169.747 47.0523L169.619 48.8428H168.65L168.327 47.9394L168.263 48.8428H161.97V48.7541Z"
                  fill="#2F2E41"
                />
                <path
                  d="M170.395 72.2075L159.3 71.7852L158.68 64.5265C158.201 63.1979 158.194 62.0571 158.657 61.136C159.055 60.3795 159.724 59.8011 160.53 59.5166L162.958 56.5843C163.271 56.2074 163.663 55.9045 164.107 55.6977C164.55 55.4909 165.034 55.3852 165.524 55.3884L171.832 55.4315C171.984 55.34 172.906 54.8713 174.725 55.6598C176.698 56.5151 176.24 59.4855 176.235 59.5154L176.23 59.5433L176.209 59.5616L172.013 63.0951L170.395 72.2075Z"
                  fill="#3F3D56"
                />
                <path
                  d="M159.388 71.0054L154.14 80.5739L150.161 124.106L154.657 125.479L162.614 91.4255L171.85 124.513L177.239 124.648L173.569 86.4648L170.363 71.6816L159.388 71.0054Z"
                  fill="#2F2E41"
                />
                <path
                  d="M125.422 63.9127C125.204 63.8423 124.967 63.8613 124.763 63.9653C124.56 64.0693 124.405 64.25 124.335 64.4676L119.878 78.2153C119.808 78.4331 119.827 78.6698 119.931 78.8737C120.035 79.0775 120.215 79.2318 120.433 79.3026L175.854 97.2684C176.072 97.3387 176.309 97.3198 176.513 97.2158C176.717 97.1117 176.871 96.9311 176.942 96.7134L181.398 82.9657C181.468 82.748 181.45 82.5112 181.346 82.3074C181.241 82.1035 181.061 81.9493 180.843 81.8785L125.422 63.9127Z"
                  fill="white"
                />
                <path
                  d="M125.422 63.9127C125.204 63.8423 124.967 63.8613 124.763 63.9653C124.56 64.0693 124.405 64.25 124.335 64.4676L119.878 78.2153C119.808 78.4331 119.827 78.6698 119.931 78.8737C120.035 79.0775 120.215 79.2318 120.433 79.3026L175.854 97.2684C176.072 97.3387 176.309 97.3198 176.513 97.2158C176.717 97.1117 176.871 96.9311 176.942 96.7134L181.398 82.9657C181.468 82.748 181.45 82.5112 181.346 82.3074C181.241 82.1035 181.061 81.9493 180.843 81.8785L125.422 63.9127Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M120.835 78.4121L176.051 96.3114L180.441 82.7689L125.225 64.8696L120.835 78.4121Z"
                  fill="white"
                />
                <path
                  d="M130.239 70.8023C130.049 70.7407 129.842 70.7572 129.663 70.8483C129.485 70.9393 129.35 71.0975 129.289 71.288C129.227 71.4784 129.243 71.6856 129.334 71.864C129.425 72.0424 129.583 72.1773 129.774 72.2392L172.432 86.0676C172.527 86.0984 172.626 86.1102 172.725 86.1025C172.824 86.0948 172.92 86.0676 173.009 86.0226C173.097 85.9776 173.176 85.9155 173.24 85.8401C173.305 85.7646 173.354 85.6772 173.384 85.5828C173.415 85.4884 173.427 85.3889 173.419 85.2899C173.411 85.191 173.384 85.0946 173.339 85.0062C173.294 84.9178 173.232 84.8391 173.156 84.7748C173.08 84.7104 172.993 84.6615 172.899 84.631L172.898 84.6308L130.239 70.8023Z"
                  fill="#3968ED"
                />
                <path
                  d="M128.842 75.1127C128.652 75.0515 128.445 75.0682 128.267 75.1593C128.089 75.2504 127.954 75.4085 127.892 75.5988C127.831 75.789 127.847 75.996 127.938 76.1743C128.028 76.3525 128.186 76.4875 128.376 76.5496L171.035 90.3781C171.225 90.4403 171.432 90.4243 171.611 90.3336C171.79 90.2429 171.925 90.0851 171.987 89.8947C172.049 89.7043 172.033 89.4969 171.943 89.3183C171.852 89.1397 171.694 89.0044 171.504 88.9423L171.501 88.9412L128.842 75.1127Z"
                  fill="#3968ED"
                />
                <path
                  d="M173.41 86.4958C173.604 86.3215 173.758 86.1066 173.86 85.8665C173.962 85.6263 174.011 85.3666 174.002 85.1057C173.993 84.8448 173.927 84.5891 173.808 84.3565C173.69 84.1239 173.522 83.9201 173.316 83.7595L175.365 77.7542L172.173 78.5833L170.717 84.159C170.47 84.5225 170.365 84.9641 170.422 85.3998C170.479 85.8356 170.693 86.2354 171.025 86.5235C171.357 86.8115 171.783 86.9678 172.223 86.9627C172.662 86.9576 173.085 86.7915 173.41 86.4958Z"
                  fill="#FFB7B7"
                />
                <path
                  d="M174.325 82.8517L171.229 81.4714L172.269 70.9956L171.301 60.4321L175.434 58.2007L176.311 59.3055L176.312 59.3305L176.776 72.6883L176.773 72.6984L174.325 82.8517Z"
                  fill="#3F3D56"
                />
                <path
                  d="M238.26 130H76.3337C76.2518 130.002 76.1716 129.976 76.1056 129.928C76.0397 129.879 75.9916 129.81 75.969 129.731C75.9549 129.678 75.9532 129.622 75.9642 129.567C75.9752 129.513 75.9984 129.462 76.0322 129.418C76.0661 129.374 76.1095 129.339 76.1592 129.314C76.2089 129.29 76.2636 129.277 76.319 129.277H238.231C238.317 129.274 238.402 129.3 238.471 129.351C238.541 129.403 238.591 129.476 238.613 129.56C238.625 129.613 238.625 129.667 238.613 129.72C238.6 129.773 238.576 129.822 238.542 129.865C238.508 129.907 238.466 129.941 238.417 129.964C238.368 129.988 238.314 130 238.26 130Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M54.8796 130H0.754306C0.672407 130.002 0.592233 129.976 0.526277 129.928C0.46032 129.879 0.412284 129.81 0.389655 129.731C0.375527 129.678 0.373882 129.622 0.384847 129.567C0.395811 129.513 0.41909 129.462 0.452897 129.418C0.486704 129.374 0.530135 129.339 0.579849 129.314C0.629562 129.29 0.684233 129.277 0.739653 129.277H54.85C54.9363 129.274 55.0212 129.3 55.0907 129.351C55.1603 129.403 55.2104 129.476 55.2329 129.56C55.2446 129.613 55.2443 129.667 55.232 129.72C55.2197 129.773 55.1957 129.822 55.1618 129.865C55.1279 129.907 55.0849 129.941 55.0361 129.964C54.9873 129.988 54.9338 130 54.8796 130Z"
                  fill="#CCCCCC"
                />
              </g>
              <defs>
                <clipPath id="clip0_1503_787">
                  <rect
                    width="238.244"
                    height="130"
                    fill="white"
                    transform="translate(0.378174)"
                  />
                </clipPath>
              </defs>
            </svg>
            <h2>Add Your First Property</h2>
            <p className="p_top_bottom_20">
              Lorem ipsum dolor sit amet consectetur. Ametnunc tris quisque
              ornare purus nunc urna purus.
            </p>
            <Link to="/property/addproperty">
              <BgThemeButton
                // onClick={handleCloseAddFirstProperty}
                className="m_0_auto"
                children={"Add"}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPropertyPage;
