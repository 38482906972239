import React from 'react'
import ChannelListing from '../Components/ChannelManagerSettings/ChannelListing'

const ChannelManagerSettingsPage = () => {

  return (
    <div className='main_container'>


    <ChannelListing/>
    </div>
  )
}

export default ChannelManagerSettingsPage