import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import { rooms_inner_lists } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { getDecryptedData } from "../assets/encryptStorage";


const RoomRatePlans = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const [loader, setLoader] = useState(true);

  const [orders, setOrders] = useState([]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const property_id = useSelector((state) => state.property.value);
  console.log(property_id.id);

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      // fetchRoomType(property_id.id);
    }
  }, [property_id]);

  // fetch data
  // http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/99f12663-7d6d-4d28-8f08-786b7daa73f1

  const fetchInfo = async (id) => {
    try {
      setLoader(true); // Set loading before sending API request
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.roomRates);
      // setLoader(false); // Stop loading
    } catch (error) {
      // setLoader(false); // Stop loading in case of error
      console.error(error);
    }
  };
  // useEffect(() => {
  //   fetchInfo(id);
  // }, []);
  // fetchUserData()

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => { },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
          {
            type: "rename",
            onAction: () => { },
            onPrimaryAction: async (value) => {
              const newItemsStrings = tabs.map((item, idx) => {
                if (idx === index) {
                  return value;
                }
                return item.content;
              });
              await sleep(1);
              setItemStrings(newItemsStrings);
              return true;
            },
          },
          {
            type: "duplicate",
            onPrimaryAction: async (value) => {
              await sleep(1);
              duplicateView(value);
              return true;
            },
          },
          {
            type: "edit",
          },
          {
            type: "delete",
            onPrimaryAction: async () => {
              await sleep(1);
              deleteView(index);
              return true;
            },
          },
        ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => { };

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
        type: "save-as",
        onAction: onCreateNewView,
        disabled: false,
        loading: false,
      }
      : {
        type: "save",
        onAction: onHandleSave,
        disabled: false,
        loading: false,
      };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setAddRoomRates({
        rate_plan_name: "",
        rate_plan_type: "",
        rate_rack_price: "",
        rate_bar_price: "",
        extra_child: "",
        extra_adult: "",
        extra_bed: "",
        rate_plan_status: 1,
        breakfast: "",
        lunch: "",
        dinner: "",
        rate_plan_action: null,
        base_category: "no",
      });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const getItemChecked = (id) => {
    // console.log(id);
  };

  // getItemChecked(selectedResources[0]);

  // getData
  // http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/edit/${id}
  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/edit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          }
        }
      );
      const data = await response.json();
      console.log(data.roomRate, "data.roomRate");

      const updatedData = {
        property_id: data.roomRate.property_id,
        rate_plan_name: data.roomRate.rate_plan_name,
        rate_plan_type: data.roomRate.rate_plan_type,
        rate_rack_price: data.roomRate.rate_rack_price,
        rate_bar_price: data.roomRate.rate_bar_price,
        extra_child: data.roomRate.extra_child,
        extra_adult: data.roomRate.extra_adult,
        extra_bed: data.roomRate.extra_bed,
        rate_plan_status: data.roomRate.rate_plan_status,
        breakfast: data.roomRate.breakfast,
        lunch: data.roomRate.lunch,
        dinner: data.roomRate.dinner,
        rate_plan_action: data.roomRate.rate_plan_action,
        base_category: data.roomRate.base_category,
      };

      setAddRoomRates(updatedData);
      // console.log(addRoomRates);
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = orders.map(
    ({
      id,
      rate_plan_type,
      rate_plan_name,
      rate_bar_price,
      room_name,
      planType,
      planName,
      price,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={room_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={rate_plan_type}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={rate_plan_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={rate_bar_price}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    console.log("removed");
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [addRoomRates, setAddRoomRates] = useState({
    rate_plan_name: "",
    rate_plan_type: "",
    rate_rack_price: "",
    rate_bar_price: "",
    extra_child: "",
    extra_adult: "",
    extra_bed: "",
    rate_plan_status: 1,
    breakfast: "",
    lunch: "",
    dinner: "",
    rate_plan_action: null,
    base_category: "no",
    room_name: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddRoomRates({
      ...addRoomRates,
      [event.target.name]: event.target.value,
    });
  };

  // delete
  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    console.log(deletedIds);

    // "http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    // now do whatever you want with the data
    console.log(data);
    fetchInfo();
    clearSelection();
    setShowLowerMenuMultipleSelect(false);
    setShowModelMenuActive(false);
  };

  const updateFunction = (id) => {
    console.log(id, "Update Function");

    const findOrder = orders.find((user) => user.id === id);
    // console.log(findOrder,"findOrder");
    // http://phplaravel-1082347-3787133.cloudwaysapps.com/api/v1/rooms/rates/update/${id}
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/rooms/rates/update/${id}`, {
      method: "POST",
      body: JSON.stringify(addRoomRates),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((json) => fetchInfo())
      .then((json) =>
        setAddRoomRates({
          rate_plan_name: "",
          rate_plan_type: "",
          rate_rack_price: "",
          rate_bar_price: "",
          extra_child: "",
          extra_adult: "",
          extra_bed: "",
          rate_plan_status: 1,
          breakfast: "",
          lunch: "",
          dinner: "",
          rate_plan_action: null,
          base_category: "no",
          room_name: "",
        })
      );
    clearSelection();
    setShowEditModelMenuActive(false);
  };
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  return (
    <>
      <SidebarInnerLists innerLists={rooms_inner_lists} />

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Room Rate Plans</div>
              {/* <div>
                <BgThemeButton
                  onClick={addModalButtonClickHandler}
                  children={" Add Rate Plan"}
                />
              </div> */}
            </div>

            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={orders.length}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: "Room Name" },
                  { title: "Plan Type" },
                  { title: "Plan Name" },
                  { title: "Price" },
                ]}
              >
                {rowMarkup}
              </IndexTable>

              {/* Single select */}
              {showLowerMenuSingleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        gap: "20px",
                      }}
                    >
                      <Button plain destructive onClick={showModelMenu}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                      <BgThemeButton
                        onClick={editModalButtonClickHandler}
                        children={"Edit"}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Multiple select */}
              {showLowerMenuMultipleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button plain destructive onClick={showModelMenu}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </LegacyCard>
          </div>

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-rate-plan-edit-model-container">
                  <h4>Add Rate Plan</h4>
                  <div className="edit-rooms-form-container">
                    <div className="form-container-div-full">
                      <VerticalInputSelectCustomCopy
                        options={planTypeOptions}
                        onChange={setAddItemDataHandleChange}
                        name="rate_plan_type"
                        type="text"
                        titleName="Plan Type"
                        value={addRoomRates.rate_plan_type}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="rate_rack_price"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Rack Price"
                        value={addRoomRates.rate_rack_price}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="rate_bar_price"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Bar Price"
                        value={addRoomRates.rate_bar_price}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="extra_adult"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Extra Adults"
                        value={addRoomRates.extra_adult}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="extra_bed"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child With Bed"
                        value={addRoomRates.extra_bed}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="extra_child"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child Without Bed"
                        value={addRoomRates.extra_child}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton children={"Add"} />
                    <BgOutlineThemeButton
                      children={"Discard"}
                      onClick={() => setShowAddModelMenuActive(false)}
                    />
                  </div>
                </section>
              </div>
            </>
          )}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-rate-plan-edit-model-container">
                  <h4>Edit Rate Plan</h4>
                  <div className="edit-rooms-form-container">
                    <div className="form-container-div-full">
                      <VerticalInputSelectCustomCopy
                        options={planTypeOptions}
                        onChange={setAddItemDataHandleChange}
                        name="rate_plan_type"
                        type="text"
                        titleName="Plan Type"
                        value={addRoomRates.rate_plan_type}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="rate_rack_price"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Rack Price"
                        value={addRoomRates.rate_rack_price}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="rate_bar_price"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Bar Price"
                        value={addRoomRates.rate_bar_price}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="extra_adult"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Extra Adults"
                        value={addRoomRates.extra_adult}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="extra_bed"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child With Bed"
                        value={addRoomRates.extra_bed}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        name="extra_child"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        titleName="Child Without Bed"
                        value={addRoomRates.extra_child}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      onClick={() => updateFunction(selectedResources[0])}
                      children={"Update"}
                    />
                    <BgOutlineThemeButton
                      children={"Discard"}
                      onClick={() => setShowEditModelMenuActive(false)}
                    />
                  </div>
                </section>
              </div>
            </>
          )}
          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={() => deleteData()} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default RoomRatePlans;
