import { configureStore } from "@reduxjs/toolkit";
import propertyReducer from "../Slice/propertyslice";
import loginTokenReducer from "../Slice/loginTokenSlice";
import isAuthenticatedReducer from "../Slice/isAuthenticatedSlice";
import propertiesReducer from '../Slice/propertiesSlice';
export const store = configureStore({
    reducer: {
        property: propertyReducer,
        loginToken: loginTokenReducer,
        isAuthenticated: isAuthenticatedReducer,
        properties: propertiesReducer,
    },
})