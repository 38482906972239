import React, { useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "./Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { getDecryptedData } from "../../assets/encryptStorage";

const AddCsvFile = ({
  setShowAddModelMenuActive,
  showAddModelMenuActive,
  title,
}) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  console.log(file?.name);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      showErrorToast("Please select a CSV file");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file); // Change the key to "file" to match the server's expectation

      // Replace 'your-backend-api-url' with the actual backend API endpoint
      const response = await fetch(
        `https://live.revcatalyst.in/api/v1/admin/import-competitors`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        showSuccessToast("File uploaded successfully:", data);
        window.location.reload();
      } else {
        const errorData = await response.json(); // Parse the error response
        showErrorToast("File upload failed. Status code: " + response.status);
        console.error("Error uploading file:", errorData);
      }
    } catch (error) {
      showErrorToast("An error occurred while uploading the file.");
      console.error("Error uploading file:", error);
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!file) {
  //     showErrorToast("Please select a CSV file");
  //     return;
  //   }

  //   var formdata = new FormData();
  //   formdata.append(
  //     "file",
  //     file[0],
  //   );

  //   var requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${userToken}`,
  //     },
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     "https://dev.revcatalyst.in/api/v1/admin/import-competitors",
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.log("error", error));
  // };
  return (
    <div className="file_content_block">
      <div className="bg-backdrop">
        <section
          style={{ width: "500px" }}
          className="room-plan-add-model-container"
        >
          <h4>{title}</h4>

          <form onSubmit={handleSubmit}>
            <div className="file_content_block">
              <input
                style={{ background: "red" }}
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="dropzone_file_input"
              />

              {!file ? (
                <div style={{ margin: "20px auto" }}>
                  <p className="dropzone_add_files_button">Add Files</p>
                  <p className="dropzone_drop_message">Only .csv files here</p>
                </div>
              ) : (
                <div style={{ margin: "20px auto" }}>
                  <p className="dropzone_add_files_button">
                    Selected File Name
                  </p>
                  <p className="dropzone_drop_message">{file?.name}</p>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <BgThemeButton type="submit" children={"Upload"} />

              <CheckWithoutBgUnderlineBtn
                type={"button"}
                onClick={() => setShowAddModelMenuActive(false)}
                children={"Discard"}
              />
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default AddCsvFile;
