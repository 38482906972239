import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const SidebarInnerLists = (props) => {
    const listsItems = props?.innerLists;
    const { innerLists } = props;
    const location = useLocation();

    return (
        <div className="footer_inner_lists">
            <ul>
                {innerLists &&
                    innerLists.map((item, index) => (
                        <li
                            className={`inner_list_item ${location.pathname === item.link ? 'active' : ''}`}
                            key={index}
                        >
                            <Link to={item.link}>{item.name}</Link>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

export default SidebarInnerLists;