import React from "react";
import LowRateInventory from "../Components/LowRateInventory";

const LowInventoryRatesPage = () => {
  return (
    <>
      <div className="main_container">
        <LowRateInventory />
      </div>
    </>
  );
};

export default LowInventoryRatesPage;
