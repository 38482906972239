import React from "react";
import Inventory from "../Components/Inventory";

const InventoryPage = () => {
  return (
    <>
      <div className="main_container">
        <Inventory />
      </div>
    </>
  );
};

export default InventoryPage;
