import React from "react";
import Sidebar from "../Components/Sidebar";
import Dashboard from "../Components/Dashboard";
import Dashboard2 from "../Components/Dashboard2";
import NewSidebar from "../Components/NewSidebar";
// import PropertySetupStepperForm from "../Components/PropertySetup/PropertySetupStepperForm";

const DashboardPage = () => {
  return (
    <>
      <div className="main_container">
        <Dashboard />
        {/* <PropertySetupStepperForm/> */}
      </div>
    </>
  );
};

export default DashboardPage;
