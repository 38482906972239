import React from "react";
import Channels from "../Components/Channels";
import RateCalender from "../Components/RateCalender/RateCalender";
import PropertyRateCalender from "../Components/RateCalender/PropertyRateCalender";

const PropertyRateCalenderPage = () => {
  return (
    <>
      <div className="main_container">
        <PropertyRateCalender />
      </div>
    </>
  );
};

export default PropertyRateCalenderPage;
