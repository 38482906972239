import React from "react";
import Sidebar from "../Components/Sidebar";
import RoomRatePlans from "../Components/RoomRatePlans";
import NewSidebar from "../Components/NewSidebar";

const RoomsRatePlanPage = () => {
  return (
    <>
      <div className="main_container">
        <RoomRatePlans />
      </div>
    </>
  );
};

export default RoomsRatePlanPage;
