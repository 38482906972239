import React, { useCallback, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
} from "@shopify/polaris";

import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";

const AddAgentProfile = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];
  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag

  const [selectedTags, setSelectedTags] = useState([
    "Rustic",
    "Antique",
    "Vinyl",
    "Refurbished",
    "Singh",
  ]);
  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    []
  );
  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const navigate = useNavigate();

  return (
    <>
      <div className="edit-rooms-full-container">
        <div>
          <Page
            backAction={{ content: "Products", url: `/roomstype` }}
            title="Add Agent"
            compactTitle
            pagination={{
              hasPrevious: true,
              hasNext: true,
            }}
          ></Page>
        </div>
        <div className="edit-rooms-full-container-div">
          <div className="edit-rooms-full-container-left">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "591px",
              }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle1}
                      ariaExpanded={open1}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Contact Person</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open1}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Name"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Phone"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Email"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle2}
                      ariaExpanded={open2}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Business details</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open2}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Company Details"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="GST No."
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Business Type"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle3}
                      ariaExpanded={open3}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Address</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open3}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="City"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Country"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Pincode"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div">
                          <TextField
                            label="Address"
                            onChange={handleChange}
                            multiline={4}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle4}
                      ariaExpanded={open4}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Agent Categories</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open4}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <Select
                            className="textfield"
                            focused={false}
                            label="Select Agent Category"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <TextField
                            label="Description"
                            onChange={handleChange}
                            multiline={4}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              {/* <div>
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Media
                    <Button
                      onClick={handleToggle5}
                      ariaExpanded={open5}
                      ariaControls="basic-collapsible"
                    >
                      <Icon source={ChevronDownMinor} />
                    </Button>
                  </div>
                  <Collapsible
                    open={open5}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <DropZone onDrop={handleDropZoneDrop} variableHeight>
                      {uploadedFiles}
                      {fileUpload}
                    </DropZone>
                  </Collapsible>
                </LegacyStack>
              </LegacyCard>
            </div>
            <div>
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Search Engine Listing
                    <Button
                      onClick={handleToggle6}
                      ariaExpanded={open6}
                      ariaControls="basic-collapsible"
                    >
                      <Icon source={ChevronDownMinor} />
                    </Button>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#888",
                    }}
                  >
                    Add a title and description to see how this Room might
                    appear
                    <br />
                    in a search engine listing
                  </p>
                  <Collapsible
                    open={open6}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <div className="edit-rooms-form-container">
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Page Title"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Meta Description"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="URL Handle"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </Collapsible>
                </LegacyStack>
              </LegacyCard>
            </div> */}
            </form>
            <div className="rooms-edit-left-container-buttons">
              <div>
                <BgThemeButton children={"Update"} />
              </div>
              <div>
                <Link style={{ textDecoration: "none" }} to={"/agents"}>
                  <BgOutlineThemeButton children={"Discard"} />
                </Link>
              </div>
            </div>
          </div>

          <div className="edit-rooms-full-container-right">
            <form
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle7}
                      ariaExpanded={open7}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Temporary Bookings</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open7}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="form-container-div-full">
                        <TextField
                          label="Max Hold Time"
                          onChange={handleSelectStatusChange}
                        />
                      </div>
                      <div className="form-container-div-full">
                        <Select
                          label="Settle Form"
                          options={statusOptions}
                          onChange={handleSelectStatusChange}
                          value={selectedOption}
                        />
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>

              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle8}
                      ariaExpanded={open8}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Asign Hotel</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open8}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <Select
                            className="textfield"
                            focused={false}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <LegacyStack spacing="tight">{tagMarkup}</LegacyStack>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              {/* <div>
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "300px",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Pricing
                    <Button
                      onClick={handleToggle9}
                      ariaExpanded={open9}
                      ariaControls="basic-collapsible"
                    >
                      <Icon source={ChevronDownMinor} />
                    </Button>
                  </div>
                  <Collapsible
                    open={open9}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <div className="edit-rooms-form-container">
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Rack Price"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Extra Adults"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Child with Bed"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Child Without Bed"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </Collapsible>
                </LegacyStack>
              </LegacyCard>
            </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAgentProfile;
