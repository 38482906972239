import React, { useEffect, useState } from 'react'
import styles from "./styles/AddTargetPageNew.module.scss"
import VerticalInputSelectCustomCopy from '../Components/CustomComponents/VerticalInputSelectCustomCopy'
import VerticalInputFieldCustom from '../Components/CustomComponents/VerticalInputFieldCustom'
import { BgThemeButton } from '../Components/UI/Buttons'
import Loader from '../Components/UI/Loader/Loader'
import { useSelector } from 'react-redux'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { showErrorToast, showSuccessToast } from '../assets/toastUtils'
import { SvgCancel, SvgMinus, SvgPlus } from '../Components/svgIcons/Icons'
import { SearchOrAdd, SearchSelect } from '../Components/CustomComponents/NewCustomComp/Search/Search'
import { PrimaryInput } from '../Components/CustomComponents/NewCustomComp/Inputs/Inputs'
import { SelectBasic } from '../Components/CustomComponents/NewCustomComp/SelectMultiSelect/SelectMultiSelect'
import { getDecryptedData } from '../assets/encryptStorage'

const AddTargetPageNew = ({ closePopup, refreshListApi }) => {
    const [currentStep, setCurrentStep] = useState("setOccupancy")
    // const userToken = JSON.parse(localStorage.getItem("userToken"))
    const userToken = getDecryptedData("encryptToken");
    const property_id = useSelector((state) => state.property.value)


    //  setOccupancy 
    const [selectedArea, setSelectedArea] = useState("")
    const areaOptions = [
        { label: "Hilly Areas", value: "Hilly Areas" },
        { label: "Plain Areas", value: "Plain Areas" },
    ];

    const [months, setMonths] = useState([
        {
            month: "January",
            value: ""
        },
        {
            month: "February",
            value: ""
        },
        {
            month: "March",
            value: ""
        },
        {
            month: "April",
            value: ""
        },
        {
            month: "May",
            value: ""
        },
        {
            month: "June",
            value: ""
        },


        {
            month: "July",
            value: ""
        },
        {
            month: "August",
            value: ""
        },
        {
            month: "September",
            value: ""
        },
        {
            month: "October",
            value: ""
        },
        {
            month: "November",
            value: ""
        },
        {
            month: "December",
            value: ""
        },])
    // end set occupancy

    // setTargets
    // const [expenseItems, setExpenseItems] = useState([{ type: "", amount: "" }])
    const [totalExpanse, setTotalExpanse] = useState(0)


    // new 
    const [expenseItems, setExpenseItems] = useState([])
    const [currentItem, setCurrentItem] = useState([{ type: "", amount: "" }])

    const handleExpenseChange = (key, value) => {
        setCurrentItem({ ...currentItem, [key]: value });
    };



    const addExpenseItem = () => {
        if (currentItem.type && currentItem.amount) {
            setExpenseItems([...expenseItems, currentItem]);
            // Reset current item after adding
            setCurrentItem({ type: "", amount: "" });
        }
        console.log(currentItem, "addCurrentItems")
    };

    const handleDelete = (index) => {
        setExpenseItems(
            expenseItems.filter((_, i) => (i !== index))
        )
    }


    // Total profit margin
    const [profitMargin, setProfitMargin] = useState("");
    const [yearlyTarget, setYearlyTarget] = useState(0);
    const [rooms, setRooms] = useState({
        totalRooms: "",
    });

    const handleRoomsChange = (e) => {
        const { name, value } = e.target;

        setRooms((prev) => {
            return {
                ...prev,
                [name]: +value,
            };
        });
    };

    const [profitMarg, setProfitMarg] = useState(0);


    // console.log(yearlyTarget.toFixed(2),'yearly')


    // setTargetsEnd

    const pathname = useParams()
    const { propertyId } = pathname


    const location = useLocation(); // Get the full location object


    const propertyName = location.search && location.search.replaceAll("?", "").replaceAll("-", " ")

    // Get Occupancies API

    const [occupancies, setOccupancies] = useState([])
    const [recommended, setRecommended] = useState([])
    useEffect(() => {
        const fetchOccupancies = async () => {
            if (!selectedArea) return // Only make the API call if area is selected

            const formData = new FormData()
            formData.append("area_type", selectedArea)

            const requestOptions = {
                method: "POST",
                body: formData,
                redirect: "follow"
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/occupancies`, requestOptions)
                const result = await response.json()
                // console.log(result)
                setOccupancies(result?.occupancies.map((item, index) => {
                    return {
                        ...item,
                        recommendPercent: item?.occupancy_percent
                    }
                }) || [])




                setRecommended(result?.occupancies || [])
                // setCurrentStep("setTarget")
            } catch (error) {
                console.error("Error:", error)
            }
        }

        fetchOccupancies()
    }, [selectedArea])

    const handleOccupancyChange = (month, value) => {
        const updatedOccupancies = occupancies.map((item, i) =>
            item.month === month ? { ...item, occupancy_percent: +value } : item
        )
        setOccupancies(updatedOccupancies) // Update the state with the new occupancies
    }
    console.log(occupancies, 'data');



    // Update Occupancy

    const handleUpdateOccupancy = async () => {
        const raw = JSON.stringify({
            "property_id": propertyId,
            "year": new Date().getFullYear(),
            "occupancies": occupancies
        });

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/admin/update-occupancy`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    method: "POST",
                    body: raw
                }
            );
            const data = await response.json();

            if (data.message === "Unauthorised.") {
                console.log("Unauthorized access.");
            }

            if (data.success) {
                console.log("Occupancy updated successfully.");
            }
        } catch (error) {
            console.error("Error updating occupancy:", error);
        }
    };

    const addonsForBanquetEvents = [
        { id: 1, label: "Loans (Liability)", value: "Loans (Liability)" },
        { id: 2, label: "Secured Loans", value: "Secured Loans" },
        { id: 3, label: "Unsecured Loans", value: "Unsecured Loans" },
        { id: 4, label: "Current Liabilities", value: "Current Liabilities" },
        { id: 5, label: "Duties & Taxes", value: "Duties & Taxes" },
        {
            id: 6,
            label: "Accountancy Charges Payable",
            value: "Accountancy Charges Payable",
        },
        {
            id: 7,
            label: "Electricity Bill Payable",
            value: "Electricity Bill Payable",
        },
        { id: 8, label: "GST Payable", value: "GST Payable" },
        {
            id: 9,
            label: "Staff Salary & Wages Payable",
            value: "Staff Salary & Wages Payable",
        },
        {
            id: 10,
            label: "Telephone Bill Payable",
            value: "Telephone Bill Payable",
        },
        { id: 11, label: "Crockery & Cutlery", value: "Crockery & Cutlery" },
        { id: 12, label: "Furniture & Fixture", value: "Furniture & Fixture" },
        { id: 13, label: "Televisions", value: "Televisions" },
        { id: 14, label: "ODC & Labour Charges", value: "ODC & Labour Charges" },
        { id: 15, label: "Cable Charges Exp.", value: "Cable Charges Exp." },
        { id: 16, label: "Bank Charges", value: "Bank Charges" },
        { id: 17, label: "Cable Charges Exp.", value: "Cable Charges Exp." },
        { id: 18, label: "Depreciation Expenses", value: "Depreciation Expenses" },
        { id: 19, label: "Discount/Allowance", value: "Discount/Allowance" },
        {
            id: 20,
            label: "Fee and Subscription Expenses",
            value: "Fee and Subscription Expenses",
        },
        { id: 21, label: "Garbage Expenses", value: "Garbage Expenses" },
        { id: 22, label: "Gas Cylinder LPG.", value: "Gas Cylinder LPG." },
        { id: 23, label: "Interest Paid on GST", value: "Interest Paid on GST" },
        {
            id: 24,
            label: "Laundry & Dry Cleaning Expenses",
            value: "Laundry & Dry Cleaning Expenses",
        },
        {
            id: 25,
            label: "Repair & Maintenance Electrical",
            value: "Repair & Maintenance Electrical",
        },
        {
            id: 26,
            label: "Sale Promotion Expenses",
            value: "Sale Promotion Expenses",
        },
        { id: 27, label: "Staff Welfare", value: "Staff Welfare" },
        { id: 28, label: "Staff Uniform", value: "Staff Uniform" },
        { id: 29, label: "Staff Accommodation", value: "Staff Accommodation" },
        { id: 30, label: "Telephone Expenses", value: "Telephone Expenses" },
        { id: 31, label: "Water Expenses", value: "Water Expenses" },
        { id: 32, label: "Security Services", value: "Security Services" },
        { id: 33, label: "Landscaping Expenses", value: "Landscaping Expenses" },
        { id: 34, label: "HVAC Maintenance", value: "HVAC Maintenance" },
        { id: 35, label: "Pest Control Services", value: "Pest Control Services" },
        { id: 36, label: "Building Insurance", value: "Building Insurance" },
        {
            id: 37,
            label: "Fire Safety Compliance",
            value: "Fire Safety Compliance",
        },
        {
            id: 38,
            label: "Parking Lot Maintenance",
            value: "Parking Lot Maintenance",
        },
        { id: 39, label: "Common Area Cleaning", value: "Common Area Cleaning" },
        { id: 40, label: "Elevator Maintenance", value: "Elevator Maintenance" },
        { id: 41, label: "Roof Repairs", value: "Roof Repairs" },
        {
            id: 42,
            label: "Window Cleaning Services",
            value: "Window Cleaning Services",
        },
        { id: 43, label: "Exterior Painting", value: "Exterior Painting" },
        { id: 44, label: "Waste Management", value: "Waste Management" },
    ];

    const submitPropertyTargets = async (propertyId, totalExpanse, yearlyTarget, totalRooms) => {

        const requestOptions = {
            method: "POST",
            redirect: "follow"
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/add-property-target?property_id=${propertyId}&total_expense=${totalExpanse}&targeted_yearly_earning=${yearlyTarget.toFixed(2)}&total_rooms=${totalRooms}`, requestOptions)
            const data = await response.json();
            if (data.message === "Unauthorised.") {

            }
            if (data.success) {
                // showSuccessToast("added")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Generate List Api
    const fetchRateList = async () => {
        const requestOptions = {
            method: "POST",
            redirect: "follow"
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/dyanamic-price?property_id=${propertyId}&ota_charge_percentage=0`, requestOptions)
            const result = await response.json();
            if (result.success) {
                console.log("Generated");
            }
        } catch (error) {
            console.log(error)
        }
    }

    // submit handle
    const handleSubmitAll = async (e) => {
        e.preventDefault();
        try {
            await Promise.all([
                handleUpdateOccupancy(),
                fetchRateList(),
                submitPropertyTargets(propertyId, allCalculations(expenseItems, buffer, profitMargin)?.totalExpenses, allCalculations(expenseItems, buffer, profitMargin).yearlyEarning, rooms.totalRooms)
            ]);
            closePopup();
            showSuccessToast("Occupancy and Target added successfully!");
            refreshListApi();

        } catch (error) {
            console.error("Error during submission:", error);
            showErrorToast("An error occurred while submitting.");
        }
    };

    // Validation 
    const handleNextStepTwo = () => {
        if (expenseItems.length <= 0) {
            showErrorToast("Please Add at-least One Expense")
            return
        } else {
            setCurrentStep('setGenerateTarget')
        }
    }
    const handleNextStepOne = () => {
        if ((!selectedArea) || (!rooms.totalRooms)) {
            showErrorToast("Fill the No. of Rooms Before Stepping Next")
            return;
        } else {
            setCurrentStep("setTarget")
        }
    }


    // 
    const numbers = [
        { label: "5%", value: 5 },
        { label: "10%", value: 10 },
        { label: "15%", value: 15 },
        { label: "20%", value: 20 },
        { label: "25%", value: 25 },
        { label: "30%", value: 30 },
        { label: "35%", value: 35 },
        { label: "40%", value: 40 },
        { label: "45%", value: 45 },
        { label: "50%", value: 50 },
    ]

    const [buffer, setBuffer] = useState("");

    const handleBufferChange = (data) => {
        setBuffer({ ...buffer, ["value"]: data })
    }

    // For Adding Other Expense
    const addOtherExpense = (data) => {
        setCurrentItem({ amount: "", type: data });

    }
    console.log(buffer, "bufferNumber");

    // calculations

    // useEffect(() => {
    //     const totalExpenses = expenseItems.reduce((acc, curr) => acc + Number(curr.amount || 0), 0);
    //     setTotalExpanse(totalExpenses)

    //     const percentExp = (totalExpenses * profitMargin) / 100;
    //     setProfitMarg(percentExp)
    //     const yearlyEarning = totalExpenses + percentExp;

    //     setYearlyTarget(yearlyEarning); 
    // }, [expenseItems, profitMargin, buffer]);
    // console.log(totalExpanse, 'expenseItems')

    function allCalculations(expenseItems, buffer, profitMargin) {

        // console.log(totalExpenses ,"totalExpenses")
        console.log(buffer, "buffer")
        console.log(profitMargin, "profitMargin")

        const totalExpenses = expenseItems && expenseItems.reduce((acc, curr) => acc + Number(curr.amount || 0), 0);
        const bufferPercentage = buffer?.value || 20;
        const profitMarginPercentage = profitMargin || 0;

        const bufferExp = (totalExpenses * bufferPercentage) / 100;
        console.log(bufferExp, "bufferExp")
        const totalExpBuffer = totalExpenses + bufferExp;

        const percentExp = (totalExpBuffer * profitMarginPercentage) / 100;
        const yearlyEarning = totalExpBuffer + percentExp;
        return {
            bufferPercentage,
            totalExpenses,
            bufferExp,
            totalExpBuffer,
            percentExp,
            yearlyEarning
        }
    }


    return (
        <div style={{ maxWidth: "800px", }}>
            <div className={`${styles.hotelName} flex justify_content_between align_items_center`}>
                <h3>{propertyName}</h3>

                <span className={styles.crossBtn} onClick={closePopup}>
                    <SvgCancel />
                </span>
            </div>
            <div className={`${styles.targetSteps_wrapper}`} style={{ width: "600px" }}>
                <div onClick={() => setCurrentStep("setOccupancy")} className={`${styles.cursor} ${currentStep === 'setOccupancy' ? styles.targetSteps_card : styles.targetSteps_card_white}`}>
                    <h1>Step 1</h1>
                </div>
                <div onClick={handleNextStepOne} className={currentStep === "setTarget" ? styles.targetSteps_card : styles.targetSteps_card_white}>
                    <h1>Step 2</h1>
                </div >
                <div onClick={handleNextStepTwo} className={`${currentStep === 'setGenerateTarget' ? styles.targetSteps_card : styles.targetSteps_card_white}`}>
                    <h1>Step 3</h1>
                </div>
            </div>

            {currentStep === 'setOccupancy' && (
                <div style={{ width: "600px", margin: "0 auto" }} >
                    <div className={` ${styles.white_wrapper}`}>

                        <div style={{ display: 'flex', gap: "10px" }}>
                            <div style={{ width: "50%" }}>
                                <VerticalInputSelectCustomCopy titleName={"Area"} options={areaOptions} value={selectedArea}
                                    onChange={(e) => setSelectedArea(e.target.value)} />

                            </div>
                            <div style={{ width: "50%" }}>

                                <VerticalInputFieldCustom
                                    titleName={"Total No. of Rooms :"}
                                    value={rooms.totalRooms || ""}
                                    name={"totalRooms"}
                                    onChange={(e) => handleRoomsChange(e)}
                                    type={"number"} />

                            </div>
                        </div>
                        <div className={styles.firstFormWrapper}>

                            {(selectedArea && occupancies && occupancies.length) === 0 ? (
                                <Loader />
                            ) : (
                                <>
                                    <div className={styles.months}>
                                        {occupancies.length > 0 && (
                                            <div className={styles.monthHead}>
                                                <span style={{ color: "red" }}>Recommended</span>
                                                <span style={{ paddingRight: "10px" }}>Occupancies</span>
                                            </div>

                                        )}
                                        {occupancies.slice(0, 6).map((items, index) =>
                                            <div key={index} className={styles.firstFormCard}>
                                                <span>{items.month}</span>

                                                <div key={index} className={styles.recommended_percent}>
                                                    {items.recommendPercent}
                                                </div>

                                                <span>
                                                    <VerticalInputFieldCustom type={"number"} onChange={(e) => handleOccupancyChange(items.month, e.target.value)} value={items?.occupancy_percent || ""} />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.months}>
                                        {occupancies.length > 0 && (
                                            <div className={styles.monthHead}>
                                                <span style={{ color: "red" }}>Recommended</span>
                                                <span style={{ paddingRight: "10px" }}>Occupancies</span>
                                            </div>

                                        )}
                                        {occupancies.slice(6, 12).map((items, index) =>
                                            <div key={index + 6} className={styles.firstFormCard}>
                                                <span>{items.month}</span>

                                                <div key={index} className={styles.recommended_percent}>
                                                    {items.recommendPercent}
                                                </div>

                                                <span>
                                                    <VerticalInputFieldCustom type={"number"} min={0} max={100} onChange={(e) => handleOccupancyChange(items.month, e.target.value)} value={items?.occupancy_percent || ""} />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>

                    </div>

                    <div>
                        <BgThemeButton
                            onClick={handleNextStepOne}
                            // onClick={handleUpdateOccupancy} 
                            children={"Next"} />
                    </div>
                </div>
            )}


            {currentStep === "setTarget" && (
                <div style={{ width: "600px", margin: "0 auto" }}>
                    <div className={`${styles.white_wrapper} ${styles.secondForm_wrapper}`}>
                        {/* <h1>Expenses</h1> */}
                        {/* {expenseItems.map((item, index) => ( */}
                        <div className={styles.secondForm_expCard}>
                            <span style={{ width: '40%' }}>
                                {/* <VerticalInputSelectCustomCopy
                                    titleName={"Expense Type"}
                                    options={addonsForBanquetEvents}
                                    value={currentItem.type}
                                    onChange={(e) => handleExpenseChange("type", e.target.value)}
                                /> */}
                                <SearchOrAdd label={"Expense Type"} defaultSelected={currentItem.type} options={addonsForBanquetEvents} handleOptionChange={addOtherExpense} optionKeyName={"label"} selectedKeyName={"value"} />
                            </span>
                            <span>
                                <VerticalInputFieldCustom
                                    titleName={'Expense Amount (₹)'}
                                    type={"number"}
                                    value={currentItem.amount}
                                    onChange={(e) => handleExpenseChange("amount", e.target.value)}
                                />
                            </span>
                            <span style={{ cursor: "pointer" }} onClick={addExpenseItem}>
                                <SvgPlus />
                            </span >
                        </div>
                        {/* ))} */}
                    </div>


                    {expenseItems.length > 0 ? (
                        <>
                            <div className={`${styles.white_wrapper}`}>
                                <table className={styles.secondForm_table}>
                                    <tr>
                                        <th className={styles.w_50}>Expense Type</th>
                                        <th className={styles.w_35}>Expense Amount</th>
                                        <th />
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <div style={{ minHeight: "fit-content", maxHeight: "100px", overflow: "scroll" }}>
                                                <table style={{ width: "100%" }}>
                                                    <tbody>
                                                        {expenseItems.map((items, index) =>
                                                            <>
                                                                <tr key={index} >
                                                                    <td style={{ width: "43%" }} className={`${styles.w_70} ${styles.borderTop}`}>{items.type}</td>
                                                                    <td className={`${styles.w_25} ${styles.borderTop}`}>₹ {items.amount}</td>
                                                                    {expenseItems.length > 0 ? (
                                                                        <td className={`${styles.w_5} ${styles.borderTop}`}>
                                                                            <span style={{ cursor: "pointer" }} onClick={() => handleDelete(index)}>
                                                                                <SvgMinus />

                                                                            </span>
                                                                        </td>
                                                                    ) : (null)}

                                                                </tr>
                                                            </>
                                                        )}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </td>
                                    </tr>

                                    <tr className={styles.totalExpanse}>
                                        <td colSpan={3} >
                                            <hr />
                                        </td>
                                    </tr>

                                    {/* //expanse */}
                                    <tr className={styles.totalExpanse}>
                                        <td className={`${styles.w_72} ${styles.td_gap}`}>Total Expenses Amount :</td>
                                        <td>₹ {allCalculations(expenseItems, buffer, profitMargin)?.totalExpenses}</td>

                                    </tr>

                                    <tr className={styles.totalExpanse}>
                                        <td className={`${styles.w_72} ${styles.td_flex}`}>Buffer :</td>
                                        <td className='flex justify_content_end align_items_center'>
                                            <div className={styles.resultInputs}>
                                                <SelectBasic
                                                    options={numbers}
                                                    optionKeyName={"label"}
                                                    selectedKeyName={"value"}
                                                    defaultSelected={{ label: "20%" }}
                                                    size={"small"}
                                                    handleSelectTagChange={handleBufferChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className={styles.totalExpanse}>
                                        <td className={`${styles.w_72} ${styles.td_flex}`}>Buffer Amount :</td>
                                        <td>
                                           {buffer.value===20 ? (
                                            <>
                                            <span>Recommended</span>
                                            </>
                                           ):""}{allCalculations(expenseItems, buffer, profitMargin)?.bufferExp}
                                        </td>
                                    </tr>

                                    <tr className={styles.totalExpanse}>
                                        <td className={`${styles.w_72} ${styles.td_flex}`}>Total Expense with Buffer</td>
                                        <td>{allCalculations(expenseItems, buffer, profitMargin)?.totalExpBuffer}</td>
                                    </tr>

                                    <tr className={styles.totalExpanse} >
                                        <td className={`${styles.w_72} ${styles.td_flex}`}> Profit Margin (%) :</td>
                                        <td style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <div className={styles.resultInputs}>
                                                <PrimaryInput
                                                    size={"small"}
                                                    type={"number"}
                                                    value={profitMargin || ""}
                                                    onChange={(e) => setProfitMargin(Number(e.target.value))}
                                                />
                                                {/* <VerticalInputFieldCustom
                                                    style={{ height: "15px", padding: "0", margin: "0", textAlign: "center" }}
                                                    value={profitMargin || ""}
                                                    onChange={(e) => setProfitMargin(Number(e.target.value))}
                                                    type={"number"}
                                                /> */}
                                            </div>

                                        </td>
                                    </tr>

                                    <tr className={styles.totalExpanse}>
                                        <td className={`${styles.w_72} ${styles.td_gap}`}>Profit Amount :</td>
                                        <td>₹ {allCalculations(expenseItems, buffer, profitMargin)?.percentExp}</td>

                                    </tr>
                                    <tr className={styles.totalExpanse}>
                                        <td className={`${styles.w_72} ${styles.td_gap}`}>Targeted Yearly Earning :</td>
                                        <td> ₹ {allCalculations(expenseItems, buffer, profitMargin)?.yearlyEarning}</td>

                                    </tr>
                                </table>
                            </div>
                        </>
                    ) : (
                        null
                    )}


                    <div>
                        <BgThemeButton onClick={handleNextStepTwo} children={"Next"} />
                    </div>

                </div>
            )}

            {currentStep === "setGenerateTarget" && (
                <form style={{ width: "600px" }} onSubmit={handleSubmitAll} action="">
                    <div className={styles.thirdForm_wrapper}>
                        <div className={`${styles.white_wrapper}`}>
                            <h4> <span style={{ fontSize: "14px", fontWeight: "600" }}>Total Number of Rooms :</span> {rooms.totalRooms}</h4>
                            <h4 style={{ marginTop: "10px" }}> <span style={{ fontSize: "14px", fontWeight: "600" }}>Area :</span> {selectedArea}</h4>
                            <div style={{ justifyContent: "left", margin: "0" }} className={styles.firstFormWrapper}>

                                <div style={{ width: "80%", gap: "30px" }} className={styles.firstFormWrapper}>
                                    <div style={{ width: "50%", paddingRight: "15px", borderRight: "0.5px solid #CCCCCC" }} className={styles.firstForm_monthData}>
                                        <div style={{ display: "flex" }} className={`${styles.firstFormCard}`}>
                                            <span style={{ width: "60%", fontWeight: "600" }}>Months</span>
                                            <span style={{ fontWeight: "600" }}>
                                                Occupancy
                                            </span>
                                        </div>

                                        {occupancies.slice(0, 6).map((items, index) =>
                                            <div style={{ display: "flex" }} key={index} className={`${styles.firstFormCard}`}>
                                                <span style={{ width: "60%" }}>{items.month} : {" "}</span>
                                                <span>
                                                    {items?.occupancy_percent}
                                                </span>
                                            </div>
                                        )}
                                    </div>


                                    <div style={{ width: "50%" }} className={styles.firstForm_monthData}>
                                        <div style={{ display: "flex" }} className={`${styles.firstFormCard}`}>
                                            <span style={{ width: "60%", fontWeight: "600" }}>Months</span>
                                            <span style={{ fontWeight: "600" }}>
                                                Occupancy
                                            </span>
                                        </div>
                                        {occupancies.slice(6, 13).map((items, index) =>
                                            <div style={{ display: "flex" }} key={index} className={styles.firstFormCard}>
                                                <span style={{ width: "60%" }}>{items.month} : {" "}</span>
                                                <span>
                                                    {items?.occupancy_percent}
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={`${styles.white_wrapper} ${styles.thirdForm_cards}`}>
                            <h4>Property Targets</h4>
                            <div className={styles.secondForm_data}>
                                <div className={styles.thirdForm_sndResult}>
                                    <span className={styles.thirdForm_sndResult_one}>Total Expenses:</span>
                                    <span>₹ {allCalculations(expenseItems, buffer, profitMargin)?.totalExpenses}</span>
                                </div>
                                <div className={styles.thirdForm_sndResult}>
                                    <span className={styles.thirdForm_sndResult_one}>Buffer:</span>
                                    <span>{allCalculations(expenseItems, buffer, profitMargin)?.bufferPercentage} %</span>
                                </div>
                                <div className={styles.thirdForm_sndResult}>
                                    <span className={styles.thirdForm_sndResult_one}>Total Expenses with Buffer:</span>
                                    <span>₹ {allCalculations(expenseItems, buffer, profitMargin)?.bufferExp}</span>
                                </div>
                                <div className={styles.thirdForm_sndResult}>
                                    <span className={styles.thirdForm_sndResult_one}>Profit Margin:</span>
                                    <span>{profitMargin} %</span>
                                </div>
                                <div className={styles.thirdForm_sndResult}>
                                    <span className={styles.thirdForm_sndResult_one}>Profit Amount:</span>
                                    <span>₹ {allCalculations(expenseItems, buffer, profitMargin)?.percentExp}</span>
                                </div>
                                <div className={styles.thirdForm_sndResult}>
                                    <span className={styles.thirdForm_sndResult_one}>Targeted Yearly Earning :</span>
                                    <span>₹ {allCalculations(expenseItems, buffer, profitMargin)?.yearlyEarning}</span>
                                </div>
                                {/* <div className={styles.thirdForm_sndResult}>
                                    <span className={styles.thirdForm_sndResult_one}>Total Number of Rooms :</span>
                                    <span>{}</span>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div>
                        <BgThemeButton type="submit" children={"Submit"} />
                    </div>
                </form>
            )}

        </div>
    )
}

export default AddTargetPageNew
