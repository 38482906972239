import React from "react";
import Property from "../Components/Property";
import AllProperties from "../Components/AllProperties";

const PropertyPage = () => {
  return (
    <>
      <div className="main_container">
        <AllProperties />
      </div>
    </>
  );
};

export default PropertyPage;
