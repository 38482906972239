const NoDataFound = () => {
  return (
    <div className="upgrade-bg-full-container">
      <div className="upgrade-full-container">
        <div className="upgrade-full-container-div" style={{}}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="175"
              height="130"
              viewBox="0 0 175 130"
              fill="none"
            >
              <path
                d="M127.536 123.52H124.076L122.429 110.172H127.536V123.52Z"
                fill="#9E616A"
              />
              <path
                d="M129.364 129.516H126.882L126.439 127.173L125.304 129.516H118.722C118.406 129.516 118.099 129.415 117.845 129.228C117.591 129.041 117.403 128.778 117.309 128.477C117.215 128.175 117.22 127.852 117.323 127.554C117.426 127.255 117.621 126.998 117.881 126.819L123.138 123.188V120.82L128.666 121.15L129.364 129.516Z"
                fill="#2F2E41"
              />
              <path
                d="M148.098 119.152L145.321 121.218L136.034 111.489L140.133 108.441L148.098 119.152Z"
                fill="#9E616A"
              />
              <path
                d="M153.142 122.874L151.15 124.355L149.397 122.739L149.884 125.296L144.602 129.224C144.349 129.412 144.042 129.515 143.727 129.516C143.411 129.518 143.104 129.419 142.849 129.233C142.594 129.047 142.404 128.785 142.309 128.484C142.214 128.184 142.217 127.86 142.318 127.561L144.37 121.512L142.957 119.611L147.59 116.577L153.142 122.874Z"
                fill="#2F2E41"
              />
              <path
                d="M131.567 54.6497C131.567 54.6497 138.133 60.3225 135.104 76.4943L131.405 92.34L143.467 112.351L139.621 117.03L123.94 95.3703L118.258 82.0412L116.664 58.3181L131.567 54.6497Z"
                fill="#2F2E41"
              />
              <path
                d="M121.389 85.8397L120.994 91.9561L122.203 118.02L127.89 117.03L128.977 98.4724L121.389 85.8397Z"
                fill="#2F2E41"
              />
              <path
                d="M48.4062 126.658L51.5011 126.658L52.9732 114.721L48.4058 114.721L48.4062 126.658Z"
                fill="#FFB6B6"
              />
              <path
                d="M47.6167 125.648L48.4878 125.648L51.8887 124.265L53.7115 125.648H53.7117C54.7418 125.648 55.7297 126.057 56.4581 126.785C57.1865 127.514 57.5957 128.502 57.5958 129.532V129.658L47.6169 129.658L47.6167 125.648Z"
                fill="#2F2E41"
              />
              <path
                d="M60.4004 124.708L63.4191 125.39L67.4847 114.07L63.0294 113.064L60.4004 124.708Z"
                fill="#FFB6B6"
              />
              <path
                d="M59.8529 123.549L60.7026 123.74L64.3247 123.14L65.7981 124.891L65.7983 124.891C66.8031 125.118 67.6766 125.735 68.2266 126.606C68.7767 127.477 68.9583 128.53 68.7314 129.535L68.7036 129.658L58.9697 127.46L59.8529 123.549Z"
                fill="#2F2E41"
              />
              <path
                d="M44.4874 53.6811C43.0364 58.0573 43.1898 62.8075 44.8362 67.9058L45.54 96.6104C45.54 96.6104 44.4914 115.434 47.875 122.058H52.7821L55.2117 94.8914L55.5168 76.6567L66.0373 92.0406L59.3365 121.614L65.376 122.154L76.2496 90.0599L62.8338 58.9229L44.4874 53.6811Z"
                fill="#2F2E41"
              />
              <path
                d="M101.076 41.216C104.83 37.8337 110.413 36.133 115.096 38.0296C110.292 40.6966 106.586 44.9768 104.635 50.1129C103.889 52.0965 103.266 54.3802 101.433 55.4421C100.292 56.1029 98.8722 56.1352 97.5929 55.8163C96.3136 55.4972 95.1409 54.8578 93.9843 54.2247L93.6553 54.2173C95.1326 49.3856 97.323 44.5983 101.076 41.216Z"
                fill="#E6E6E6"
              />
              <path
                d="M115.07 38.1323C110.922 38.6683 107.049 40.4975 104 43.3602C103.333 43.9648 102.765 44.6708 102.318 45.4522C101.906 46.2275 101.71 47.0987 101.75 47.9754C101.77 48.7936 101.885 49.6282 101.706 50.4371C101.609 50.8498 101.425 51.2371 101.167 51.5737C100.909 51.9104 100.583 52.1887 100.209 52.3904C99.293 52.9172 98.2419 53.0833 97.2083 53.21C96.0608 53.3506 94.8666 53.4785 93.8732 54.1222C93.7529 54.2002 93.6389 54.0089 93.7591 53.931C95.4874 52.8111 97.6743 53.2186 99.5326 52.4712C100.4 52.1225 101.167 51.5103 101.439 50.5847C101.676 49.7754 101.558 48.9146 101.53 48.0864C101.479 47.2317 101.638 46.3775 101.994 45.5985C102.4 44.7968 102.939 44.0701 103.589 43.4495C105.03 42.0289 106.686 40.8438 108.495 39.9375C110.554 38.8917 112.777 38.2059 115.068 37.9096C115.209 37.8914 115.21 38.1142 115.07 38.1323Z"
                fill="white"
              />
              <path
                d="M104.929 42.3806C104.518 41.8039 104.326 41.0999 104.386 40.3944C104.446 39.6888 104.755 39.0277 105.258 38.5291C105.36 38.4285 105.521 38.5824 105.419 38.6831C104.95 39.1466 104.662 39.7625 104.607 40.4195C104.552 41.0766 104.734 41.7317 105.12 42.2664C105.204 42.3827 105.012 42.4962 104.929 42.3806Z"
                fill="white"
              />
              <path
                d="M101.685 47.5619C103.11 47.8145 104.578 47.5205 105.796 46.7387C105.917 46.6611 106.031 46.8524 105.91 46.9299C104.641 47.7411 103.113 48.0439 101.63 47.7778C101.489 47.7524 101.544 47.5366 101.685 47.5619Z"
                fill="white"
              />
              <path
                d="M110.573 39.1865C110.685 39.4428 110.86 39.6671 111.08 39.8393C111.301 40.0116 111.561 40.1263 111.837 40.1733C111.978 40.1969 111.923 40.4127 111.782 40.3892C111.478 40.3359 111.191 40.2088 110.947 40.0191C110.702 39.8293 110.508 39.5827 110.382 39.3007C110.367 39.2751 110.364 39.2451 110.371 39.2167C110.378 39.1883 110.396 39.1638 110.42 39.148C110.446 39.133 110.476 39.1286 110.504 39.1358C110.533 39.1431 110.558 39.1613 110.573 39.1865Z"
                fill="white"
              />
              <path
                d="M120.253 54.3407C120.164 54.3284 120.076 54.3161 119.986 54.3052C118.791 54.1467 117.585 54.0854 116.38 54.1221C116.287 54.1235 116.193 54.1262 116.1 54.1303C113.198 54.2496 110.344 54.9066 107.683 56.0678C106.624 56.5308 105.602 57.0737 104.626 57.6918C103.277 58.5455 101.883 59.5784 100.376 59.9266C100.219 59.9647 100.06 59.993 99.8999 60.0114L93.6725 54.6193C93.6645 54.5999 93.6552 54.5819 93.6472 54.5625L93.3887 54.3586C93.4368 54.3232 93.4875 54.2875 93.5356 54.2521C93.5634 54.2315 93.5927 54.212 93.6205 54.1914C93.6395 54.178 93.6585 54.1647 93.6749 54.1515C93.6812 54.147 93.6876 54.1426 93.6927 54.1396C93.709 54.1264 93.7257 54.1159 93.7408 54.1042C94.0235 53.904 94.3081 53.7054 94.5946 53.5085C94.5959 53.5071 94.5959 53.5071 94.5985 53.5068C96.7673 51.9855 99.109 50.7267 101.574 49.757C101.648 49.7291 101.723 49.6997 101.8 49.6742C102.911 49.2614 104.053 48.94 105.215 48.7132C105.852 48.5911 106.495 48.5013 107.141 48.4442C108.811 48.2999 110.493 48.419 112.126 48.7974C115.38 49.552 118.357 51.3392 120.121 54.1264C120.166 54.1977 120.209 54.2679 120.253 54.3407Z"
                fill="#E6E6E6"
              />
              <path
                d="M120.17 54.4076C116.536 52.3384 112.342 51.467 108.184 51.9171C107.287 51.9981 106.409 52.2199 105.581 52.5744C104.786 52.9459 104.105 53.5234 103.608 54.2471C103.132 54.9126 102.722 55.6485 102.092 56.1866C101.765 56.4573 101.385 56.6558 100.977 56.7693C100.568 56.8828 100.14 56.9086 99.7207 56.845C98.6718 56.7138 97.7325 56.2136 96.831 55.6925C95.8301 55.1139 94.7996 54.497 93.6189 54.4129C93.4759 54.4027 93.5001 54.1813 93.6429 54.1915C95.697 54.3379 97.1979 55.9799 99.1316 56.502C100.034 56.7456 101.015 56.7188 101.789 56.1433C102.466 55.6401 102.89 54.8816 103.366 54.2036C103.84 53.4905 104.482 52.9043 105.234 52.4962C106.041 52.1006 106.91 51.8453 107.802 51.7411C109.808 51.4745 111.844 51.525 113.834 51.8907C116.108 52.2954 118.295 53.0861 120.302 54.2285C120.427 54.2993 120.293 54.4779 120.17 54.4076Z"
                fill="white"
              />
              <path
                d="M109.515 51.6934C109.534 50.9856 109.804 50.3076 110.277 49.7806C110.75 49.2536 111.395 48.9118 112.096 48.8163C112.238 48.7975 112.274 49.0173 112.132 49.0362C111.479 49.1239 110.878 49.4423 110.439 49.9339C109.999 50.4256 109.75 51.0581 109.736 51.7174C109.733 51.8606 109.511 51.8358 109.515 51.6934Z"
                fill="white"
              />
              <path
                d="M103.807 53.8779C104.793 54.9377 106.142 55.5869 107.585 55.696C107.728 55.7067 107.704 55.9281 107.561 55.9174C106.059 55.801 104.657 55.1226 103.633 54.0175C103.536 53.9121 103.71 53.7731 103.807 53.8779Z"
                fill="white"
              />
              <path
                d="M115.946 52.5416C115.881 52.8139 115.885 53.098 115.958 53.3683C116.03 53.6386 116.169 53.8868 116.361 54.0904C116.459 54.1945 116.285 54.3334 116.187 54.23C115.976 54.004 115.824 53.7298 115.743 53.4313C115.662 53.1328 115.656 52.8191 115.724 52.5176C115.728 52.4887 115.743 52.4623 115.766 52.444C115.789 52.4257 115.818 52.4167 115.847 52.4189C115.876 52.4222 115.903 52.437 115.922 52.46C115.94 52.4829 115.949 52.5123 115.946 52.5416Z"
                fill="white"
              />
              <path
                d="M10.8728 126.442C10.5804 127.538 9.81229 128.452 8.88489 129.117C8.73369 129.226 8.57845 129.327 8.4212 129.421C8.37281 129.45 8.32442 129.48 8.27402 129.506C8.21959 129.538 8.16515 129.569 8.11072 129.597H3.74993C3.6713 129.437 3.59469 129.276 3.51808 129.117C1.64917 125.19 0.336701 120.952 0.560486 116.635C0.64927 114.961 1.00101 113.311 1.6028 111.746C2.80035 108.627 4.98579 105.928 7.99177 104.569C8.06637 104.535 8.14499 104.501 8.2216 104.468C8.19741 104.555 8.17322 104.64 8.14902 104.726C7.82653 105.888 7.59864 107.074 7.46759 108.273C7.45549 108.365 7.44541 108.458 7.43735 108.553C7.15093 111.443 7.40526 114.362 8.18733 117.16C8.18935 117.166 8.19135 117.174 8.19338 117.18C8.36475 117.799 8.56367 118.408 8.79014 119.008C8.96755 119.482 9.15908 119.95 9.36876 120.409C10.2437 122.339 11.4171 124.395 10.8728 126.442Z"
                fill="#E6E6E6"
              />
              <path
                d="M8.27497 104.559C8.23263 104.615 8.19028 104.67 8.14796 104.726C7.26246 105.9 6.51052 107.168 5.90607 108.509C5.87381 108.575 5.84357 108.642 5.81736 108.71C4.98915 110.581 4.45726 112.57 4.24078 114.605C4.23844 114.619 4.2371 114.633 4.23675 114.648C4.18635 115.123 4.15611 115.603 4.14401 116.083C4.09918 116.982 4.19648 117.883 4.43231 118.752C4.64735 119.462 5.02416 120.112 5.53309 120.651C5.5835 120.706 5.6339 120.76 5.68833 120.813C5.72866 120.855 5.77301 120.897 5.81535 120.938C6.22259 121.327 6.66815 121.688 7.05121 122.105C7.22678 122.292 7.38533 122.495 7.52499 122.71C7.74787 123.071 7.89184 123.475 7.94744 123.895C8.00304 124.315 7.969 124.743 7.84756 125.149C7.57337 126.169 6.94637 127.03 6.30526 127.851C5.9847 128.262 5.65406 128.677 5.36777 129.117C5.26295 129.274 5.16617 129.433 5.07747 129.597H4.82344C4.90812 129.433 5.00086 129.274 5.10167 129.117C5.83754 127.958 6.88388 126.962 7.42621 125.686C7.79314 124.825 7.90402 123.849 7.44032 123.004C7.30442 122.759 7.13998 122.53 6.95041 122.323C6.58147 121.905 6.13995 121.549 5.73875 121.172C5.63794 121.077 5.53915 120.98 5.4444 120.879C4.94203 120.369 4.55437 119.757 4.30733 119.085C4.02755 118.231 3.89529 117.336 3.91621 116.438C3.91621 115.865 3.94645 115.289 4.0029 114.716C4.00895 114.643 4.01701 114.569 4.02508 114.494C4.43737 110.891 5.80664 107.464 7.99071 104.569C8.03104 104.513 8.07337 104.458 8.11571 104.404C8.20239 104.291 8.36166 104.448 8.27497 104.559Z"
                fill="white"
              />
              <path
                d="M4.10743 114.735C3.40913 114.618 2.77524 114.256 2.31899 113.715C1.86274 113.173 1.61384 112.487 1.61672 111.779C1.61711 111.75 1.62869 111.722 1.64902 111.702C1.66935 111.681 1.69685 111.67 1.72572 111.669C1.75459 111.668 1.78257 111.679 1.80378 111.699C1.82499 111.718 1.83776 111.745 1.83941 111.774C1.83551 112.433 2.06743 113.073 2.49332 113.576C2.9192 114.079 3.51101 114.414 4.16191 114.519C4.30333 114.542 4.24805 114.758 4.10743 114.735Z"
                fill="white"
              />
              <path
                d="M5.47805 120.692C6.66444 119.863 7.49468 118.617 7.80323 117.202C7.83369 117.062 8.04957 117.117 8.01915 117.257C7.69533 118.728 6.8287 120.023 5.59221 120.883C5.4743 120.965 5.36078 120.773 5.47805 120.692Z"
                fill="white"
              />
              <path
                d="M5.84049 108.485C6.10123 108.587 6.38313 108.622 6.6609 108.588C6.93866 108.554 7.20359 108.451 7.43188 108.289C7.54869 108.206 7.66208 108.397 7.54603 108.48C7.29297 108.658 7.00025 108.771 6.69345 108.809C6.38665 108.848 6.07509 108.811 5.78601 108.701C5.7579 108.693 5.7339 108.674 5.71891 108.649C5.70392 108.624 5.69905 108.594 5.70529 108.566C5.71265 108.537 5.73098 108.513 5.75629 108.497C5.78161 108.482 5.81187 108.478 5.84049 108.485Z"
                fill="white"
              />
              <path
                d="M23.4009 112.355C23.3303 112.41 23.2577 112.464 23.1872 112.521C22.23 113.253 21.3339 114.062 20.5078 114.94C20.4433 115.006 20.3788 115.075 20.3163 115.143L20.3142 115.145C18.3554 117.272 16.8066 119.742 15.7458 122.432L15.7398 122.45C15.7377 122.456 15.7357 122.46 15.7337 122.466C15.3132 123.542 14.9743 124.649 14.7196 125.776C14.4737 126.877 14.272 128.056 13.887 129.117C13.8285 129.28 13.764 129.441 13.6954 129.597H4.73193C4.76217 129.437 4.79443 129.276 4.82669 129.117C5.28266 126.781 5.9953 124.502 6.95164 122.323C6.9839 122.25 7.01616 122.178 7.05244 122.105C7.54615 121.028 8.12668 119.993 8.78829 119.01L8.78844 119.01L8.78887 119.009L8.78953 119.008L8.79031 119.008C9.15361 118.472 9.54389 117.955 9.95964 117.458C11.0379 116.175 12.3115 115.07 13.7337 114.184C13.7418 114.178 13.7519 114.174 13.76 114.168C16.5905 112.412 19.9453 111.575 23.1529 112.299H23.1549C23.2376 112.317 23.3182 112.335 23.4009 112.355Z"
                fill="#E6E6E6"
              />
              <path
                d="M23.3897 112.462C23.3212 112.48 23.2526 112.5 23.1861 112.521C21.7732 112.924 20.4093 113.483 19.1197 114.188C19.0552 114.222 18.9906 114.258 18.9261 114.295C17.1438 115.289 15.526 116.554 14.1299 118.043C14.1288 118.044 14.1281 118.045 14.1278 118.047C14.1117 118.063 14.0936 118.081 14.0795 118.097C13.7569 118.444 13.4464 118.805 13.1521 119.176C12.5763 119.867 12.1123 120.645 11.7771 121.48C11.5209 122.176 11.4305 122.922 11.513 123.659C11.519 123.734 11.5291 123.807 11.5392 123.881C11.5473 123.94 11.5553 123.998 11.5654 124.059C11.6985 124.867 11.9283 125.678 11.8638 126.502C11.8245 126.925 11.6963 127.334 11.4874 127.703C11.2786 128.073 10.9939 128.394 10.6521 128.645C10.4198 128.825 10.1714 128.983 9.91019 129.117C9.5367 129.309 9.14754 129.47 8.74691 129.597H7.95459C8.06144 129.567 8.16628 129.536 8.27313 129.506C8.65231 129.399 9.02462 129.269 9.38803 129.117C9.59563 129.03 9.79762 128.93 9.99285 128.819C10.8033 128.353 11.4787 127.639 11.6198 126.686C11.7428 125.851 11.5049 125.016 11.3638 124.2C11.3396 124.059 11.3174 123.919 11.3053 123.78C11.2091 123.073 11.2675 122.354 11.4767 121.672C11.7668 120.821 12.2004 120.025 12.7589 119.321C13.1044 118.862 13.4731 118.421 13.8637 118C13.9121 117.946 13.9625 117.891 14.0149 117.837C15.4404 116.337 17.0863 115.064 18.8959 114.061H18.8979C20.2434 113.312 21.6708 112.721 23.1518 112.299H23.1538C23.2204 112.279 23.2889 112.258 23.3555 112.24C23.4946 112.204 23.5268 112.424 23.3897 112.462Z"
                fill="white"
              />
              <path
                d="M13.9361 118.077C13.4491 117.563 13.1608 116.892 13.1225 116.185C13.0843 115.478 13.2986 114.78 13.7272 114.217C13.8143 114.103 13.9952 114.233 13.9079 114.347C13.5078 114.871 13.3083 115.521 13.3452 116.179C13.3822 116.838 13.6533 117.461 14.1096 117.937C14.2086 118.041 14.0346 118.18 13.9361 118.077Z"
                fill="white"
              />
              <path
                d="M11.4443 123.658C12.8908 123.71 14.3039 123.215 15.4017 122.272C15.5104 122.178 15.6499 122.352 15.5413 122.445C14.3971 123.425 12.9255 123.937 11.4203 123.88C11.2769 123.874 11.3016 123.653 11.4443 123.658Z"
                fill="white"
              />
              <path
                d="M19.0834 114.13C19.2303 114.368 19.4342 114.566 19.6765 114.706C19.9189 114.846 20.1922 114.923 20.472 114.931C20.6154 114.935 20.5905 115.156 20.448 115.153C20.139 115.142 19.8372 115.056 19.5691 114.902C19.301 114.748 19.0746 114.531 18.9099 114.269C18.8923 114.246 18.8842 114.217 18.8874 114.188C18.8905 114.159 18.9046 114.132 18.9268 114.113C18.9499 114.094 18.9793 114.086 19.0086 114.089C19.0379 114.092 19.0648 114.107 19.0834 114.13Z"
                fill="white"
              />
              <path
                d="M92.5461 34.9102C92.3391 35.1823 92.0751 35.4058 91.7725 35.5649C91.4698 35.724 91.136 35.8148 90.7945 35.8309C90.453 35.847 90.1121 35.788 89.7959 35.6581C89.4796 35.5282 89.1957 35.3305 88.9641 35.079L81.3433 38.3935L82.0856 34.1393L89.2087 31.644C89.657 31.2829 90.2221 31.0989 90.797 31.1266C91.372 31.1544 91.9167 31.3921 92.3281 31.7947C92.7395 32.1973 92.9889 32.7369 93.0291 33.311C93.0692 33.8852 92.8974 34.4542 92.5461 34.9102Z"
                fill="#FFB8B8"
              />
              <path
                d="M87.4611 36.2384L69.7604 44.123L69.7224 44.1094L49.5481 36.9203C48.0454 36.1349 46.8997 34.8048 46.3457 33.2023C45.7917 31.5998 45.8712 29.8462 46.568 28.3004C46.9542 27.4463 47.5169 26.6837 48.2191 26.0629C48.9213 25.442 49.7471 24.9768 50.642 24.6981C51.5369 24.4193 52.4807 24.3332 53.4113 24.4455C54.3418 24.5578 55.2381 24.8659 56.041 25.3495L71.0545 34.3935L85.6129 32.5067L87.4611 36.2384Z"
                fill="#E6E6E6"
              />
              <path
                d="M174.464 129.76C174.465 129.792 174.458 129.823 174.446 129.852C174.434 129.881 174.417 129.908 174.394 129.93C174.372 129.952 174.346 129.97 174.316 129.982C174.287 129.994 174.256 130 174.225 130H0.78288C0.719251 130 0.65823 129.975 0.613238 129.93C0.568245 129.885 0.542969 129.824 0.542969 129.76C0.542969 129.696 0.568245 129.635 0.613238 129.59C0.65823 129.545 0.719251 129.52 0.78288 129.52H174.225C174.256 129.52 174.287 129.526 174.316 129.538C174.346 129.55 174.372 129.568 174.394 129.59C174.417 129.613 174.434 129.639 174.446 129.668C174.458 129.697 174.465 129.729 174.464 129.76Z"
                fill="#CCCCCC"
              />
              <path
                d="M63.709 59.8423L45.8655 56.9882C45.3975 56.8383 44.9675 56.5889 44.605 56.2572C44.2425 55.9254 43.9561 55.5192 43.7654 55.0663C43.5747 54.6134 43.4843 54.1246 43.5004 53.6334C43.5164 53.1423 43.6385 52.6605 43.8584 52.221L44.6676 50.6024L44.3533 33.5974C44.3154 31.3855 44.8607 29.2025 45.9343 27.2683C47.008 25.334 48.5721 23.7166 50.4693 22.5788L52.2094 21.5383L53.0888 19.3052L61.1975 19.503L61.2211 22.4318L64.125 27.0624L64.1249 27.0816L63.9427 52.2854L63.1424 54.8864L64.3525 57.9117L63.709 59.8423Z"
                fill="#E6E6E6"
              />
              <path
                d="M57.8842 16.5284C61.9532 16.5284 65.2518 13.2298 65.2518 9.16081C65.2518 5.0918 61.9532 1.79321 57.8842 1.79321C53.8152 1.79321 50.5166 5.0918 50.5166 9.16081C50.5166 13.2298 53.8152 16.5284 57.8842 16.5284Z"
                fill="#FFB8B8"
              />
              <path
                d="M50.2123 10.7669C49.9925 9.84112 50.0934 8.5384 50.2176 7.60626C50.5432 5.16251 51.9003 2.83929 53.9621 1.46316C54.3531 1.16623 54.8176 0.98161 55.3058 0.92921C55.7935 0.908848 56.3223 1.16997 56.472 1.63464C56.5949 1.32795 56.7793 1.04971 57.0139 0.81707C57.2485 0.584434 57.5283 0.402346 57.836 0.282046C58.4541 0.0452278 59.1189 -0.0443312 59.7776 0.020468C61.0545 0.125716 62.279 0.574682 63.3213 1.31972C64.3635 2.06476 65.1846 3.07807 65.6975 4.25212C65.8942 4.71961 66.4439 2.99283 66.6932 3.43454C66.9224 3.89368 67.3221 4.24489 67.8069 4.41303C68.2955 4.54936 68.4939 6.56225 68.6862 6.09288C68.8353 6.43975 68.8943 6.81874 68.8576 7.19454C68.8208 7.57034 68.6896 7.93073 68.4761 8.24215C68.2625 8.55357 67.9737 8.80589 67.6364 8.97562C67.2991 9.14534 66.9243 9.22694 66.547 9.21282C66.0497 9.19421 65.5742 9.01132 65.0829 8.93258C63.3259 8.65105 61.462 9.92513 61.0864 11.6645C60.9519 11.1615 60.7371 10.6835 60.4502 10.249C60.3046 10.0329 60.1094 9.85468 59.881 9.72919C59.6526 9.6037 59.3976 9.53456 59.1371 9.52751C58.6372 9.54622 58.1978 9.87305 57.8734 10.2539C57.5491 10.6347 57.3054 11.0791 56.9787 11.4579C56.02 12.5693 54.7232 15.1614 53.3569 14.9723C52.2787 14.8231 50.5123 12.0309 50.2123 10.7669Z"
                fill="#2F2E41"
              />
              <path
                d="M102.421 43.2589C102.473 42.923 102.404 42.5797 102.226 42.2899C102.049 42.0001 101.775 41.7824 101.452 41.6756L81.6111 35.0674C81.4209 35.0038 81.2194 34.9807 81.0198 34.9995L76.0467 35.4698L73.1932 35.7405L67.9274 36.2381C67.6095 36.2685 67.3107 36.4036 67.0778 36.6221C66.8448 36.8407 66.691 37.1303 66.6405 37.4457L63.9669 54.1465C63.9163 54.4722 63.9783 54.8055 64.1427 55.0912C64.307 55.377 64.5639 55.5981 64.871 55.7182L84.919 63.4568C85.0131 63.4933 85.1108 63.5196 85.2106 63.5351C85.3558 63.5586 85.5037 63.5597 85.6492 63.5384L98.6037 61.6184C98.9092 61.5742 99.1924 61.4326 99.4111 61.2147C99.6298 60.9969 99.7725 60.7143 99.8179 60.4089L102.421 43.2589Z"
                fill="white"
              />
              <path
                d="M65.325 54.5414L85.373 62.2796C85.4022 62.2912 85.434 62.2949 85.4651 62.2904L98.4179 60.3706C98.4563 60.3654 98.4919 60.3477 98.5194 60.3204C98.5468 60.2931 98.5646 60.2575 98.5701 60.2191L101.175 43.0689C101.181 43.0271 101.172 42.9844 101.15 42.9483C101.128 42.9122 101.094 42.8849 101.054 42.8711L100.921 42.8259L81.2118 36.2644C81.2028 36.2612 81.1935 36.259 81.1841 36.2577C81.1688 36.2549 81.1532 36.2541 81.1377 36.2553L69.0081 37.4031L68.0456 37.4934C68.0061 37.4976 67.9691 37.5146 67.9401 37.5419C67.9112 37.5691 67.892 37.605 67.8854 37.6442L65.2122 54.3443C65.2057 54.3851 65.2133 54.4269 65.2339 54.4627C65.2544 54.4986 65.2866 54.5264 65.325 54.5414Z"
                fill="#6C63FF"
              />
              <path
                d="M69.0093 37.4031L81.1389 36.2553C81.1544 36.2541 81.17 36.2549 81.1853 36.2577C81.1947 36.259 81.204 36.2612 81.213 36.2644L100.922 42.8259L95.7229 43.3762L93.7773 43.5812L88.2411 44.1661C88.2249 44.1669 88.2087 44.1667 88.1925 44.1655C88.1827 44.1626 88.1728 44.1596 88.161 44.1564L69.0093 37.4031Z"
                fill="#2F2E41"
              />
              <path
                d="M64.8717 55.7192L84.9197 63.4574C85.014 63.4937 85.1121 63.5194 85.2121 63.534C85.3573 63.558 85.5053 63.5598 85.6511 63.5394L98.6038 61.6197C98.9095 61.5752 99.1927 61.4334 99.4116 61.2154C99.6305 60.9974 99.7734 60.7148 99.8191 60.4093L102.422 43.2589C102.46 43.006 102.431 42.7475 102.338 42.5096C102.324 42.4779 102.311 42.4463 102.296 42.4165C102.193 42.1992 102.035 42.012 101.839 41.8726C101.721 41.7879 101.591 41.7215 101.453 41.6757L101.43 41.6674L81.6132 35.0672C81.5323 35.0414 81.4496 35.0213 81.3658 35.0072C81.2513 34.9909 81.1352 34.9887 81.0201 35.0005L76.0479 35.4691L73.1946 35.7413L67.928 36.2386C67.7125 36.2587 67.5044 36.3274 67.3194 36.4397C67.3051 36.4466 67.2914 36.4548 67.2786 36.4641C67.2694 36.4701 67.2607 36.4767 67.2524 36.4839C67.0908 36.5942 66.9534 36.7362 66.8485 36.9013C66.7436 37.0664 66.6734 37.2512 66.6422 37.4443L63.9668 54.1462C63.9163 54.4722 63.9784 54.8057 64.1429 55.0917C64.3074 55.3777 64.5645 55.599 64.8717 55.7192ZM66.9971 37.5025C67.0296 37.3023 67.1184 37.1155 67.2531 36.9639C67.2939 36.9152 67.3395 36.8707 67.3893 36.8311C67.5253 36.7212 67.6862 36.6461 67.8578 36.6126C67.8917 36.6056 67.926 36.6008 67.9605 36.5981L76.8281 35.7604L81.0548 35.3582C81.0835 35.3548 81.1098 35.3553 81.1362 35.3538C81.2002 35.3525 81.2642 35.3572 81.3273 35.368C81.3853 35.3755 81.4424 35.3891 81.4976 35.4086L101.338 42.0171C101.353 42.0226 101.367 42.028 101.383 42.0335C101.563 42.1006 101.721 42.216 101.841 42.3669C101.935 42.4825 102.004 42.6167 102.043 42.7607C102.082 42.9047 102.09 43.0553 102.067 43.2027L99.4618 60.3548C99.4277 60.5836 99.3209 60.7953 99.1572 60.9587C98.9935 61.1221 98.7817 61.2286 98.5529 61.2624L85.5981 63.182C85.4128 63.2085 85.2238 63.187 85.0492 63.1194L65.001 55.3832C64.7712 55.2927 64.579 55.1267 64.456 54.9125C64.3329 54.6984 64.2863 54.4487 64.3238 54.2046L66.9971 37.5025Z"
                fill="#3F3D56"
              />
              <path
                d="M67.2089 36.5749C67.2195 36.5446 67.2382 36.5176 67.2629 36.4971C67.2711 36.4905 67.2798 36.4846 67.2889 36.4793C67.2973 36.4761 67.3059 36.4708 67.3143 36.4675C67.3546 36.4514 67.3994 36.4506 67.4401 36.4654L67.8574 36.6126L88.2432 43.8039L93.0143 43.2998L95.6842 43.0182L101.335 42.4199L101.84 42.3669L101.939 42.3561C101.981 42.3522 102.023 42.3632 102.058 42.3873C102.093 42.4114 102.119 42.4469 102.131 42.4878C102.133 42.4972 102.135 42.5069 102.136 42.5167C102.14 42.5555 102.132 42.5946 102.112 42.6281C102.092 42.6617 102.061 42.6878 102.025 42.7025C102.009 42.7092 101.992 42.7137 101.975 42.716L101.288 42.788L95.7229 43.3763L93.7772 43.5813L88.241 44.1662C88.2248 44.167 88.2086 44.1668 88.1924 44.1656C88.1826 44.1626 88.1728 44.1596 88.1609 44.1565L67.389 36.8311L67.3184 36.8062C67.3023 36.8009 67.2873 36.7926 67.2742 36.7817C67.2428 36.7586 67.2195 36.7262 67.2077 36.689C67.196 36.6518 67.1964 36.6119 67.2089 36.5749Z"
                fill="#3F3D56"
              />
              <path
                d="M85.265 63.5864C85.2181 63.5789 85.176 63.5532 85.148 63.5148C85.12 63.4765 85.1082 63.4286 85.1153 63.3816L88.042 43.9598C88.0492 43.9126 88.0748 43.8701 88.1132 43.8417C88.1517 43.8134 88.1998 43.8014 88.2471 43.8085L88.2487 43.8088C88.2956 43.8163 88.3376 43.842 88.3657 43.8804C88.3937 43.9187 88.4054 43.9666 88.3984 44.0136L85.4716 63.4353C85.4645 63.4826 85.4389 63.5251 85.4005 63.5534C85.362 63.5818 85.3139 63.5938 85.2666 63.5867L85.265 63.5864Z"
                fill="#3F3D56"
              />
              <path
                d="M92.6838 62.3544L90.7373 62.5598L93.0141 43.2992C93.0141 43.2992 95.6138 42.8286 95.6833 43.0173C95.7261 43.13 92.7023 62.2309 92.6838 62.3544Z"
                fill="#3F3D56"
              />
              <path
                d="M67.319 36.8059L67.2666 45.5894L82.8159 51.5142L88.3998 44.0136L67.319 36.8059Z"
                fill="#3F3D56"
              />
              <path
                d="M101.84 42.3672C101.71 42.2039 101.535 42.0822 101.337 42.0171L81.4974 35.4089C81.4417 35.3901 81.3845 35.3762 81.3264 35.3672C81.2631 35.3564 81.199 35.3516 81.1348 35.3529L81.1669 35.1518L81.3656 35.008L86.0768 31.597L101.81 36.4189L101.838 41.8727L101.84 42.3672Z"
                fill="#3F3D56"
              />
              <path
                d="M68.1107 50.9063C68.1331 50.9085 68.1551 50.9131 68.1764 50.9201L74.2637 52.9194C74.34 52.9444 74.4031 52.9987 74.4393 53.0703C74.4755 53.1419 74.4818 53.2249 74.4568 53.3011C74.4318 53.3773 74.3775 53.4405 74.3059 53.4767C74.2344 53.5129 74.1513 53.5192 74.0751 53.4942L67.9878 51.4948C67.9172 51.4716 67.8575 51.4232 67.8203 51.3587C67.7831 51.2943 67.771 51.2185 67.7862 51.1456C67.8014 51.0728 67.8429 51.0082 67.9028 50.964C67.9626 50.9199 68.0367 50.8993 68.1107 50.9063Z"
                fill="white"
              />
              <path
                d="M92.6708 87.7529C94.0069 87.7529 95.0901 86.6697 95.0901 85.3336C95.0901 83.9975 94.0069 82.9143 92.6708 82.9143C91.3346 82.9143 90.2515 83.9975 90.2515 85.3336C90.2515 86.6697 91.3346 87.7529 92.6708 87.7529Z"
                fill="#F2F2F2"
              />
              <path
                d="M30.9794 96.6237C32.3155 96.6237 33.3987 95.5405 33.3987 94.2044C33.3987 92.8683 32.3155 91.7851 30.9794 91.7851C29.6432 91.7851 28.5601 92.8683 28.5601 94.2044C28.5601 95.5405 29.6432 96.6237 30.9794 96.6237Z"
                fill="#F2F2F2"
              />
              <path
                d="M81.3812 16.5852C82.7174 16.5852 83.8005 15.502 83.8005 14.1659C83.8005 12.8297 82.7174 11.7466 81.3812 11.7466C80.0451 11.7466 78.9619 12.8297 78.9619 14.1659C78.9619 15.502 80.0451 16.5852 81.3812 16.5852Z"
                fill="#F2F2F2"
              />
              <path
                d="M85.3504 54.4645C85.0368 54.6007 84.6972 54.6665 84.3554 54.6573C84.0137 54.6481 83.6781 54.564 83.3723 54.4111C83.0666 54.2581 82.7981 54.04 82.5858 53.7721C82.3734 53.5041 82.2224 53.1929 82.1434 52.8603L73.875 52.0249L76.6028 48.6769L84.0364 49.9835C84.6039 49.8878 85.1869 50.0035 85.6748 50.3089C86.1628 50.6143 86.5217 51.088 86.6837 51.6403C86.8456 52.1927 86.7994 52.7852 86.5536 53.3058C86.3079 53.8263 85.8798 54.2385 85.3504 54.4645Z"
                fill="#FFB8B8"
              />
              <path
                d="M80.2648 53.1364L60.9692 51.3585L60.9427 51.3281L46.8603 35.1921C45.9335 33.7722 45.5845 32.0518 45.8849 30.383C46.1852 28.7142 47.1121 27.2234 48.4758 26.2158C49.2303 25.6597 50.0941 25.2696 51.0102 25.0714C51.9263 24.8732 52.874 24.8712 53.791 25.0657C54.7079 25.2601 55.5733 25.6465 56.3301 26.1995C57.0869 26.7525 57.718 27.4595 58.1819 28.274L66.8556 43.5044L80.4774 48.9774L80.2648 53.1364Z"
                fill="#E6E6E6"
              />
              <path
                d="M128.697 22.1054C128.218 18.5906 127.617 14.8169 125.138 12.2796C124.351 11.4746 123.409 10.8371 122.37 10.4057C121.33 9.97435 120.214 9.75796 119.088 9.76961C117.963 9.78126 116.851 10.0207 115.821 10.4735C114.791 10.9263 113.862 11.5831 113.093 12.4043C111.288 14.3294 110.491 17.0326 110.418 19.6702C110.345 22.3079 110.933 24.9163 111.584 27.4732C115.033 27.5421 118.456 26.8683 121.622 25.4976C122.413 25.154 123.192 24.7656 124.028 24.5527C124.864 24.3399 125.461 24.8755 126.238 25.249L126.665 24.4314C127.015 25.0819 128.097 24.889 128.49 24.2636C128.883 23.6381 128.797 22.8371 128.697 22.1054Z"
                fill="#2F2E41"
              />
              <path
                d="M115.025 41.7236L100.938 42.0249C100.721 41.6509 100.401 41.3479 100.016 41.1526C99.6302 40.9574 99.1963 40.8784 98.7668 40.9253C98.3373 40.9721 97.9307 41.1428 97.5965 41.4165C97.2622 41.6902 97.0147 42.0552 96.8841 42.467C96.7535 42.8788 96.7454 43.3197 96.8607 43.7361C96.9761 44.1524 97.21 44.5262 97.534 44.812C97.858 45.0979 98.258 45.2834 98.6855 45.346C99.113 45.4086 99.5494 45.3456 99.9418 45.1647C101.81 45.2709 116.428 47.3455 117.3 46.0085C118.185 44.6515 122.909 39.8623 122.909 39.8623L119.278 34.6923L115.025 41.7236Z"
                fill="#9E616A"
              />
              <path
                d="M118.458 25.5829C122.114 25.5829 125.077 22.6195 125.077 18.964C125.077 15.3086 122.114 12.3452 118.458 12.3452C114.803 12.3452 111.839 15.3086 111.839 18.964C111.839 22.6195 114.803 25.5829 118.458 25.5829Z"
                fill="#9E616A"
              />
              <path
                d="M129.059 31.6993C128.279 30.147 127.9 28.2292 126.237 27.726C125.114 27.3861 120.573 27.8954 119.549 28.4691C117.847 29.4233 119.39 31.2114 118.385 32.8847C117.294 34.7038 114.327 38.3543 113.235 40.1733C112.484 41.4239 115.012 45.0945 114.607 46.4954C114.201 47.8962 114.181 49.4686 114.873 50.7528C115.491 51.9022 114.596 52.9191 115.218 54.0666C115.866 55.2601 116.66 59.7223 116.057 60.939L115.654 62.1487C119.655 62.3838 123.02 57.6036 126.996 57.1C127.984 56.975 129.009 56.8116 129.823 56.2383C131.022 55.3938 131.496 53.8615 131.792 52.4253C133.218 45.4051 132.256 38.1099 129.059 31.6993Z"
                fill="#3F3D56"
              />
              <path
                d="M135.059 48.0904C134.501 46.5691 133.492 39.9178 133.492 39.9178L127.177 39.7327L130.265 47.3478L121.839 59.1425C121.839 59.1425 121.855 59.1684 121.883 59.2146C121.44 59.1435 120.986 59.2078 120.581 59.3991C120.175 59.5903 119.836 59.8997 119.609 60.2865C119.382 60.6734 119.277 61.1199 119.307 61.5675C119.338 62.015 119.503 62.4429 119.781 62.7951C120.059 63.1472 120.437 63.4073 120.865 63.5412C121.293 63.6751 121.752 63.6767 122.181 63.5457C122.61 63.4146 122.99 63.1571 123.27 62.8069C123.55 62.4566 123.718 62.0298 123.752 61.5825C123.783 61.6003 123.82 61.6067 123.855 61.6006C123.89 61.5946 123.922 61.5764 123.945 61.5493C124.883 60.3729 135.616 49.6116 135.059 48.0904Z"
                fill="#9E616A"
              />
              <path
                d="M135.701 43.8899C135.121 41.4434 134.537 38.9837 133.56 36.6672C132.583 34.3506 131.184 32.1626 129.216 30.5976C128.587 30.0973 127.884 29.6557 127.095 29.5001C126.306 29.3445 125.418 29.5186 124.864 30.1012C123.978 31.0333 124.244 32.5296 124.569 33.774C125.528 37.4434 126.486 41.1128 127.445 44.7821C130.213 44.4887 132.981 44.1953 135.749 43.9019L135.701 43.8899Z"
                fill="#3F3D56"
              />
              <path
                d="M126.57 19.882C126.326 18.2808 126.071 16.6663 125.499 15.1586C124.926 13.651 124.003 12.2388 122.679 11.3848C120.583 10.0325 117.868 10.2933 115.518 11.0712C113.7 11.6727 111.926 12.587 110.644 14.0568C109.362 15.5267 108.642 17.6241 109.119 19.546C111.626 18.9536 114.133 18.3612 116.64 17.7688L116.366 17.9628C117.189 18.3733 117.908 18.9659 118.468 19.6958C119.028 20.4256 119.415 21.2734 119.598 22.1749C119.777 23.0873 119.751 24.0282 119.523 24.9294C119.294 25.8307 118.867 26.6698 118.274 27.386C119.984 26.7794 121.694 26.1728 123.404 25.5663C124.46 25.1918 125.589 24.7604 126.215 23.8001C126.941 22.6866 126.772 21.2108 126.57 19.882Z"
                fill="#2F2E41"
              />
            </svg>
          </div>
          <div style={{ fontSize: "26px", fontWeight: "600" }}>
            No Data Found
          </div>

          <div>there is no information available yet</div>

          {/* 
                    <div style={{ marginTop: "-10px" }}>
                        <a href="tel://+917778889990">
                            <BgThemeButton children={"(+91) 80918 05354"}></BgThemeButton>
                        </a>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default NoDataFound;
