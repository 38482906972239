import React from "react";
import Settings from "../Components/Settings/Settings";

const SettingsPage = () => {
    return (
        <>
            <Settings />
        </>
    );
};

export default SettingsPage;
