import { Button, ChoiceList, Icon, Page, TextField } from "@shopify/polaris";
import React, { useCallback, useState, useEffect } from "react";
import {
  ApprovedButton,
  BgThemeButton,
  BillGenerateButton,
  CPButton,
  CancelledButton,
  PaidButton,
  PaymentStatusButton,
  PendingButton,
  SyncButton,
  UnpaidButton,
  UpdateButton,
  ViewVoucherButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import { SearchMinor } from "@shopify/polaris-icons";
import Loader from "./UI/Loader/Loader";
import SearchInput from "./CustomComponents/SearchInput";
import { Link } from "react-router-dom";
import BookingSearchInput from "./CustomComponents/BookingSearchInput";
import { getDecryptedData } from "../assets/encryptStorage";

const Booking = () => {
  const data = {
    status: "success",
    Bookings: [
      {
        id: "99eccf55-3c64-470a-8bca-373809095547",
        property_id: "99eafc59-0b26-49fa-9fc5-c0bf4a02cb01",
        unique_booking_id: 497229,
        booked_by: "bba11194-3762-11ee-bd80-acde48001122",
        booked_from: "bba11194-3762-11ee-bd80-acde48001122",
        ota_booking_id: null,
        ota_unique_id: null,
        ota_name: "",
        check_in: "2023-08-09",
        check_out: "2023-08-10",
        check_in_time: "14:00:00",
        check_out_time: "12:00:00",
        taxable_amount: "0.00",
        grand_total: "3000.00",
        paid_amount: "3000.00",
        balance_amount: "0.00",
        max_hold_time: null,
        no_nights: 1,
        tax_applied: 0,
        tax_amount: 0,
        tac_applied: 0,
        tac_amount: 0,
        gst_type: "inclusive",
        status: "confirmed",
        bill_no: null,
        generated_by: null,
        created_at: "2023-08-19T05:41:38.000000Z",
        updated_at: "2023-08-19T05:41:38.000000Z",
        items: [
          {
            id: "99eccf55-40d0-499f-82ee-bc91bbe9f4a5",
            booking_id: "99eccf55-3c64-470a-8bca-373809095547",
            property_id: "99eafc59-0b26-49fa-9fc5-c0bf4a02cb01",
            room_id: "99eb1300-9150-47f0-b938-4a4e55302ba5",
            rate_plan_id: "99eb27ca-9d0b-4abd-bcfd-90da44d1846c",
            room_type_name: "New",
            no_of_rooms: 2,
            no_nights: 1,
            room_rate: "4000.00",
            check_in: "2023-08-09",
            check_out: "2023-08-10",
            check_in_time: null,
            check_out_time: null,
            room_plan: null,
            adult: 2,
            child: 2,
            infant: 0,
            lunch: "200.00",
            breakfast: "100.00",
            dinner: "100.00",
            bar_price: "4400.00",
            room_plan_total: 10800,
            ota_room_id: null,
            ota_room_rate: "0.00",
            ota_room_plan: null,
            status: "confirmed",
            created_at: "2023-08-19T05:41:38.000000Z",
            updated_at: "2023-08-19T05:41:38.000000Z",
          },
        ],
        guest: [
          {
            id: "",
            property_id: "99eafc59-0b26-49fa-9fc5-c0bf4a02cb01",
            booking_id: "99eccf55-3c64-470a-8bca-373809095547",
            ota_booking_id: null,
            guest_name: "Test",
            guest_email: "test@test.com",
            guest_phone: "78076078676",
            guest_city: "Dharamshala",
            guest_state: "Himachal",
            guest_address: "Dharamshala 176215 kangra HP",
            guest_pincode: 176215,
            created_at: null,
            updated_at: null,
          },
        ],
      },
    ],
  };
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const [openSyncPopup, setOpenSyncPopup] = useState(false);
  const handleOpenSyncPopup = () => {
    setOpenSyncPopup(!openSyncPopup);
  };
  const [selected, setSelected] = useState(["hidden"]);
  const [bookings, setBookings] = useState([]);
  const [loader, setLoader] = useState(true);

  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${process.env.REACT_APP_PROPERTY_ID}`;

  const fetchInfo = async () => {
    try {
      setLoader(true);
      const response = await await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      // console.log(data)
      setBookings(data.Bookings);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  console.log(url);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  console.log(bookings);

  const handleChange = useCallback((value) => setSelected(value), []);

  console.log(data);
  return (
    <div>
      <div className="room-types-full-container height-100">
        <div style={{ display: "flex", gap: "15px" }}>
          <div className="booking-choice-full-parent-container-left">
            <div className="booking-choice-full-parent-container-left-heading">
              Bookings
            </div>
            <div className="booking-choice-full-container">
              <div className="booking-choice-full-container-divs">
                <ChoiceList
                  title="Booking From"
                  choices={[
                    { label: "Website", value: "hidden" },
                    { label: "Tripadvisor", value: "Tripadvisor" },
                    { label: "Makemytrip", value: "Makemytrip" },
                    { label: "Goibibo", value: "Goibibo" },
                    { label: "Agoda", value: "Agoda" },
                    { label: "OYO", value: "OYO" },
                  ]}
                  selected={selected}
                  onChange={handleChange}
                />
              </div>
              <div className="booking-choice-full-container-divs">
                <ChoiceList
                  title="Date Filters"
                  choices={[
                    { label: "Check-In", value: "Check-In" },
                    { label: "Check-Out", value: "Check-Out" },
                    { label: "Booking Dates", value: "Booking Dates" },
                  ]}
                  selected={selected}
                  onChange={handleChange}
                />
              </div>

              <div className="booking-choice-full-container-divs">
                <ChoiceList
                  title="Booking Status"
                  choices={[
                    { label: "Acknowledged", value: "Acknowledged" },
                    { label: "Cancelled", value: "Cancelled" },
                    { label: "Pending", value: "Pending" },
                    { label: "Modified", value: "Modified" },
                    { label: "Check-In-Denied", value: "Check-In-Denied" },
                  ]}
                  selected={selected}
                  onChange={handleChange}
                />
              </div>

              {/* <div className="booking-choice-full-container-divs">
                <ChoiceList
                  title="Customer Payment Mode"
                  choices={[
                    { label: "Paid Online", value: "Paid Online" },
                    { label: "Pay Hotel", value: "Pay Hotel" },
                  ]}
                  selected={selected}
                  onChange={handleChange}
                />
              </div>

              <div className="booking-choice-full-container-divs">
                <ChoiceList
                  title="GST Invoice Status"
                  choices={[
                    { label: "Pending", value: "Pending" },
                    { label: "Uploaded", value: "Uploaded" },
                    { label: "Rejected", value: "Rejected" },
                    {
                      label: "Under Verification",
                      value: "Under Verification",
                    },
                  ]}
                  selected={selected}
                  onChange={handleChange}
                />
              </div> */}
            </div>
          </div>
          <div className="booking-choice-full-parent-container-right">
            <div className="booking-choice-full-parent-container-right-heading">
              <div style={{ width: "300px" }}>
                <BookingSearchInput />
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <SyncButton
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                      fill="none"
                    >
                      <path
                        d="M6.00008 2.66675V0.666748L3.33342 3.33341L6.00008 6.00008V4.00008C8.20675 4.00008 10.0001 5.79342 10.0001 8.00008C10.0001 8.67342 9.83341 9.31342 9.53341 9.86675L10.5067 10.8401C11.0469 9.99135 11.3337 9.00612 11.3334 8.00008C11.3334 5.05341 8.94675 2.66675 6.00008 2.66675ZM6.00008 12.0001C3.79341 12.0001 2.00008 10.2067 2.00008 8.00008C2.00008 7.32675 2.16675 6.68675 2.46675 6.13341L1.49341 5.16008C0.95325 6.00881 0.666468 6.99404 0.666748 8.00008C0.666748 10.9467 3.05341 13.3334 6.00008 13.3334V15.3334L8.66675 12.6667L6.00008 10.0001V12.0001Z"
                        fill="#3968ED"
                      />
                    </svg>
                  }
                  onClick={handleOpenSyncPopup}
                />
                <Link to="/bookings/createbooking">
                  <BgThemeButton children={"Create Booking"} />
                </Link>
              </div>
            </div>
            <div className="booking-table-full-container">
              <div className="booking-first-row-head">
                <div className="booking-first-row-head-divs active">
                  Past 7 Days
                </div>
                <div className="booking-first-row-head-divs">Past 30 Days</div>
                <div className="booking-first-row-head-divs">
                  Upcoming 30 Days
                </div>
                <div className="booking-first-row-head-divs">
                  Select Date Range
                </div>
              </div>

              <div className="booking-second-row-head">
                <div className="booking-second-row-head-divs">Guest Name</div>
                <div className="booking-second-row-head-divs">
                  Stay Duration
                </div>
                <div className="booking-second-row-head-divs">
                  Rooms & Meal Plan
                </div>
                <div className="booking-second-row-head-divs">Booking ID</div>
                <div className="booking-second-row-head-divs">
                  Guest Contact
                </div>
                <div className="booking-second-row-head-divs">Net Amount</div>
              </div>

              <div className="booking-third-row-head-container">
                {loader ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <>
                    {data.Bookings[0].unique_booking_id ? (
                      <>
                        {data.Bookings.map((item) => (
                          <div key={item.id}>
                            <p>9 July | 1 Check-in</p>
                            <div className="booking-third-row-head">
                              <div className="booking-third-row-one-full-div">
                                <div
                                  style={{ position: "relative" }}
                                  className="booking-third-row-one-full-div-child"
                                >
                                  {item.guest.map((guest) => (
                                    <div key={guest.guest_name}>
                                      {guest.guest_name}
                                    </div>
                                  ))}
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      left: "0",
                                      color: "#888",
                                      borderRadius: "4px",
                                      background: "#eee",
                                      padding: "5px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    +3 Guests
                                  </div>
                                </div>
                                <div className="booking-third-row-one-full-div-child">
                                  {item.check_in} {item.check_out}
                                </div>
                                <div className="booking-third-row-one-full-div-child">
                                  {item.items.map((room) => (
                                    <div key={room.id}>
                                      2x {room.room_type_name}
                                      <span>
                                        <CPButton />
                                      </span>
                                    </div>
                                  ))}
                                </div>
                                <div className="booking-third-row-one-full-div-child">
                                  {item.items.map((room) => (
                                    <div key={room.id}>
                                      2x {room.room_type_name}
                                      <span>
                                        <CPButton />
                                      </span>
                                    </div>
                                  ))}
                                </div>
                                <div className="booking-third-row-one-full-div-child">
                                  {item.guest.map((guest) => (
                                    <div key={guest.property_id}>
                                      {guest.guest_phone}
                                    </div>
                                  ))}
                                </div>
                                <div className="booking-third-row-one-full-div-child">
                                  {item.items.map((room) => (
                                    <div key={room.id}>₹ {room.bar_price}</div>
                                  ))}
                                </div>
                              </div>
                              <hr />
                              <div className="booking-third-row-bottom-buttons-full-div">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <PaymentStatusButton />
                                  {item.status === "confirmed" ? (
                                    <ApprovedButton />
                                  ) : item.status === "pending" ? (
                                    <PendingButton />
                                  ) : (
                                    <UnpaidButton />
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Link to="/bookings/previewbill">
                                    <WithoutBgButtonBlue
                                      children={"Preview Bill"}
                                    />
                                  </Link>
                                  {/* <BillGenerateButton /> */}
                                  <ViewVoucherButton children={"Voucher"} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <h6 style={{ textAlign: "center" }}>No Bookings Find</h6>
                    )}
                  </>
                )}
                <div className="booking-third-row-head"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openSyncPopup && (
        <div className="bg-backdrop">
          <div className="dashboard_checkin_modal_container">
            <div
              className="dashboard_checkin_modal_close_btn"
              onClick={handleOpenSyncPopup}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M1 12.7464L13 0.851318"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
                <path
                  d="M13 12.7463L0.999999 0.851296"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
              </svg>
            </div>
            <div className="dashboard_checkin_modal_box sync_modal_box">
              <div className="dashboard_checkin_modal_content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="159"
                  height="130"
                  viewBox="0 0 159 130"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1402_1584)">
                    <path
                      d="M128.993 13.0657C130.001 8.94644 129.042 4.14239 125.787 1.43286C125.723 6.04469 124.034 10.4853 121.019 13.969C119.851 15.3084 118.412 16.6761 118.356 18.454C118.321 19.5602 118.859 20.6227 119.602 21.442C120.344 22.2613 121.281 22.8745 122.206 23.4782L122.342 23.7186C125.321 20.7066 127.985 17.185 128.993 13.0657Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M125.722 1.49299C126.97 4.77296 127.156 8.36384 126.252 11.7556C126.071 12.4892 125.775 13.1896 125.376 13.8309C124.967 14.443 124.402 14.9342 123.739 15.2534C123.127 15.5634 122.466 15.8096 121.94 16.2633C121.674 16.4995 121.462 16.7894 121.315 17.1139C121.169 17.4384 121.093 17.7902 121.092 18.1462C121.067 19.0332 121.361 19.8764 121.677 20.6911C122.028 21.5955 122.407 22.5294 122.326 23.5197C122.316 23.6397 122.503 23.6479 122.512 23.5282C122.654 21.8053 121.486 20.3499 121.301 18.6788C121.215 17.8991 121.362 17.0885 121.937 16.5199C122.44 16.0228 123.122 15.7683 123.744 15.4598C124.395 15.1579 124.962 14.7009 125.396 14.1286C125.827 13.5098 126.149 12.822 126.349 12.0948C126.826 10.4648 127.044 8.76975 126.996 7.07181C126.951 5.13366 126.576 3.21743 125.887 1.40604C125.844 1.29395 125.679 1.38163 125.722 1.49299Z"
                      fill="white"
                    />
                    <path
                      d="M126.607 10.6806C127.196 10.7553 127.791 10.618 128.288 10.2931C128.785 9.96822 129.15 9.47699 129.318 8.90713C129.352 8.79171 129.175 8.73375 129.141 8.84933C128.985 9.38028 128.645 9.83783 128.182 10.1394C127.719 10.441 127.163 10.5667 126.616 10.4939C126.497 10.4782 126.489 10.665 126.607 10.6806Z"
                      fill="white"
                    />
                    <path
                      d="M124.07 15.1371C123.319 14.1834 122.954 12.981 123.048 11.7696C123.057 11.6496 122.871 11.6413 122.861 11.7611C122.766 13.0219 123.148 14.2725 123.932 15.2631C124.007 15.3575 124.144 15.2309 124.07 15.1371Z"
                      fill="white"
                    />
                    <path
                      d="M126.726 5.23724C126.493 5.25588 126.258 5.21607 126.044 5.12137C125.829 5.02668 125.642 4.88006 125.498 4.6947C125.424 4.59942 125.287 4.72611 125.36 4.82076C125.52 5.02482 125.727 5.18648 125.964 5.29164C126.201 5.39679 126.46 5.44223 126.718 5.42398C126.743 5.4243 126.766 5.41527 126.784 5.39871C126.802 5.38216 126.813 5.35932 126.815 5.33484C126.816 5.31011 126.808 5.28598 126.791 5.26771C126.774 5.24943 126.751 5.23848 126.726 5.23724Z"
                      fill="white"
                    />
                    <path
                      d="M111.709 4.09937C111.753 4.16017 111.797 4.22097 111.841 4.28327C112.432 5.10379 112.955 5.97104 113.405 6.87647C113.441 6.94592 113.477 7.01684 113.51 7.08718C114.572 9.28015 115.219 11.6517 115.417 14.0812C115.495 15.0481 115.5 16.0195 115.431 16.987C115.335 18.3232 115.126 19.7644 115.466 21.0172C115.5 21.1483 115.543 21.2772 115.593 21.4031L122.039 23.866C122.057 23.8642 122.074 23.8639 122.091 23.8622L122.344 23.9723C122.351 23.9227 122.357 23.871 122.364 23.8214C122.368 23.7926 122.371 23.7632 122.375 23.7345C122.378 23.7151 122.38 23.6957 122.383 23.6784C122.384 23.6719 122.385 23.6654 122.385 23.6605C122.388 23.6431 122.389 23.6267 122.392 23.6108C122.428 23.3222 122.461 23.0329 122.493 22.7428C122.494 22.7413 122.494 22.7413 122.493 22.7393C122.756 20.5311 122.756 18.2994 122.494 16.0911C122.486 16.0253 122.478 15.958 122.466 15.8911C122.33 14.9061 122.115 13.9338 121.821 12.9839C121.659 12.4644 121.47 11.9533 121.256 11.4529C120.701 10.1608 119.946 8.96433 119.02 7.9073C117.173 5.80039 114.675 4.30893 111.92 4.11231C111.849 4.10734 111.78 4.10286 111.709 4.09937Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M111.692 4.18707C114.659 6.05252 116.965 8.8076 118.281 12.061C118.576 12.7563 118.761 13.494 118.827 14.2469C118.868 14.9825 118.712 15.7156 118.375 16.3703C118.073 16.9869 117.693 17.5827 117.545 18.2622C117.475 18.6112 117.479 18.9711 117.557 19.3184C117.636 19.6657 117.786 19.9924 117.999 20.2773C118.512 21.0008 119.253 21.4966 119.995 21.9562C120.819 22.4666 121.682 22.9835 122.212 23.8232C122.277 23.9249 122.43 23.8191 122.366 23.7175C121.444 22.2567 119.638 21.7992 118.486 20.5766C117.949 20.0062 117.579 19.2699 117.697 18.4689C117.8 17.7684 118.192 17.1537 118.503 16.5319C118.841 15.8982 119.02 15.1911 119.023 14.4723C118.994 13.7184 118.839 12.9748 118.562 12.2733C117.963 10.6841 117.119 9.19907 116.061 7.87261C114.861 6.35201 113.41 5.04834 111.772 4.018C111.67 3.95427 111.591 4.12374 111.692 4.18707Z"
                      fill="white"
                    />
                    <path
                      d="M117.918 10.9884C118.433 10.693 118.826 10.2239 119.028 9.66481C119.229 9.10573 119.225 8.49311 119.018 7.93634C118.975 7.82383 118.799 7.88459 118.841 7.99725C119.036 8.51535 119.039 9.08602 118.85 9.60625C118.662 10.1265 118.294 10.562 117.813 10.8342C117.709 10.8935 117.815 11.0474 117.918 10.9884Z"
                      fill="white"
                    />
                    <path
                      d="M118.569 16.0779C117.397 15.7698 116.383 15.03 115.73 14.006C115.666 13.9045 115.512 14.0103 115.577 14.1117C116.258 15.176 117.314 15.9437 118.535 16.2616C118.652 16.2918 118.685 16.1079 118.569 16.0779Z"
                      fill="white"
                    />
                    <path
                      d="M114.744 6.57038C114.568 6.72626 114.357 6.83605 114.129 6.88989C113.901 6.94372 113.663 6.93991 113.437 6.87881C113.321 6.84712 113.287 7.03108 113.402 7.06256C113.653 7.12891 113.916 7.13279 114.168 7.07387C114.42 7.01496 114.654 6.89503 114.849 6.72458C114.869 6.71006 114.882 6.68854 114.887 6.66441C114.891 6.64027 114.887 6.61533 114.873 6.59464C114.859 6.5743 114.838 6.56034 114.814 6.55579C114.789 6.55125 114.764 6.55649 114.744 6.57038Z"
                      fill="white"
                    />
                    <path
                      d="M103.185 97.1482C81.5725 97.1482 63.9892 79.5246 63.9892 57.8623C63.9892 36.2 81.5725 18.5764 103.185 18.5764C105.47 18.5761 107.751 18.7742 110.002 19.1684C110.03 19.1733 110.057 19.1836 110.081 19.1989C110.105 19.2142 110.126 19.234 110.142 19.2574C110.158 19.2807 110.17 19.3071 110.176 19.335C110.182 19.3628 110.183 19.3916 110.178 19.4198C110.173 19.4479 110.163 19.4747 110.147 19.4988C110.132 19.5229 110.112 19.5437 110.089 19.5601C110.066 19.5765 110.039 19.5881 110.012 19.5942C109.984 19.6004 109.955 19.601 109.927 19.596C107.701 19.2061 105.445 19.0103 103.185 19.0105C81.8113 19.0105 64.4223 36.4393 64.4223 57.8623C64.4223 79.2853 81.8113 96.7141 103.185 96.7141C124.559 96.7141 141.948 79.2853 141.948 57.8623C141.958 48.1793 138.35 38.8436 131.836 31.6929C131.798 31.6503 131.778 31.5943 131.781 31.537C131.784 31.4797 131.809 31.4258 131.851 31.3872C131.893 31.3486 131.949 31.3283 132.006 31.3307C132.064 31.3332 132.117 31.3583 132.156 31.4004C138.743 38.631 142.391 48.0711 142.381 57.8623C142.381 79.5246 124.798 97.1482 103.185 97.1482Z"
                      fill="#E4E4E4"
                    />
                    <path
                      d="M120.468 32.0501C124.751 32.0501 128.223 28.5702 128.223 24.2774C128.223 19.9846 124.751 16.5046 120.468 16.5046C116.185 16.5046 112.713 19.9846 112.713 24.2774C112.713 28.5702 116.185 32.0501 120.468 32.0501Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M119.41 28.3227C119.276 28.3227 119.144 28.2915 119.024 28.2314C118.904 28.1714 118.8 28.0842 118.72 27.9768L116.604 25.1494C116.536 25.0586 116.487 24.9552 116.459 24.8452C116.43 24.7352 116.424 24.6208 116.44 24.5084C116.456 24.396 116.494 24.2879 116.552 24.1902C116.61 24.0925 116.686 24.0071 116.777 23.939C116.867 23.8709 116.97 23.8213 117.08 23.7931C117.19 23.765 117.304 23.7587 117.416 23.7748C117.528 23.7908 117.636 23.8289 117.734 23.8867C117.831 23.9446 117.916 24.0211 117.984 24.1119L119.368 25.9616L122.923 20.6169C123.051 20.4269 123.248 20.2952 123.472 20.2507C123.696 20.2061 123.928 20.2524 124.118 20.3794C124.308 20.5063 124.44 20.7035 124.485 20.9279C124.53 21.1522 124.485 21.3853 124.359 21.5761L120.128 27.9377C120.051 28.0528 119.948 28.1477 119.827 28.2147C119.707 28.2816 119.572 28.3186 119.434 28.3225C119.426 28.3226 119.418 28.3227 119.41 28.3227Z"
                      fill="white"
                    />
                    <path
                      d="M102.177 51.7658L90.218 18.4204L90.5213 18.3111L88.3219 12.178L88.0187 12.2873L84.3468 2.04926C84.2096 1.66688 83.9987 1.31532 83.726 1.01464C83.4533 0.713961 83.1242 0.470062 82.7575 0.296865C82.3908 0.123669 81.9936 0.0245658 81.5887 0.00521852C81.1837 -0.0141288 80.7789 0.0466571 80.3974 0.184105L58.7784 7.97297C58.3969 8.1104 58.0461 8.32181 57.7461 8.59512C57.4461 8.86843 57.2028 9.19828 57.03 9.56585C56.8572 9.93342 56.7583 10.3315 56.739 10.7374C56.7197 11.1433 56.7803 11.549 56.9174 11.9314L59.4776 19.07L58.9984 19.2549L60.6327 23.5084L61.0163 23.3604L61.3556 24.3065L60.9474 24.4641L62.5817 28.7176L62.8943 28.597L74.7475 61.6479C74.884 62.0308 75.0946 62.3828 75.3671 62.6839C75.6397 62.985 75.9689 63.2292 76.3358 63.4025C76.7027 63.5758 77.1002 63.6748 77.5054 63.6938C77.9105 63.7128 78.3155 63.6514 78.6969 63.5132L100.316 55.7243C101.086 55.4459 101.714 54.8728 102.063 54.1307C102.412 53.3886 102.453 52.5381 102.177 51.7658Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M77.5599 62.4141C77.2555 62.4134 76.9586 62.3188 76.7097 62.1431C76.4608 61.9674 76.2719 61.7192 76.1687 61.4321L58.1505 11.1908C58.0178 10.8208 58.0373 10.4131 58.2045 10.0574C58.3717 9.70168 58.673 9.42712 59.0422 9.29408L63.6421 7.63686L63.6352 7.79816C63.6234 8.07817 63.6804 8.35679 63.8014 8.60948C63.9223 8.86217 64.1034 9.08117 64.3287 9.24719C64.554 9.41321 64.8165 9.52115 65.0932 9.5615C65.3699 9.60186 65.6523 9.57338 65.9154 9.47859L76.1239 5.80074C76.3703 5.68758 76.5866 5.51788 76.7555 5.30535C76.9243 5.09283 77.0409 4.84345 77.0957 4.57741C77.15 4.31579 77.1417 4.04503 77.0717 3.7872C77.0017 3.52938 76.8718 3.29181 76.6927 3.09388L76.585 2.9738L81.3364 1.26189C81.7056 1.12943 82.1122 1.14913 82.4669 1.31669C82.8217 1.48425 83.0957 1.78599 83.2289 2.15573L100.42 50.0901C100.771 51.0721 100.719 52.1534 100.275 53.0969C99.8314 54.0404 99.0325 54.7691 98.0536 55.123L78.0612 62.3259C77.9004 62.3841 77.7308 62.4139 77.5599 62.4141Z"
                      fill="white"
                    />
                    <path
                      d="M75.7985 26.779C79.0277 26.779 81.6454 24.1552 81.6454 20.9187C81.6454 17.6821 79.0277 15.0583 75.7985 15.0583C72.5694 15.0583 69.9516 17.6821 69.9516 20.9187C69.9516 24.1552 72.5694 26.779 75.7985 26.779Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M90.2871 29.4408L70.0389 36.7359C69.9757 36.7588 69.9086 36.769 69.8414 36.7659C69.7742 36.7628 69.7083 36.7465 69.6474 36.7178C69.5865 36.6892 69.5319 36.6487 69.4866 36.5989C69.4413 36.549 69.4063 36.4907 69.3835 36.4273C69.3608 36.3638 69.3507 36.2965 69.354 36.2292C69.3572 36.1618 69.3737 36.0958 69.4024 36.0348C69.4312 35.9739 69.4716 35.9192 69.5215 35.874C69.5713 35.8287 69.6296 35.7937 69.6929 35.7711L89.9411 28.4761C90.0686 28.4305 90.209 28.4375 90.3314 28.4955C90.4539 28.5535 90.5483 28.6578 90.5942 28.7855C90.64 28.9132 90.6334 29.0539 90.5758 29.1768C90.5182 29.2996 90.4144 29.3946 90.2871 29.4408Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M84.2741 34.0663L77.6139 36.4659C77.5506 36.4888 77.4835 36.499 77.4163 36.4959C77.3492 36.4928 77.2832 36.4764 77.2224 36.4477C77.1615 36.4191 77.1069 36.3787 77.0616 36.3288C77.0164 36.2789 76.9814 36.2206 76.9586 36.1572C76.9359 36.0938 76.9258 36.0265 76.9291 35.9591C76.9323 35.8918 76.9488 35.8258 76.9775 35.7648C77.0062 35.7039 77.0466 35.6492 77.0965 35.604C77.1463 35.5587 77.2045 35.5237 77.2679 35.501L83.928 33.1015C84.0557 33.0555 84.1964 33.0622 84.3191 33.1202C84.4418 33.1781 84.5365 33.2826 84.5824 33.4105C84.6283 33.5385 84.6215 33.6794 84.5637 33.8024C84.5059 33.9254 84.4017 34.0204 84.2741 34.0663Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M85.8582 38.4833L79.198 40.8829C79.1348 40.9056 79.0677 40.9157 79.0007 40.9125C78.9336 40.9093 78.8678 40.8929 78.807 40.8642C78.7463 40.8355 78.6917 40.7951 78.6465 40.7453C78.6014 40.6955 78.5664 40.6372 78.5437 40.5739C78.521 40.5105 78.5109 40.4433 78.5141 40.3761C78.5173 40.3088 78.5337 40.2429 78.5623 40.182C78.591 40.1211 78.6313 40.0664 78.681 40.0211C78.7307 39.9758 78.7888 39.9408 78.852 39.918L85.5122 37.5185C85.6398 37.4725 85.7805 37.4792 85.9032 37.5372C86.0259 37.5951 86.1206 37.6996 86.1665 37.8275C86.2124 37.9555 86.2057 38.0964 86.1479 38.2194C86.0901 38.3424 85.9859 38.4374 85.8582 38.4833Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M86.6696 40.7459L80.0094 43.1454C79.9462 43.1684 79.879 43.1786 79.8118 43.1756C79.7446 43.1725 79.6787 43.1561 79.6178 43.1275C79.5569 43.0988 79.5022 43.0584 79.4569 43.0085C79.4117 42.9587 79.3766 42.9003 79.3539 42.8369C79.3311 42.7734 79.3211 42.7061 79.3243 42.6387C79.3276 42.5714 79.344 42.5053 79.3728 42.4444C79.4015 42.3834 79.442 42.3288 79.4919 42.2835C79.5418 42.2382 79.6 42.2033 79.6634 42.1806L86.3236 39.7811C86.3868 39.7582 86.454 39.7479 86.5212 39.751C86.5884 39.7541 86.6543 39.7705 86.7152 39.7991C86.776 39.8278 86.8307 39.8682 86.876 39.9181C86.9212 39.9679 86.9562 40.0263 86.979 40.0897C87.0017 40.1531 87.0118 40.2205 87.0085 40.2878C87.0053 40.3551 86.9888 40.4212 86.9601 40.4821C86.9314 40.5431 86.8909 40.5977 86.8411 40.643C86.7912 40.6883 86.7329 40.7232 86.6696 40.7459Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M88.7903 34.898L74.6597 39.989C74.5964 40.0119 74.5293 40.0222 74.4621 40.0191C74.3949 40.016 74.329 39.9996 74.2681 39.971C74.2072 39.9423 74.1526 39.9019 74.1073 39.852C74.062 39.8022 74.027 39.7438 74.0043 39.6804C73.9815 39.617 73.9715 39.5496 73.9747 39.4823C73.978 39.415 73.9944 39.3489 74.0232 39.288C74.0519 39.227 74.0924 39.1724 74.1422 39.1271C74.1921 39.0818 74.2503 39.0469 74.3137 39.0242L88.4443 33.9332C88.5076 33.9103 88.5747 33.9 88.6419 33.9031C88.7091 33.9062 88.775 33.9226 88.8359 33.9512C88.8968 33.9799 88.9514 34.0203 88.9967 34.0702C89.042 34.12 89.077 34.1784 89.0997 34.2418C89.1225 34.3052 89.1325 34.3726 89.1293 34.4399C89.126 34.5072 89.1096 34.5733 89.0808 34.6342C89.0521 34.6952 89.0117 34.7498 88.9618 34.7951C88.912 34.8404 88.8537 34.8753 88.7903 34.898Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M85.9441 46.2379L83.5258 47.1092C83.3088 47.1872 83.0697 47.1757 82.8612 47.0771C82.6527 46.9785 82.4917 46.801 82.4138 46.5835C82.3358 46.3661 82.3471 46.1265 82.4453 45.9174C82.5435 45.7083 82.7205 45.5469 82.9374 45.4686L85.3557 44.5973C85.5727 44.5193 85.8118 44.5309 86.0203 44.6294C86.2288 44.728 86.3898 44.9055 86.4677 45.123C86.5457 45.3404 86.5344 45.58 86.4362 45.7891C86.338 45.9982 86.161 46.1596 85.9441 46.2379Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M66.8872 7.79939C67.0729 7.79939 67.2235 7.64848 67.2235 7.46232C67.2235 7.27616 67.0729 7.12524 66.8872 7.12524C66.7015 7.12524 66.5509 7.27616 66.5509 7.46232C66.5509 7.64848 66.7015 7.79939 66.8872 7.79939Z"
                      fill="white"
                    />
                    <path
                      d="M28.211 26.4201C31.9803 21.9731 38.096 19.1705 43.7343 20.6235C38.6561 24.4128 35.0789 29.8803 33.6355 36.0586C33.0874 38.4433 32.7256 41.153 30.7938 42.6496C29.5919 43.5809 27.9757 43.8355 26.4659 43.6665C24.956 43.4974 23.519 42.9452 22.1012 42.3979L21.7243 42.4398C22.6725 36.6827 24.4418 30.8671 28.211 26.4201Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M43.7201 20.745C39.0654 21.9944 34.9217 24.682 31.8777 28.4261C31.2082 29.2204 30.6677 30.1156 30.2762 31.0787C29.9252 32.0291 29.8344 33.0565 30.0135 34.054C30.1617 34.9875 30.4213 35.9251 30.3406 36.8784C30.2921 37.3658 30.1413 37.8374 29.8982 38.2622C29.6551 38.6871 29.3251 39.0557 28.9299 39.3438C27.9638 40.0873 26.7888 40.4385 25.6278 40.742C24.3388 41.0789 22.9945 41.4084 21.9585 42.2974C21.833 42.4052 21.6736 42.2036 21.7989 42.0961C23.6014 40.5493 26.1613 40.6804 28.1692 39.54C29.1062 39.0079 29.8889 38.1895 30.0574 37.0885C30.2049 36.1256 29.9381 35.1585 29.7798 34.2147C29.5909 33.2441 29.6419 32.242 29.9285 31.2958C30.2698 30.3159 30.775 29.4013 31.4224 28.5913C32.8509 26.7443 34.5603 25.134 36.4879 23.8192C38.6795 22.3064 41.1132 21.1806 43.6837 20.4904C43.8428 20.4477 43.8781 20.7026 43.7201 20.745Z"
                      fill="white"
                    />
                    <path
                      d="M32.7871 27.1628C32.2297 26.5657 31.9022 25.7894 31.8631 24.9725C31.8241 24.1557 32.076 23.3516 32.5738 22.7037C32.675 22.573 32.8825 22.7244 32.7812 22.8553C32.3164 23.4578 32.0819 24.2069 32.1198 24.9674C32.1577 25.728 32.4656 26.4499 32.988 27.0029C33.1013 27.1232 32.8998 27.2824 32.7871 27.1628Z"
                      fill="white"
                    />
                    <path
                      d="M29.8761 33.5908C31.5424 33.6613 33.1741 33.0998 34.4457 32.0181C34.5716 31.9108 34.7311 32.1123 34.6054 32.2194C33.28 33.3426 31.5808 33.9235 29.8469 33.8463C29.6816 33.8388 29.7117 33.5833 29.8761 33.5908Z"
                      fill="white"
                    />
                    <path
                      d="M38.7453 22.6411C38.9129 22.9172 39.1464 23.1473 39.4246 23.3106C39.7029 23.4739 40.0173 23.5654 40.3395 23.5769C40.5048 23.5822 40.4745 23.8377 40.3103 23.8324C39.9543 23.8181 39.6073 23.7166 39.2994 23.5368C38.9916 23.357 38.7324 23.1044 38.5444 22.8011C38.5243 22.774 38.5152 22.7402 38.519 22.7067C38.5229 22.6731 38.5393 22.6423 38.565 22.6204C38.5917 22.5994 38.6256 22.5897 38.6594 22.5936C38.6932 22.5975 38.724 22.6146 38.7453 22.6411Z"
                      fill="white"
                    />
                    <path
                      d="M52.1183 38.5037C52.015 38.5033 51.9117 38.5029 51.8071 38.5042C50.4184 38.5059 49.0321 38.6206 47.6619 38.8473C47.5556 38.8631 47.4482 38.8808 47.3427 38.8997C44.0474 39.481 40.8883 40.6706 38.0263 42.4078C36.888 43.1001 35.8039 43.8782 34.7835 44.7354C33.3742 45.9193 31.9402 47.3153 30.2721 47.9449C30.0989 48.0126 29.9216 48.0694 29.7412 48.115L21.8045 42.8976C21.7924 42.8766 21.7791 42.8575 21.7669 42.8365L21.4405 42.6427C21.49 42.5948 21.5425 42.5462 21.592 42.4983C21.6206 42.4704 21.651 42.4436 21.6797 42.4157C21.6993 42.3975 21.719 42.3793 21.7357 42.3617C21.7422 42.3556 21.7488 42.3496 21.7542 42.3453C21.7709 42.3277 21.7882 42.3132 21.8037 42.2974C22.096 42.0249 22.3907 41.754 22.6878 41.4847C22.6889 41.4829 22.6889 41.4829 22.6919 41.4822C24.9608 39.4358 27.4201 37.5613 30.0851 36.1206C30.1653 36.0772 30.2466 36.032 30.3304 35.9911C31.5353 35.3483 32.7905 34.8054 34.0836 34.3676C34.7924 34.1301 35.5128 33.9288 36.242 33.7644C38.1268 33.3432 40.0659 33.2217 41.9884 33.4045C45.8208 33.7694 49.494 35.3587 51.9341 38.2788C51.9964 38.3535 52.0571 38.4272 52.1183 38.5037Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M52.0344 38.5927C47.5674 36.7814 42.6445 36.4268 37.965 37.5794C36.9532 37.8096 35.984 38.1982 35.0929 38.7309C34.2416 39.2779 33.5521 40.0435 33.0958 40.948C32.6535 41.7828 32.2975 42.688 31.6604 43.4005C31.3289 43.7604 30.9253 44.0459 30.476 44.2385C30.0266 44.431 29.5418 44.5261 29.0532 44.5177C27.8352 44.5283 26.686 44.0997 25.5767 43.6414C24.3451 43.1326 23.0739 42.5845 21.7127 42.6691C21.5477 42.6794 21.5415 42.4223 21.7062 42.4121C24.0745 42.2647 26.0396 43.9142 28.3279 44.2154C29.3957 44.3559 30.5121 44.1748 31.3082 43.3973C32.0043 42.7175 32.3721 41.7843 32.8127 40.9352C33.2449 40.0463 33.8877 39.277 34.6849 38.6945C35.546 38.118 36.4987 37.6926 37.5022 37.4365C39.7522 36.8238 42.0844 36.5696 44.4132 36.683C47.0719 36.7977 49.6913 37.3674 52.1583 38.3675C52.3109 38.4294 52.1861 38.6543 52.0344 38.5927Z"
                      fill="white"
                    />
                    <path
                      d="M39.45 37.1194C39.3636 36.3063 39.5685 35.4888 40.028 34.813C40.4874 34.1373 41.1716 33.6472 41.9583 33.4304C42.1176 33.3871 42.1923 33.6332 42.0327 33.6766C41.2998 33.8771 40.6625 34.3337 40.236 34.9638C39.8094 35.594 39.6216 36.3562 39.7065 37.1129C39.7247 37.2774 39.4681 37.283 39.45 37.1194Z"
                      fill="white"
                    />
                    <path
                      d="M33.2645 40.4953C34.5526 41.5572 36.1927 42.0935 37.8578 41.9972C38.0228 41.9875 38.029 42.2446 37.8643 42.2543C36.1314 42.3512 34.4257 41.7897 33.0877 40.6817C32.9602 40.576 33.1377 40.3902 33.2645 40.4953Z"
                      fill="white"
                    />
                    <path
                      d="M46.9234 37.1046C46.8914 37.4262 46.9396 37.7507 47.0637 38.049C47.1877 38.3474 47.3838 38.6101 47.6342 38.8138C47.763 38.9178 47.5853 39.1035 47.4574 39.0002C47.1818 38.7739 46.9656 38.4834 46.8278 38.1541C46.69 37.8248 46.6348 37.4667 46.6669 37.1111C46.6671 37.0773 46.6802 37.0449 46.7034 37.0204C46.7266 36.9959 46.7583 36.9812 46.7919 36.9793C46.8259 36.9786 46.8588 36.9914 46.8834 37.0148C46.908 37.0383 46.9224 37.0705 46.9234 37.1046Z"
                      fill="white"
                    />
                    <path
                      d="M141.331 111.892C145.441 112.902 150.234 111.941 152.937 108.678C148.336 108.615 143.905 106.922 140.43 103.899C139.093 102.728 137.729 101.287 135.955 101.23C134.851 101.195 133.791 101.735 132.974 102.479C132.156 103.223 131.545 104.162 130.942 105.09L130.702 105.226C133.707 108.212 137.221 110.882 141.331 111.892Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M152.877 108.613C149.605 109.865 146.022 110.051 142.638 109.145C141.906 108.963 141.207 108.666 140.567 108.266C139.957 107.856 139.467 107.29 139.148 106.626C138.839 106.013 138.593 105.35 138.141 104.823C137.905 104.557 137.616 104.343 137.292 104.197C136.968 104.05 136.617 103.974 136.262 103.973C135.377 103.948 134.536 104.243 133.723 104.559C132.821 104.911 131.889 105.291 130.901 105.21C130.781 105.2 130.773 105.387 130.892 105.397C132.611 105.538 134.063 104.368 135.731 104.182C136.509 104.096 137.317 104.244 137.885 104.82C138.381 105.324 138.634 106.008 138.942 106.631C139.243 107.284 139.699 107.852 140.27 108.287C140.888 108.718 141.574 109.041 142.3 109.242C143.926 109.72 145.617 109.939 147.311 109.89C149.245 109.845 151.157 109.47 152.964 108.779C153.076 108.736 152.988 108.571 152.877 108.613Z"
                      fill="white"
                    />
                    <path
                      d="M143.71 109.501C143.636 110.09 143.773 110.687 144.097 111.185C144.421 111.683 144.911 112.049 145.48 112.218C145.595 112.252 145.653 112.074 145.538 112.04C145.008 111.884 144.551 111.543 144.25 111.079C143.95 110.614 143.824 110.058 143.897 109.509C143.912 109.39 143.726 109.382 143.71 109.501Z"
                      fill="white"
                    />
                    <path
                      d="M139.264 106.958C140.216 106.205 141.415 105.839 142.624 105.933C142.744 105.943 142.752 105.756 142.632 105.746C141.374 105.651 140.127 106.034 139.138 106.82C139.044 106.895 139.171 107.032 139.264 106.958Z"
                      fill="white"
                    />
                    <path
                      d="M149.141 109.62C149.123 109.386 149.162 109.151 149.257 108.936C149.351 108.721 149.498 108.533 149.683 108.388C149.778 108.315 149.651 108.177 149.557 108.25C149.353 108.411 149.192 108.619 149.087 108.856C148.982 109.093 148.937 109.353 148.955 109.612C148.955 109.636 148.964 109.66 148.98 109.678C148.997 109.696 149.019 109.707 149.044 109.709C149.069 109.71 149.093 109.701 149.111 109.685C149.129 109.668 149.14 109.645 149.141 109.62Z"
                      fill="white"
                    />
                    <path
                      d="M150.277 94.5684C150.216 94.6127 150.155 94.657 150.093 94.7009C149.274 95.2928 148.409 95.8171 147.506 96.2686C147.436 96.3046 147.366 96.3401 147.296 96.374C145.108 97.4384 142.741 98.0865 140.317 98.2852C139.353 98.3634 138.384 98.3679 137.418 98.2987C136.085 98.2031 134.647 97.9931 133.397 98.3344C133.267 98.3686 133.138 98.4108 133.012 98.4609L130.555 104.922C130.557 104.94 130.557 104.957 130.559 104.974L130.449 105.228C130.499 105.235 130.55 105.241 130.6 105.248C130.628 105.252 130.658 105.255 130.686 105.259C130.706 105.262 130.725 105.264 130.742 105.267C130.749 105.268 130.755 105.269 130.76 105.269C130.777 105.272 130.794 105.273 130.81 105.276C131.098 105.312 131.386 105.346 131.676 105.377C131.677 105.378 131.677 105.378 131.679 105.377C133.882 105.64 136.109 105.641 138.312 105.379C138.378 105.37 138.445 105.362 138.512 105.35C139.495 105.214 140.465 104.998 141.412 104.704C141.931 104.541 142.44 104.352 142.94 104.138C144.229 103.581 145.423 102.825 146.477 101.896C148.579 100.045 150.067 97.541 150.264 94.7795C150.269 94.7088 150.273 94.6396 150.277 94.5684Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M150.189 94.5517C148.328 97.5256 145.579 99.8361 142.333 101.155C141.64 101.451 140.903 101.636 140.152 101.703C139.418 101.744 138.687 101.588 138.034 101.25C137.419 100.947 136.824 100.566 136.146 100.418C135.798 100.348 135.439 100.352 135.092 100.43C134.746 100.509 134.42 100.66 134.136 100.873C133.414 101.387 132.919 102.13 132.461 102.874C131.951 103.699 131.436 104.565 130.598 105.096C130.496 105.16 130.602 105.314 130.703 105.25C132.161 104.326 132.617 102.515 133.837 101.361C134.406 100.822 135.141 100.452 135.94 100.57C136.639 100.674 137.252 101.066 137.873 101.378C138.505 101.717 139.21 101.896 139.927 101.899C140.68 101.871 141.422 101.714 142.121 101.437C143.707 100.837 145.189 99.9912 146.512 98.9301C148.029 97.7275 149.33 96.2737 150.358 94.6315C150.421 94.5298 150.252 94.4507 150.189 94.5517Z"
                      fill="white"
                    />
                    <path
                      d="M143.403 100.792C143.698 101.308 144.166 101.702 144.724 101.903C145.282 102.105 145.893 102.102 146.448 101.894C146.561 101.851 146.5 101.674 146.388 101.717C145.871 101.912 145.301 101.915 144.782 101.726C144.263 101.537 143.829 101.168 143.557 100.686C143.498 100.581 143.345 100.688 143.403 100.792Z"
                      fill="white"
                    />
                    <path
                      d="M138.326 101.444C138.633 100.269 139.371 99.2531 140.393 98.5989C140.494 98.5342 140.388 98.3801 140.287 98.4447C139.225 99.1274 138.46 100.186 138.142 101.41C138.112 101.527 138.296 101.561 138.326 101.444Z"
                      fill="white"
                    />
                    <path
                      d="M147.811 97.6099C147.656 97.4341 147.546 97.2224 147.493 96.9937C147.439 96.765 147.443 96.5266 147.504 96.2997C147.535 96.1835 147.352 96.15 147.32 96.2654C147.254 96.5164 147.25 96.7797 147.309 97.0325C147.368 97.2853 147.487 97.5198 147.657 97.7155C147.672 97.7353 147.693 97.7489 147.718 97.7534C147.742 97.7579 147.766 97.753 147.787 97.7398C147.807 97.7257 147.821 97.7041 147.826 97.6798C147.83 97.6555 147.825 97.6304 147.811 97.6099Z"
                      fill="white"
                    />
                    <path
                      d="M157.799 72.2993L151.532 82.8696L151.77 82.9955L149.648 87.0282L149.206 86.7938L147.812 89.1445L148.158 89.3441L145.884 93.2901L145.49 93.0622L125.041 127.556C124.505 128.458 123.633 129.111 122.617 129.37C121.602 129.629 120.525 129.474 119.623 128.938L81.6072 106.298C80.7064 105.76 80.0553 104.887 79.7967 103.869C79.538 102.85 79.6931 101.771 80.2277 100.867L112.986 45.6109C113.522 44.7082 114.394 44.0557 115.409 43.7965C116.425 43.5372 117.502 43.6926 118.404 44.2283L156.42 66.8687C157.32 67.4065 157.971 68.2802 158.229 69.2982C158.488 70.3162 158.333 71.3955 157.799 72.2993Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M120.978 126.805L82.8281 104.085C82.5505 103.92 82.3499 103.65 82.2702 103.337C82.1905 103.023 82.2382 102.69 82.4029 102.411L115.378 46.7874C115.544 46.5092 115.812 46.3081 116.125 46.2282C116.439 46.1483 116.77 46.1962 117.048 46.3613L155.198 69.0812C155.476 69.247 155.676 69.5163 155.756 69.8301C155.836 70.1439 155.788 70.4766 155.623 70.7552L122.648 126.379C122.483 126.657 122.214 126.859 121.901 126.938C121.588 127.018 121.256 126.97 120.978 126.805Z"
                      fill="white"
                    />
                    <path
                      d="M136.649 56.8421C136.834 56.8421 136.985 56.6912 136.985 56.505C136.985 56.3189 136.834 56.168 136.649 56.168C136.463 56.168 136.312 56.3189 136.312 56.505C136.312 56.6912 136.463 56.8421 136.649 56.8421Z"
                      fill="white"
                    />
                    <path
                      d="M134.567 96.6875L102.722 77.7221C102.622 77.6631 102.535 77.585 102.465 77.4921C102.395 77.3993 102.345 77.2935 102.316 77.181C102.287 77.0685 102.281 76.9513 102.297 76.8363C102.314 76.7213 102.353 76.6107 102.412 76.5108C102.471 76.4109 102.549 76.3238 102.642 76.2543C102.735 76.1848 102.841 76.1344 102.953 76.106C103.066 76.0775 103.182 76.0716 103.297 76.0885C103.412 76.1055 103.522 76.1449 103.621 76.2047L135.467 95.1702C135.567 95.2291 135.654 95.3073 135.724 95.4001C135.793 95.493 135.844 95.5987 135.873 95.7112C135.902 95.8238 135.908 95.9409 135.892 96.0559C135.875 96.1709 135.836 96.2815 135.777 96.3814C135.718 96.4813 135.64 96.5685 135.547 96.6379C135.454 96.7074 135.348 96.7578 135.236 96.7863C135.123 96.8147 135.006 96.8206 134.892 96.8037C134.777 96.7867 134.667 96.7473 134.567 96.6875Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M121.852 93.7488L111.377 87.5105C111.277 87.4513 111.191 87.3731 111.121 87.2802C111.052 87.1874 111.002 87.0818 110.973 86.9695C110.945 86.8572 110.938 86.7403 110.955 86.6256C110.971 86.5109 111.01 86.4005 111.069 86.3009C111.129 86.2012 111.207 86.1142 111.299 86.0448C111.392 85.9754 111.497 85.925 111.609 85.8964C111.721 85.8678 111.838 85.8616 111.952 85.8782C112.067 85.8948 112.177 85.9338 112.276 85.9931L122.751 92.2313C122.851 92.2905 122.937 92.3688 123.007 92.4616C123.076 92.5544 123.126 92.66 123.155 92.7723C123.183 92.8847 123.19 93.0015 123.173 93.1162C123.156 93.2309 123.118 93.3412 123.058 93.4409C122.999 93.5405 122.921 93.6275 122.829 93.6969C122.736 93.7663 122.631 93.8168 122.519 93.8453C122.407 93.8739 122.29 93.8801 122.176 93.8636C122.061 93.847 121.951 93.808 121.852 93.7488Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M117.733 100.696L107.258 94.4576C107.058 94.338 106.913 94.1434 106.855 93.9166C106.797 93.6898 106.832 93.4493 106.951 93.2481C107.071 93.0469 107.265 92.9015 107.491 92.8437C107.717 92.7859 107.957 92.8206 108.158 92.9401L118.633 99.1784C118.732 99.2376 118.819 99.3159 118.888 99.4087C118.958 99.5015 119.008 99.6071 119.036 99.7194C119.065 99.8318 119.071 99.9486 119.055 100.063C119.038 100.178 118.999 100.288 118.94 100.388C118.881 100.488 118.803 100.575 118.71 100.644C118.618 100.713 118.512 100.764 118.4 100.792C118.288 100.821 118.172 100.827 118.057 100.811C117.943 100.794 117.833 100.755 117.733 100.696Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M115.624 104.254L105.149 98.0158C105.049 97.9568 104.962 97.8786 104.893 97.7857C104.823 97.6929 104.773 97.5872 104.744 97.4748C104.715 97.3623 104.709 97.2453 104.725 97.1304C104.742 97.0155 104.781 96.905 104.84 96.8053C104.899 96.7055 104.977 96.6184 105.07 96.5489C105.163 96.4795 105.269 96.4291 105.381 96.4006C105.493 96.372 105.61 96.366 105.724 96.3828C105.839 96.3996 105.949 96.4389 106.049 96.4984L116.523 102.737C116.623 102.796 116.711 102.874 116.78 102.967C116.85 103.06 116.901 103.165 116.93 103.278C116.958 103.39 116.965 103.507 116.948 103.622C116.932 103.738 116.893 103.848 116.834 103.948C116.774 104.048 116.696 104.135 116.603 104.205C116.51 104.274 116.405 104.324 116.292 104.353C116.18 104.381 116.063 104.387 115.948 104.37C115.834 104.353 115.723 104.314 115.624 104.254Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M125.696 100.672L103.472 87.4368C103.272 87.3167 103.128 87.1222 103.071 86.8959C103.014 86.6695 103.049 86.4298 103.168 86.2291C103.287 86.0284 103.48 85.8831 103.706 85.8251C103.931 85.767 104.171 85.8009 104.371 85.9194L126.596 99.1549C126.695 99.2139 126.783 99.292 126.852 99.3849C126.922 99.4777 126.973 99.5834 127.002 99.696C127.031 99.8085 127.037 99.9257 127.02 100.041C127.004 100.156 126.965 100.266 126.906 100.366C126.847 100.466 126.768 100.553 126.675 100.623C126.582 100.692 126.477 100.743 126.364 100.771C126.252 100.8 126.135 100.805 126.02 100.788C125.906 100.772 125.795 100.732 125.696 100.672Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M121.46 117.587L117.657 115.322C117.316 115.119 117.069 114.788 116.972 114.402C116.874 114.017 116.933 113.608 117.136 113.266C117.339 112.924 117.668 112.677 118.053 112.579C118.437 112.48 118.845 112.539 119.186 112.742L122.99 115.007C123.331 115.21 123.578 115.541 123.676 115.927C123.774 116.313 123.715 116.722 123.512 117.064C123.309 117.406 122.979 117.653 122.594 117.751C122.209 117.85 121.801 117.791 121.46 117.587Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M128.556 80.8106C132.862 80.8106 136.352 77.3123 136.352 72.9969C136.352 68.6814 132.862 65.1831 128.556 65.1831C124.251 65.1831 120.76 68.6814 120.76 72.9969C120.76 77.3123 124.251 80.8106 128.556 80.8106Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M0.739319 104.749H106.24V43.5308C106.239 43.0722 106.057 42.6324 105.733 42.3081C105.41 41.9837 104.971 41.8013 104.513 41.8008H2.46546C2.00782 41.8013 1.56908 41.9837 1.24548 42.3081C0.921878 42.6324 0.739847 43.0722 0.739319 43.5308V104.749Z"
                      fill="#CACACA"
                    />
                    <path
                      d="M102.98 44.1936H3.9989V100.516H102.98V44.1936Z"
                      fill="white"
                    />
                    <path
                      d="M0.647522 103.093V110.647C0.647519 110.96 0.709059 111.27 0.828629 111.56C0.948199 111.849 1.12346 112.112 1.3444 112.333C1.56534 112.555 1.82764 112.731 2.11631 112.85C2.40498 112.97 2.71438 113.032 3.02684 113.032H41.9662V127.941H41.4489C41.4083 127.941 41.3681 127.949 41.3306 127.964C41.2931 127.98 41.259 128.003 41.2303 128.032C41.2016 128.06 41.1789 128.094 41.1633 128.132C41.1478 128.17 41.1398 128.21 41.1398 128.251V129.288C41.1398 129.328 41.1478 129.369 41.1633 129.406C41.1789 129.444 41.2016 129.478 41.2303 129.507C41.259 129.535 41.2931 129.558 41.3306 129.574C41.3681 129.589 41.4083 129.597 41.4489 129.597H65.6219C65.6625 129.597 65.7027 129.589 65.7402 129.574C65.7777 129.558 65.8118 129.535 65.8405 129.507C65.8692 129.478 65.892 129.444 65.9075 129.406C65.923 129.369 65.931 129.328 65.931 129.288V128.251C65.931 128.21 65.923 128.17 65.9075 128.132C65.892 128.094 65.8692 128.06 65.8405 128.032C65.8118 128.003 65.7777 127.98 65.7402 127.964C65.7027 127.949 65.6625 127.941 65.6219 127.941H65.1046V113.032H104.044C104.356 113.032 104.666 112.97 104.955 112.85C105.243 112.731 105.505 112.555 105.726 112.333C105.947 112.112 106.123 111.849 106.242 111.56C106.362 111.27 106.423 110.96 106.423 110.647V103.093L0.647522 103.093Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M88.6574 63.0298H41.7837C41.4882 63.0298 41.2048 62.9122 40.9959 62.7027C40.7869 62.4933 40.6696 62.2093 40.6696 61.9131C40.6696 61.6169 40.7869 61.3329 40.9959 61.1235C41.2048 60.914 41.4882 60.7964 41.7837 60.7964H88.6574C88.9529 60.7964 89.2363 60.914 89.4452 61.1235C89.6542 61.3329 89.7715 61.6169 89.7715 61.9131C89.7715 62.2093 89.6542 62.4933 89.4452 62.7027C89.2363 62.9122 88.9529 63.0298 88.6574 63.0298Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M72.9296 68.0709H57.5115C57.216 68.0709 56.9326 67.9533 56.7236 67.7439C56.5147 67.5344 56.3973 67.2504 56.3973 66.9542C56.3973 66.658 56.5147 66.3739 56.7236 66.1645C56.9326 65.9551 57.216 65.8374 57.5115 65.8374H72.9296C73.2251 65.8374 73.5085 65.9551 73.7174 66.1645C73.9264 66.3739 74.0438 66.658 74.0438 66.9542C74.0438 67.2504 73.9264 67.5344 73.7174 67.7439C73.5085 67.9533 73.2251 68.0709 72.9296 68.0709Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M72.9296 78.2965H57.5115C57.216 78.2965 56.9326 78.1789 56.7236 77.9694C56.5147 77.76 56.3973 77.476 56.3973 77.1798C56.3973 76.8836 56.5147 76.5995 56.7236 76.3901C56.9326 76.1806 57.216 76.063 57.5115 76.063H72.9296C73.2251 76.063 73.5085 76.1806 73.7174 76.3901C73.9264 76.5995 74.0438 76.8836 74.0438 77.1798C74.0438 77.476 73.9264 77.76 73.7174 77.9694C73.5085 78.1789 73.2251 78.2965 72.9296 78.2965Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M72.9296 83.5337H57.5115C57.3652 83.5337 57.2203 83.5048 57.0851 83.4487C56.95 83.3926 56.8271 83.3104 56.7237 83.2067C56.6202 83.103 56.5381 82.9799 56.4821 82.8444C56.4262 82.7089 56.3973 82.5637 56.3973 82.417C56.3973 82.2704 56.4262 82.1251 56.4821 81.9897C56.5381 81.8542 56.6202 81.7311 56.7237 81.6274C56.8271 81.5237 56.95 81.4414 57.0851 81.3853C57.2203 81.3292 57.3652 81.3003 57.5115 81.3003H72.9296C73.0759 81.3003 73.2208 81.3292 73.3559 81.3853C73.4911 81.4414 73.6139 81.5237 73.7174 81.6274C73.8209 81.7311 73.9029 81.8542 73.9589 81.9897C74.0149 82.1251 74.0437 82.2704 74.0437 82.417C74.0437 82.5637 74.0149 82.7089 73.9589 82.8444C73.9029 82.9799 73.8209 83.103 73.7174 83.2067C73.6139 83.3104 73.4911 83.3926 73.3559 83.4487C73.2208 83.5048 73.0759 83.5337 72.9296 83.5337Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M81.5765 73.1118H48.8646C48.5691 73.1118 48.2857 72.9942 48.0768 72.7848C47.8678 72.5753 47.7504 72.2913 47.7504 71.9951C47.7504 71.699 47.8678 71.4149 48.0768 71.2055C48.2857 70.9961 48.5691 70.8784 48.8646 70.8784H81.5765C81.872 70.8784 82.1554 70.9961 82.3644 71.2055C82.5733 71.4149 82.6907 71.699 82.6907 71.9951C82.6907 72.2913 82.5733 72.5753 82.3644 72.7848C82.1554 72.9942 81.872 73.1118 81.5765 73.1118Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M87.877 94.2569H82.2788C81.7763 94.2569 81.2944 94.0568 80.9391 93.7007C80.5838 93.3446 80.3842 92.8616 80.3842 92.358C80.3842 91.8543 80.5838 91.3713 80.9391 91.0152C81.2944 90.659 81.7763 90.459 82.2788 90.459H87.877C88.3795 90.459 88.8614 90.659 89.2167 91.0152C89.572 91.3713 89.7716 91.8543 89.7716 92.358C89.7716 92.8616 89.572 93.3446 89.2167 93.7007C88.8614 94.0568 88.3795 94.2569 87.877 94.2569Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M27.0441 80.4744C32.4261 80.4744 36.789 76.1014 36.789 70.7072C36.789 65.3129 32.4261 60.9399 27.0441 60.9399C21.6622 60.9399 17.2993 65.3129 17.2993 70.7072C17.2993 76.1014 21.6622 80.4744 27.0441 80.4744Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M141.079 130H0.905355C0.837099 130 0.771708 129.972 0.723514 129.924C0.67532 129.875 0.648254 129.81 0.648254 129.741C0.648254 129.673 0.67532 129.607 0.723514 129.559C0.771708 129.51 0.837099 129.483 0.905355 129.483H141.079C141.147 129.483 141.212 129.51 141.26 129.559C141.309 129.607 141.336 129.673 141.336 129.741C141.336 129.81 141.309 129.875 141.26 129.924C141.212 129.972 141.147 130 141.079 130Z"
                      fill="#CACACA"
                    />
                    <path
                      d="M53.4436 42.9972C53.6464 42.9972 53.8109 42.8324 53.8109 42.6291C53.8109 42.4258 53.6464 42.261 53.4436 42.261C53.2407 42.261 53.0763 42.4258 53.0763 42.6291C53.0763 42.8324 53.2407 42.9972 53.4436 42.9972Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1402_1584">
                      <rect
                        width="157.705"
                        height="130"
                        fill="white"
                        transform="translate(0.647522)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <h4>
                  Sync your <br /> External Booking
                </h4>
                <div className="copylink_custom_input_container">
                  <div className="copylink_custom_input">
                    <input type="search" placeholder="Enter Booking ID" />
                  </div>
                  <BgThemeButton
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M8.00002 3.1665V1.1665L5.33335 3.83317L8.00002 6.49984V4.49984C10.2067 4.49984 12 6.29317 12 8.49984C12 9.17317 11.8334 9.81317 11.5334 10.3665L12.5067 11.3398C13.0469 10.4911 13.3336 9.50588 13.3334 8.49984C13.3334 5.55317 10.9467 3.1665 8.00002 3.1665ZM8.00002 12.4998C5.79335 12.4998 4.00002 10.7065 4.00002 8.49984C4.00002 7.8265 4.16669 7.1865 4.46669 6.63317L3.49335 5.65984C2.95319 6.50856 2.66641 7.4938 2.66669 8.49984C2.66669 11.4465 5.05335 13.8332 8.00002 13.8332V15.8332L10.6667 13.1665L8.00002 10.4998V12.4998Z"
                          fill="white"
                        />
                      </svg>
                    }
                    style={{
                      borderTopLeftRadius: "unset",
                      borderBottomLeftRadius: "unset",
                    }}
                    children={"Sync"}
                  />
                </div>
                <p className="synced_successfully_msg">
                  #345674425 booking has been synced successfully
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Booking;
