import styles from "./Buttons.module.scss";

export const PrimaryButton = ({
  disabled,
  title,
  onClick,
  type,
  style,
  icon,
  loader,
  loaderText,
  isIconLeft,
  isIconRight,
  size,
}) => {
  return (
    <button
      style={{
        cursor: loader ? "not-allowed" : "pointer",
        ...style,
      }}
      disabled={disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.primary_button} ${styles[size]}`}
    >
      {loader ? (
        <>
          <span
            className={`${styles.bg_theme_button_spinner} ${styles[size]}`}
          ></span>
          <span>{loaderText}</span>
        </>
      ) : (
        <>
          {isIconLeft && icon}
          {title}
          {isIconRight && icon}
        </>
      )}
    </button>
  );
};

export const SecondaryButton = ({
  disabled,
  title,
  onClick,
  type,
  style,
  icon,
  loader,
  loaderText,
  isIconLeft,
  isIconRight,
  size = "small",
}) => {
  return (
    <button
      style={{
        cursor: loader ? "not-allowed" : "pointer",
        ...style,
      }}
      disabled={disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.secondary_button} ${styles[size]}`}
    >
      {loader ? (
        <>
          <span
            className={`${styles.bg_theme_button_spinner} ${styles[size]}`}
          ></span>
          <span>{loaderText}</span>
        </>
      ) : (
        <>
          {isIconLeft && icon}
          {title}
          {isIconRight && icon}
        </>
      )}
    </button>
  );
};

export const GhostButton = ({
  disabled,
  title,
  onClick,
  type,
  style,
  icon,
  loader,
  loaderText,
  isIconLeft,
  isIconRight,
  size = "small",
}) => {
  return (
    <button
      style={{
        cursor: loader ? "not-allowed" : "pointer",
        ...style,
      }}
      disabled={disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.ghost_button} ${styles[size]}`}
    >
      {loader ? (
        <>
          <span
            className={`${styles.bg_theme_button_spinner} ${styles[size]}`}
          ></span>
          <span>{loaderText}</span>
        </>
      ) : (
        <>
          {isIconLeft && icon}
          {title}
          {isIconRight && icon}
        </>
      )}
    </button>
  );
};

export const LinkButton = ({
  disabled,
  title,
  onClick,
  type,
  style,
  icon,
  loader,
  loaderText,
  isIconLeft,
  isIconRight,
  size = "small",
}) => {
  return (
    <button
      style={{
        cursor: loader ? "not-allowed" : "pointer",
        ...style,
      }}
      disabled={disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.link_button} ${styles[size]}`}
    >
      {loader ? (
        <>
          <span
            className={`${styles.bg_theme_button_spinner} ${styles[size]}`}
          ></span>
          <span>{loaderText}</span>
        </>
      ) : (
        <>
          {isIconLeft && icon}
          {title}
          {isIconRight && icon}
        </>
      )}
    </button>
  );
};

//===== icon buttons =====

export const PrimaryIconButton = ({
  disabled,
  onClick,
  type,
  style,
  loader,
  size,
  icon,
}) => {
  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 14;
      case "medium":
        return 12;
      case "small":
        return 10;
      default:
        return 14;
    }
  };

  return (
    <button
      style={{
        cursor: disabled || loader ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "#D6D6D6" : "#3968ED",
        ...style,
      }}
      disabled={loader ? true : ""}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.primary_icon_button} ${
        size ? styles[size] : styles.large
      }`}
    >
      {icon ? (
        icon
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M8.08334 1.16663H6.91667V6.41663H1.66667V7.58329H6.91667V12.8333H8.08334V7.58329H13.3333V6.41663H8.08334V1.16663Z"
            fill="#fff"
          />
        </svg>
      )}
    </button>
  );
};
export const PrimaryIconButtonRounded = ({
  disabled,
  onClick,
  type,
  style,
  icon,
  loader,
  size,
}) => {
  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 14;
      case "medium":
        return 12;
      case "small":
        return 10;
      default:
        return 14;
    }
  };
  return (
    <button
      style={{
        cursor: loader || disabled ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "#D6D6D6" : "#3968ED",
        ...style,
      }}
      disabled={loader ? true : disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.primary_icon_button}  ${
        styles.primary_icon_button_rounded
      } ${size ? styles[size] : styles.large}`}
    >
      {icon ? (
        icon
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M8.08334 1.16663H6.91667V6.41663H1.66667V7.58329H6.91667V12.8333H8.08334V7.58329H13.3333V6.41663H8.08334V1.16663Z"
            fill="#fff"
          />
        </svg>
      )}
    </button>
  );
};

export const SecondaryIconButton = ({
  disabled,
  onClick,
  type,
  style,
  loader,
  size,
  icon,
}) => {
  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 14;
      case "medium":
        return 12;
      case "small":
        return 10;
      default:
        return 14;
    }
  };

  return (
    <button
      style={{
        cursor: disabled || loader ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "#D6D6D6" : "",

        ...style,
      }}
      disabled={loader ? true : ""}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.secondary_icon_button} ${
        size ? styles[size] : styles.large
      }`}
    >
      {icon ? (
        icon
      ) : (
        <svg
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16671 1.33337H7.83337V7.33337H1.83337V8.66671H7.83337V14.6667H9.16671V8.66671H15.1667V7.33337H9.16671V1.33337Z"
            // fill="#2B59D9"
          />
        </svg>
      )}
    </button>
  );
};
export const SecondaryIconButtonRounded = ({
  disabled,
  onClick,
  type,
  style,
  icon,
  loader,
  size,
}) => {
  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 14;
      case "medium":
        return 12;
      case "small":
        return 10;
      default:
        return 14;
    }
  };
  return (
    <button
      style={{
        cursor: loader || disabled ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "#D6D6D6" : "",
        ...style,
      }}
      disabled={loader ? true : disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.secondary_icon_button}  ${
        styles.secondary_icon_button_rounded
      } ${size ? styles[size] : styles.large}`}
    >
      {icon ? (
        icon
      ) : (
        <svg
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16671 1.33337H7.83337V7.33337H1.83337V8.66671H7.83337V14.6667H9.16671V8.66671H15.1667V7.33337H9.16671V1.33337Z"
            fill=""
          />
        </svg>
      )}
    </button>
  );
};

export const GhostIconButton = ({
  disabled,
  onClick,
  type,
  style,
  loader,
  size,
  icon,
}) => {
  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 14;
      case "medium":
        return 12;
      case "small":
        return 10;
      default:
        return 14;
    }
  };

  return (
    <button
      style={{
        cursor: disabled || loader ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "#ADADAD" : "",

        ...style,
      }}
      disabled={loader ? true : ""}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.ghost_icon_button} ${
        size ? styles[size] : styles.large
      }`}
    >
      {icon ? (
        icon
      ) : (
        <svg
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16671 1.33337H7.83337V7.33337H1.83337V8.66671H7.83337V14.6667H9.16671V8.66671H15.1667V7.33337H9.16671V1.33337Z"
            fill="#333"
          />
        </svg>
      )}
    </button>
  );
};
export const GhostIconButtonRounded = ({
  disabled,
  onClick,
  type,
  style,
  icon,
  loader,
  size,
}) => {
  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 14;
      case "medium":
        return 12;
      case "small":
        return 10;
      default:
        return 14;
    }
  };
  return (
    <button
      style={{
        cursor: loader || disabled ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "#ADADAD" : "",
        ...style,
      }}
      disabled={loader ? true : disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`${styles.ghost_icon_button}  ${
        styles.secondary_icon_button_rounded
      } ${size ? styles[size] : styles.large}`}
    >
      {icon ? (
        icon
      ) : (
        <svg
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16671 1.33337H7.83337V7.33337H1.83337V8.66671H7.83337V14.6667H9.16671V8.66671H15.1667V7.33337H9.16671V1.33337Z"
            fill="#333"
          />
        </svg>
      )}
    </button>
  );
};
