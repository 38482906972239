import React, { useCallback, useState } from "react";
import AddDiscount from "./AddDiscount";
import AddCategoryAgent from "./UI/AddCategoryAgent";
import {
  Page,
  LegacyCard,
  DataTable,
  Button,
  TextField,
  LegacyStack,
  Icon,
  Collapsible,
} from "@shopify/polaris";
import { ChevronDownMinor, TickMinor } from "@shopify/polaris-icons";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import LineChartDashboard from "./LineChartsDashboard";
import SliderCustomScore from "./UI/SliderCustomScore";
import CustomToggleButton from "./UI/CustomToggleButton";
const CompetitiveAnalysis = () => {
  const BottomTableRows = [
    ["Golden Fern Resort", 4500, 4500, 4500, 4500, 4500, 4500],
    ["Hotel Snow Castle", 4500, 4500, 4500, 4500, 4500, 4500],
    ["Hotel Woodrina", 4500, 4500, 4500, 4500, 4500, 4500],
    ["Hill Top Resort", 4500, 4500, 4500, 4500, 4500, 4500],
    ["Hotel Bobs N Barley", 4500, 4500, 4500, 4500, 4500, 4500],
  ];

  const [expanded, setExpanded] = useState(false);

  // useState for slider
  const [range, setRange] = useState(10);

  const [hundredMinusrange, setHundredMinusrange] = useState(100 - range);
  const [rangePlusAllowance, setRangePlusAllowance] = useState(
    hundredMinusrange - 4
  );

  const [open1, setOpen1] = useState(false);
  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);

  const [selectedOption, setSelectedOption] = useState("today");

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // use state
  const [openDayStatus, setOpenDayStatus] = useState();
  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);
  return (
    <section style={{ padding: "25px 150px 25px" }}>
      {/* <div className="dashboard-upper-buttons-container">
        <Button
          fullWidth
          textAlign="left"
          disclosure={expanded ? "up" : "down"}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Today" : "Show more"}
        </Button>
        <Button
          fullWidth
          textAlign="left"
          disclosure={expanded ? "up" : "down"}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "All Channels" : "Show more"}
        </Button>
      </div> */}
      <div className="dashboard2-full-container" style={{}}>
        <div className="dashboard2-full-container-left" style={{}}>
          <LegacyCard>
            <div
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              className="room-types-full-container-div"
            >
              <div
                style={{ marginTop: "20px" }}
                className="room-type-heading"
              >
                Competitor Rates
              </div>
              <div style={{ marginTop: "20px" }}>
                <BgOutlineThemeButton children={"Add Competitor"} />
              </div>
            </div>
            <DataTable
              columnContentTypes={[
                "text",
                "numeric",
                "numeric",
                "numeric",
                "numeric",
                "numeric",
                "numeric",
              ]}
              headings={[
                "Price",
                "23 Jul",
                "24 Jul",
                "25 Jul",
                "26 Jul",
                "27 Jul",
                "28 Jul",
              ]}
              rows={BottomTableRows}
            />
          </LegacyCard>
          {/* <div><LineChartDashboard /></div>
          <div
            style={{
              borderRadius: "8px",
              border: "1px solid #3968ED",
              background: "#ECF1FF",
              width: "100%",
              padding: "20px 15px",
              gap: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M19.6 14C19.6 12.4536 18.9732 11.0536 17.9598 10.0402L19.9397 8.06031C21.4598 9.58041 22.4 11.6804 22.4 14C22.4 18.6392 18.6392 22.4 14 22.4C9.36081 22.4 5.6 18.6392 5.6 14C5.6 9.8378 8.6272 6.38265 12.6 5.71614V2.88665C7.07516 3.57559 2.8 8.28854 2.8 14C2.8 20.1856 7.81441 25.2 14 25.2C20.1856 25.2 25.2 20.1856 25.2 14C25.2 10.9072 23.9464 8.10721 21.9196 6.08041L23.8995 4.10052C26.433 6.63402 28 10.134 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C14.4725 0 14.9395 0.0234072 15.4 0.0691253V11.5746C16.2369 12.0587 16.8 12.9636 16.8 14C16.8 15.5464 15.5464 16.8 14 16.8C12.4536 16.8 11.2 15.5464 11.2 14C11.2 12.9636 11.7631 12.0587 12.6 11.5746V8.57642C10.1847 9.19808 8.4 11.3906 8.4 14C8.4 17.0928 10.9072 19.6 14 19.6C17.0928 19.6 19.6 17.0928 19.6 14Z"
                    fill="#3968ED"
                  />
                </svg>
              </div>
              <div
                style={{
                  color: "#3968ED",
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Day Status
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <CustomToggleButton
                func={openDayStatusHandler}
                positive={"Open"}
                negative={"Closed"}
                toggleTextStyle={{
                  color: "#15AA12",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
              width: "100%",
            }}
          >
            <div
              style={{
                padding: "12px",
                borderRadius: "8px",
                background: "#FFF",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",

                  marginBottom: "25px",
                }}
              >
                <div>Today</div>
                <CheckWithoutBgUnderlineBtn children={"Check-In"} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: "#282828",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ fontWeight: "500" }}>Total</div>
                  <div>Check-In</div>
                </div>
                <div
                  style={{
                    color: "#282828",
                    fontFamily: "Inter",
                    fontSize: "30px",
                    fontWeight: "700",
                  }}
                >
                  80
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "12px",
                borderRadius: "8px",
                background: "#FFF",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",

                  marginBottom: "25px",
                }}
              >
                <div>Today</div>
                <CheckWithoutBgUnderlineBtn children={"Check-Out"} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: "#282828",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ fontWeight: "500" }}>Total</div>
                  <div>Check-Out</div>
                </div>
                <div
                  style={{
                    color: "#282828",
                    fontFamily: "Inter",
                    fontSize: "30px",
                    fontWeight: "700",
                  }}
                >
                  80
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "750px", margin: "0 -24px" }}></div> */}
        </div>
        <div className="dashboard2-full-container-right">
          <div style={{ width: "6 00px" }}>
            <LineChartDashboard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompetitiveAnalysis;
