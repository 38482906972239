import React from "react";
import Sidebar from "../Components/Sidebar";
import CreateSegments from "../Components/CreateSegments";
import NewSidebar from "../Components/NewSidebar";

const CreateSegmentPage = () => {
  return (
    <>
      <div className="main_container">
        <CreateSegments />
      </div>
    </>
  );
};

export default CreateSegmentPage;
