import React from "react";
import Rooms from "../Components/Rooms";
import NewSidebar from "../Components/NewSidebar";
import SidebarInnerLists from "../Components/CustomComponents/SidebarInnerLists";
import { rooms_inner_lists } from "../assets/constant";

const RoomsPage = () => {
  // const internaListsItems = [
  //   {
  //     name: "Rooms",
  //     link: "/rooms",
  //   },
  //   {
  //     name: "Room Types",
  //     link: "/roomstype",
  //   },
  //   {
  //     name: "Room Plans",
  //     link: "/roomsplans",
  //   },
  //   {
  //     name: "Room Rate Plan",
  //     link: "/roomsrateplans",
  //   },
  //   {
  //     name: "Inventory",
  //     link: "/inventory",
  //   },
  // ]
  return (
    <>
      <div className="main_container">
        <Rooms />
      </div>
    </>
  );
};

export default RoomsPage;
