import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import Loader from "./UI/Loader/Loader";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { rooms_inner_lists } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { getDecryptedData } from "../assets/encryptStorage";

const RoomPlans = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [data, setData] = useState([]);

  const [orders, setOrders] = useState(data);
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");
  // console.log(orders, "orders");

  const [loader, setLoader] = useState(true);

  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  // fetch data from api

  const url =
    "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/mealPlans";

  // const fetchInfo = () => {
  //   setLoader(true)
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((data) => setOrders(data.plans))
  //   setLoader(false);
  // }

  // async function fetchInfo() {

  //   setLoader(true)
  //   const response = await fetch(url);
  //   const data = await response.json();
  //   setOrders(data.plans)
  //   setLoader(false)
  // }

  const fetchInfo = async () => {
    try {
      setLoader(true); // Set loading before sending API request
      const response = await await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.plans);
      // setLoader(false); // Stop loading
    } catch (error) {
      // setLoader(false); // Stop loading in case of error
      console.error(error);
    }
  };
  useEffect(() => {
    fetchInfo();
  }, []);
  // fetchUserData()

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    key: index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  // console.log(loader);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  // const { selectedResources, allResourcesSelected, handleSelectionChange } =
  //   useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      // setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
    clearSelection();
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  // useEffect(() => {
  // `http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/mealPlans/edit/${id}`
  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/edit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.mealPlan, "data.mealPlan");
      const updatedData = {
        meal_type: data.mealPlan.meal_type,
        meal_name: data.mealPlan.meal_name,
        lunch: data.mealPlan.lunch,
        breakfast: data.mealPlan.breakfast,
        dinner: data.mealPlan.dinner,
      };
      setAddRoomPlans(updatedData);
      console.log(addRoomPlans);
    } catch (error) {
      console.error(error);
    }
  };
  // getOneData(selectedResources[0]);
  // }, [selectedResources]);

  // getItemChecked(selectedResources[0]);
  // console.log(selectedResources);
  const rowMarkup = orders.map(({ id, meal_type, meal_name, index }) => (
    <IndexTable.Row
      id={id}
      key={index}
      selected={selectedResources.includes(id)}
      position={id}
    >
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={meal_type}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>{}</IndexTable.Cell>
      <IndexTable.Cell>
        <CustomCssComponentTableText
          innerText={meal_name}
        ></CustomCssComponentTableText>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    console.log("removed");
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  // breakfast Options
  const mealsOptions = [
    { label: "Excluded", value: "excluded" },
    { label: "Included", value: "included" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "Room Only" },
    {
      label: "Room + Complimentary Breakfast",
      value: "Room + Complimentary Breakfast",
    },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "Room with Complimentary Breakfast and dinner",
    },
    {
      label: "Breakfast + Lunch + Dinner",
      value: "Breakfast + Lunch + Dinner",
    },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [addRoomPlans, setAddRoomPlans] = useState({
    meal_type: "EP",
    meal_name: "",
    lunch: "Excluded",
    breakfast: "Excluded",
    dinner: "Excluded",
  });

  // const setButtonStatus = useCallback((event) => {
  //   if (event.target.value === "booked") {
  //     setAddRoomPlans({ ...addRoomPlans, roomStatus: "booked" });
  //   } else if (event.target.value === "available") {
  //     setAddRoomPlans({ ...addRoomPlans, roomStatus: "available" });
  //   }
  // }, [addItemData]);

  const setAddItemDataHandleChange = (event) => {
    setAddRoomPlans({
      ...addRoomPlans,
      [event.target.name]: event.target.value,
    });
  };
  // console.log(addRoomPlans);

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    console.log(deletedIds);

    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/mealPlans/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    // now do whatever you want with the data
    console.log(data);
    fetchInfo();
    clearSelection();
    setShowLowerMenuMultipleSelect(false);
    setShowModelMenuActive(false);
  };

  const handleSubmitAddMeals = (event) => {
    // event.preventDefault();
    // setLoader(true)
    // const requestData = {
    //   property_token: token,
    //   date_range: formInputValues.date_range,
    //   adult: formInputValues.adult,
    //   rooms: formInputValues.rooms,
    //   children: formInputValues.children || 0,
    //   childAge: formInputValues.childAge ? formInputValues.childAge : []
    // };
    // try {
    //   const response = axios.post('/api/search-hotels-rooms', requestData);
    //   const roomsData = response.data;
    //   dispatch(setSingleHotelRoomsData(roomsData));
    //   router.push("/hotels-search-results/hotel-rooms/#rooms-details", undefined, { shallow: true }).then(() => {
    //     setLoader(false);
    //   });
    // } catch (error) {
    //   console.log(error);
    //   setLoader(false);
    // }
    // 'http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/mealPlans/store'
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/store`, {
      method: "POST",
      body: JSON.stringify(addRoomPlans),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((json) => fetchInfo())
      .then((json) => setShowAddModelMenuActive(false))
      .then((json) =>
        setAddRoomPlans({
          meal_type: "EP",
          meal_name: "",
          lunch: "Excluded",
          breakfast: "Excluded",
          dinner: "Excluded",
        })
      );
  };

  const updateFunction = (id) => {
    console.log(id, "Update Function");

    const findOrder = orders.find((user) => user.id === id);
    // console.log(findOrder,"findOrder");
    // `http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/mealPlans/update/${id}`
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/update/${id}`, {
      method: "POST",
      body: JSON.stringify(addRoomPlans),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((json) => fetchInfo())
      .then((json) => setShowLowerMenuSingleSelect(false))
      .then((json) => clearSelection());
  };

  return (
    <>
      <SidebarInnerLists innerLists={rooms_inner_lists} />

      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Room Plans</div>
              <div>
                <BgThemeButton
                  children={"Add Plans"}
                  onClick={addModalButtonClickHandler}
                />
              </div>
            </div>

            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={orders.length}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: "Plan Type" },
                  { title: "" },
                  { title: "" },
                  { title: "" },
                  { title: "" },
                  { title: "" },
                  { title: "" },
                  { title: "" },
                  { title: "" },
                  { title: "" },
                  { title: "Plan Name" },
                ]}
              >
                {rowMarkup}
              </IndexTable>

              {/* Single select */}
              {showLowerMenuSingleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div>
                      <Button plain destructive onClick={() => showModelMenu()}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    </div>
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="room-type-bottom-div">
                        <div>Plan Type</div>
                        <div style={{ width: "70px" }}>
                          <VerticalInputSelectCustomCopy
                            options={planTypeOptions}
                            name="meal_type"
                            type="text"
                            value={addRoomPlans.meal_type}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                      </div>
                      <div className="room-type-bottom-div">
                        <div>Plan Name</div>
                        <div style={{ width: "400px" }}>
                          <VerticalInputFieldCustom
                            // required={true}
                            name="meal_name"
                            className="textfield"
                            onChange={(e) => setAddItemDataHandleChange(e)}
                            focused={false}
                            value={addRoomPlans.meal_name}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <BgThemeButton
                        onClick={() => updateFunction(selectedResources[0])}
                        children={"Update"}
                        type="submit"
                      />
                    </form>
                  </div>
                </div>
              )}

              {/* Multiple select */}
              {showLowerMenuMultipleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button plain destructive onClick={() => showModelMenu()}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </LegacyCard>
          </div>

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="room-plan-add-model-container"
                >
                  <h4>Add Plan</h4>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="meal_type"
                        options={planTypeOptions}
                        value={addRoomPlans.meal_type}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Plan Type"
                        autoComplete="off"
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name="meal_name"
                        className="textfield"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        value={addRoomPlans.meal_name}
                        titleName="Plan Name"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="breakfast"
                        options={mealsOptions}
                        value={addRoomPlans.breakfast}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Breakfast"
                        autoComplete="off"
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="lunch"
                        options={mealsOptions}
                        value={addRoomPlans.lunch}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Lunch"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="dinner"
                        options={mealsOptions}
                        value={addRoomPlans.dinner}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Dinner"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      onClick={(e) => handleSubmitAddMeals(e)}
                      type="submit"
                      children={"Add"}
                    />
                    <BgOutlineThemeButton
                      children={"Discard"}
                      onClick={removeAddModalContainer}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button onClick={() => deleteData()}>Discard</Button>
                    <Button onClick={() => deleteData()} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default RoomPlans;
