import React from "react";
import SingleCustomer from "../Components/SingleCustomer";

const SingleCustomerPage = () => {
  return (
    <>
      <div className="main_container">
        <SingleCustomer />
      </div>
    </>
  );
};

export default SingleCustomerPage;
