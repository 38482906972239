import React, { useEffect, useState } from "react";
import { SelectBasicWithSelectTag } from "../CustomComponents/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import CustomToggleButton from "../UI/CustomToggleButton";
import ToggleSwitch from "../CustomComponents/NewCustomComp/Switch/ToggleSwitch";
import { PrimaryInput } from "../CustomComponents/NewCustomComp/Inputs/Inputs";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { getDecryptedData } from "../../assets/encryptStorage";

const SelectPlansSetup = ({
  isTrial,
  setIsTrial,
  formDataStepFour,
  setFormDataStepFour,
}) => {
  // const userToken = localStorage.getItem("userToken");
  const userToken = getDecryptedData("encryptToken");

  const [plans, setPlans] = useState([]);

  const fetchPlans = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/plans/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      console.log(data.success);
      if (data.success) {
        showSuccessToast(data.message);
        setPlans(data.data.data);
      } else {
        showErrorToast(data.message);
      }

      // if (success === true) {
      // }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //  if (userToken){
    fetchPlans();
    //  }
  }, []);

  const plansOption = [
    { label: "select", value: "" }, // Default option
    ...plans
      ?.filter((item) => item.show_onine === 1) // Only include items with show_onine as 1
      .map((item) => ({
        value: item.id,
        label: item.name,
      })),
  ];

  const billingOptions = [
    { label: "select", value: "" },
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Yearly", value: "yearly" },
  ];
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataStepFour((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div>
      <div className="flex justify_content_between flex_gap_10">
        <SelectBasicWithSelectTag
          required={true}
          name={"plan_id"}
          value={formDataStepFour?.plan_id}
          label={"Select Plan"}
          options={plansOption}
          onChange={handleChange}
        />
        <SelectBasicWithSelectTag
          required={true}
          name={"frequency"}
          value={formDataStepFour?.frequency}
          label={"Billing Frequency"}
          options={billingOptions}
          onChange={handleChange}
        />
      </div>

      <div className="flex justify_content_between flex_gap_10 align_items_center mt-10">
        <div className="flex  flex_gap_10 align_items_center">
          <span>Free Trail</span>
          <ToggleSwitch
            size={"medium"}
            isOn={isTrial}
            handleToggle={() => setIsTrial(!isTrial)}

          />
        </div>

        <div>
          {isTrial ? (
            <div className="flex flex_gap_10 align_items_center">
              <span>
                Trial Days <sup style={{ color: "red" }}>*</sup>{" "}
              </span>

              <PrimaryInput size={"small"} style={{ width: "50px" }} name={"trial_days"} value={formDataStepFour?.trial_days}  onChange={handleChange}/>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SelectPlansSetup;
