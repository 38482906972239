import React from "react";
import Rooms from "../Components/Rooms";
import NewSidebar from "../Components/NewSidebar";
import SidebarInnerLists from "../Components/CustomComponents/SidebarInnerLists";
import { rooms_inner_lists } from "../assets/constant";
import Plans from "../Components/Plans";

const PlanPage = () => {
  return (
    <>
      <div className="main_container">
        <Plans />
      </div>
    </>
  );
};

export default PlanPage;
