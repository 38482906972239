import React from "react";
import Sidebar from "../Components/Sidebar";
import Discounts from "../Components/Discounts";
import Booking from "../Components/Booking";
import NewSidebar from "../Components/NewSidebar";

const BookingPage = () => {
  return (
    <>
      <div className="main_container">
        <Booking />
      </div>
    </>
  );
};

export default BookingPage;
