import React from "react";
import Sidebar from "../Components/Sidebar";

import EditAgentProfile from "../Components/EditAgentProfile";
import NewSidebar from "../Components/NewSidebar";

const AgentEditProfilePage = () => {
  return (
    <>
      <div className="main_container">
        <EditAgentProfile />
      </div>
    </>
  );
};

export default AgentEditProfilePage;
