import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { BgOutlineThemeButton, BgThemeButton } from "./Buttons";

const AddDepartment = ({
  setShowAddUnitsMenuActive,
  setShowAddDepartmentMenuActive,
  setShowAddModelMenuActive,
}) => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const addCategories = [
    {
      id: "Kitchen Department",
      department: "Provision",
    },
    {
      id: "House Keeping Department",
      department: "House Keeping Department",
    },
    {
      id: "Exclusive Agents",
      department: "Exclusive Agents",
    },
    {
      id: "F & B Department",
      department: "F & B Department",
    },
  ];
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(addCategories);

  const addCategoriesRowMarkup = addCategories.map(
    ({ id, department, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{department}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  return (
    <>
     <div
        onClick={() => [
          setShowAddUnitsMenuActive(false),
          setShowAddDepartmentMenuActive(false),
          setShowAddModelMenuActive(false),
        ]}
        className="bg-backdrop"
      ></div>
      <section className="agent-add-categories-model-container">
        <LegacyCard>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "10px",
            }}
          >
            <div>
              <Page
                backAction={{ content: "Products", url: `/hotelier/items` }}
                title="Departments"
                compactTitle
              ></Page>
            </div>
            <div>
              <BgThemeButton
                onClick={() => [
                  setShowAddCategoriesLowerMenuActive(
                    !showAddCategoriesLowerMenuActive
                  ),
                  setShowLowerMenuMultipleSelect(false),
                  setShowLowerMenuSingleSelect(false),
                ]}
                children={"Add Departments"}
              />
            </div>
          </div>
          <div
            style={{
              padding: "10px",
            }}
          >
            <IndexTable
              resourceName={resourceName}
              itemCount={addCategories.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[{ title: "Departments" }]}
            >
              {addCategoriesRowMarkup}
            </IndexTable>
          </div>

          {/* Single select */}
          {showLowerMenuSingleSelect && (
            <div className=" room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div>
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
                <form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="room-type-bottom-div">
                    <div>Department Name</div>
                    <div style={{ width: "200px" }}>
                      <Select labelInline />
                    </div>
                  </div>
                  <BgThemeButton children={"Update"} />
                </form>
              </div>
            </div>
          )}

          {/* Multiple select */}
          {showLowerMenuMultipleSelect && (
            <div className="room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          )}

          {/* Add Button Click */}
          {showAddCategoriesLowerMenuActive && (
            <div className=" room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                {/* <div>
                  <Button plain destructive>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span></span>
                      <span>Add</span>
                    </div>
                  </Button>
                </div> */}
                <form
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="room-type-bottom-div">
                    <div style={{ marginLeft: "10px" }}>Department Name</div>
                    <div style={{ width: "150px" }}>
                      <Select
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      color: "#3968ED",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton children={"Add"} />
                    <BgOutlineThemeButton
                      onClick={() => setShowAddCategoriesLowerMenuActive(false)}
                      children={"Cancel"}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
        </LegacyCard>
      </section>
    </>
  );
};

export default AddDepartment;
