import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import AddCategoryAgent from "./UI/AddCategoryAgent";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { customers_inner_list } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { useDispatch } from "react-redux";
import Loader from "./UI/Loader/Loader";

import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

import { CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { SvgCopy } from "../assets/svgIcons";
import { getDecryptedData } from "../assets/encryptStorage";
const Customers = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));

  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  // fetch data
  const [btnStatus, setButtonStatus] = useState([]);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const fetchInfo = async () => {
    try {
      const response = await fetch(
        // ${process.env.REACT_APP_BASE_URL}/api/v1/admin/customers/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/customers/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      // if (data.message === "Unauthorised.") {
      //   navigate("/");
      // }
      setOrders(data.data.data);
      // setOrders({
      //   ...orders,
      //   url:"/test"
      // })
      console.log(data.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (token) => {
    if (!token) {
      showErrorToast("No token");
      return;
    }
    const textarea = document.createElement("textarea");
    textarea.value = token;

    document.body.appendChild(textarea);

    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");

    document.body.removeChild(textarea);

    setIsCopied(true);
    showSuccessToast("Code copied sucessfully...");

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const generateKey = async (id) => {
    try {
      const response = await fetch(
        //{{main}}/api/v1/admin/generate/account/key/11
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/generate/account/key/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          fetchInfo();
        } else {
          showErrorToast(data.message);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);
  setTimeout(() => {
    setLoader(false);
  }, 1000);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const customers = [
    {
      id: "3411",
      url: "#",
      name: "Mae Jemison",
      location: "Decatur, USA",
      orders: 20,
      amountSpent: "$2,400",
    },
    {
      id: "2561",
      url: "#",
      name: "Ellen Ochoa",
      location: "Los Angeles, USA",
      orders: 30,
      amountSpent: "$140",
    },
  ];

  const rowMarkup = orders.map(
    ({
      id,
      index,
      account_name,
      billing_email,
      billing_phone,
      company_gst,
      token,
      url,
      property_limits,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <div
            style={{ margin: "7px 0", fontSize: "14px", fontWeight: "500" }}
            onClick={() => navigate(`/singlecustomer/${id}`)}
          >
            <Link
              style={{ color: "#3968ED", textDecoration: "none" }}
              dataPrimaryLink
              url={url}
            >
              <Text fontWeight="bold" as="span">
                {account_name}
              </Text>
            </Link>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={billing_phone}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={billing_email}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={company_gst}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={property_limits}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {/* <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#3968ed",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/targets/${id}`)}
          >
            Targets
          </button> */}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text alignment="center">
            {token && token !== "" && token !== null ? (
              <Tooltip content={token}>
                <div className="flex align_items_center flex_gap_5" onClick={() => copyToClipboard(token)}>
                  <SvgCopy />
                  Copy Key
                </div>
              </Tooltip>
            ) : (
              <BgOutlineThemeButton onClick={() => generateKey(id)}>
                Generate Key
              </BgOutlineThemeButton>
            )}
          </Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    console.log("removed");
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Status Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [asignHotelChecked, setAsignHotelChecked] = useState(false);

  const asignHotelCheckboxHandleChange = useCallback(
    (newChecked) => setAsignHotelChecked(newChecked),
    []
  );

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    room_name: "",
    room_no: "",
    email: "",
    status: "booked",
    room_id: "dsd",

    // property_id: property_id?.id,
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };
  console.log(addItemData);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Customers</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {/* <div>
              <BgOutlineThemeButton
                children={"Categories"}
                onClick={() => setShowAddModelMenuActive(!showModelMenuActive)}
              />
            </div> */}

                <div>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/customers/addprofile`}
                  >
                    <BgThemeButton children={"Add Customer"} />
                  </Link>
                </div>
              </div>
            </div>

            <LegacyCard>
              <IndexTable
                selectable={false}
                resourceName={resourceName}
                itemCount={orders.length}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: "Customers Name" },
                  { title: "Phone" },
                  { title: "Email" },
                  { title: "GST" },
                  { title: "Property Limit" },
                ]}
              >
                {rowMarkup}
              </IndexTable>

              {/* Single select */}
              {showLowerMenuSingleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        gap: "20px",
                      }}
                    >
                      <Button plain destructive onClick={showModelMenu}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>

                      {/* <Link
                        style={{ textDecoration: "none" }}
                        to={`/agent/editprofile`}
                      >
                        <BgOutlineThemeButton children={"Edit"} />
                      </Link> */}
                    </div>
                  </div>
                </div>
              )}

              {/* Multiple select */}
              {showLowerMenuMultipleSelect && (
                <div className="room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button plain destructive onClick={showModelMenu}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </LegacyCard>
          </div>

          {/* Add Button Click */}
          {/* {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Rooms
                    </div>
                    <div
                      onClick={() => setShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Room No."
                        name="room_no"
                        type="number"
                        value={addItemData.room_no}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={roomTypeOptions}
                        titleName="Room Type"
                        name="room_id"
                        value={addItemData.room_id}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Floor No."
                        name="email"
                        value={addItemData.email}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>

                    <div
                      style={{ width: "100%" }}
                      className="form-container-div"
                    >
                      <VerticalInputSelectCustomCopy
                        // options={statusOptions}
                        titleName="Status"
                        name="status"
                        value={addItemData.status}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        // onClick={(e) => handleSubmitAddMeals(e)}
                        type="submit"
                        children={"Add"}
                      />
                      <Button
                      // onClick={() => setShowAddModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )} */}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              <div className="bg-backdrop"></div>
              <section className="room-rate-plan-edit-model-container">
                <h4>Edit Rate Plan</h4>
                <div className="edit-rooms-form-container">
                  <div className="form-container-div-full">
                    <Select
                      options={planTypeOptions}
                      value={planTypeSelected}
                      onChange={planTypeHandleSelectChange}
                      className="textfield"
                      focused={false}
                      label="Plan Type"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <TextField
                      className="textfield"
                      focused={false}
                      label="Rack Price"
                      autoComplete="off"
                      placeholder="6500.00"
                    />
                  </div>
                  <div className="form-container-div">
                    <TextField
                      className="textfield"
                      focused={false}
                      label="Bar Price"
                      autoComplete="off"
                      placeholder="7000.00"
                    />
                  </div>
                </div>
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <TextField
                      className="textfield"
                      focused={false}
                      label="Extra Adult"
                      autoComplete="off"
                      placeholder="850.00"
                    />
                  </div>
                  <div className="form-container-div">
                    <TextField
                      className="textfield"
                      focused={false}
                      label="Child With Bed"
                      autoComplete="off"
                      placeholder="500.00"
                    />
                  </div>
                  <div className="form-container-div">
                    <TextField
                      className="textfield"
                      focused={false}
                      label="Child Without Bed"
                      autoComplete="off"
                      placeholder="500.00"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button primary>Update</Button>
                  <Button onClick={() => setShowEditModelMenuActive(false)}>
                    Discard
                  </Button>
                </div>
              </section>
            </>
          )}
          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={removeItemConfirm} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Customers;
