import React, { useCallback, useEffect, useState } from "react";
const CustomToggleButton = (props) => {
  const [isToggled, setIsToggled] = useState(true);
  const [beddingFinalValue, setBeddingFinalValue] = useState("");
  const handleToggle = useCallback(() => {
    setIsToggled((isToggled) => !isToggled);
  }, [isToggled]);
  props.func(beddingFinalValue);

  useEffect(() => {
    if (isToggled === false) {
      setBeddingFinalValue("disabled");
    } else {
      setBeddingFinalValue("enabled");
    }
  }, [isToggled]);

  // console.log(beddingFinalValue);

  return (
    <>
      <div className="bedding-wrapper">
        <div>
          <p className="bedding_text">{props.title}</p>
        </div>
        <div className="fle  align_items_center">
          <span className="toggle_text">
            {isToggled ? props.positive : props.negative}
          </span>
          <label className="toggle-container">
            <input
              name="extra_bedding"
              type="checkbox"
              checked={isToggled}
              onChange={handleToggle}
              className="toggle-checkbox-custom"
            />
            <span className="toggle-slider"></span>
          </label>
        </div>
      </div>
    </>
  );
};
export default CustomToggleButton;
