export const getDateByMonth = (dateStr) => {
    const date = new Date(dateStr);

    // Get day, month, and year in the required format
    const day = date.toLocaleDateString("en-GB", { day: "2-digit" });
    const month = date.toLocaleDateString("en-GB", { month: "short" });
    const year = date
        .toLocaleDateString("en-GB", { year: "numeric" })
        .toUpperCase();

    return `${day} ${month}, ${year}`;
};

export const getDateAndMonth = (dateStr) => {
    const date = new Date(dateStr);

    // Get day, month, and year in the required format
    const day = date.toLocaleDateString("en-GB", { day: "2-digit" });
    const month = date.toLocaleDateString("en-GB", { month: "short" });
    const year = date
        .toLocaleDateString("en-GB", { year: "numeric" })
        .toUpperCase();

    return `${day} ${month}`;
};