import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link } from "react-router-dom";
import AddCategoryAgent from "./UI/AddCategoryAgent";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import AddCategoryItem from "./UI/AddCategoryItem";
import AddDepartment from "./UI/AddDepartment";
import AddUnits from "./UI/AddUnits";

const Items = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showAddDepartmentMenuActive, setShowAddDepartmentMenuActive] =
    useState(false);
  const [showAddUnitsMenuActive, setShowAddUnitsMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => { },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
          {
            type: "rename",
            onAction: () => { },
            onPrimaryAction: async (value) => {
              const newItemsStrings = tabs.map((item, idx) => {
                if (idx === index) {
                  return value;
                }
                return item.content;
              });
              await sleep(1);
              setItemStrings(newItemsStrings);
              return true;
            },
          },
          {
            type: "duplicate",
            onPrimaryAction: async (value) => {
              await sleep(1);
              duplicateView(value);
              return true;
            },
          },
          {
            type: "edit",
          },
          {
            type: "delete",
            onPrimaryAction: async () => {
              await sleep(1);
              deleteView(index);
              return true;
            },
          },
        ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => { };

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
        type: "save-as",
        onAction: onCreateNewView,
        disabled: false,
        loading: false,
      }
      : {
        type: "save",
        onAction: onHandleSave,
        disabled: false,
        loading: false,
      };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const orders = [
    {
      id: "1",
      itemName: "Kitchen King Masala",
      unit: "Gm",
      itemCategory: "Provision",
      department: "Kitchen",
    },
    {
      id: "2",
      itemName: "Kashmiri Mirch (100 Gm)",
      unit: "Kg",
      itemCategory: "Vegetables",
      department: "House Keeping",
    },
    {
      id: "3",
      itemName: "Dhaniya Pwder",
      unit: "Gm",
      itemCategory: "Provision",
      department: "F & B",
    },
    {
      id: "4",
      itemName: "Kaju Broken",
      unit: "Kg",
      itemCategory: "Vegetables",
      department: "Kitchen",
    },
    {
      id: "5",
      itemName: "Maida Non Branded",
      unit: "Kg",
      itemCategory: "Vegetables",
      department: "House Keeping",
    },
    {
      id: "6",
      itemName: "King S Soyabean Oil",
      unit: "Kg",
      itemCategory: "Vegetables",
      department: "F & B",
    },
    {
      id: "7",
      itemName: "Sugar",
      unit: "Kg",
      itemCategory: "Vegetables",
      department: "Kitchen",
    },
    {
      id: "8",
      itemName: "Papad (200 Gm)",
      unit: "Kg",
      itemCategory: "Vegetables",
      department: "House Keeping",
    },
  ];

  const addCategories = [
    {
      id: "1",
      category: "General Agents",
      discount: "10",
    },
    {
      id: "2",
      category: "Preferred Agents",
      discount: "15",
    },
    {
      id: "3",
      category: "Exclusive Agents",
      discount: "20",
    },
    {
      id: "4",
      category: "Special Agents",
      discount: "25",
    },
  ];

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const showAddDepartmentMenuActiveHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const getItemChecked = (id) => {
    console.log(id);
  };

  getItemChecked(selectedResources[0]);

  const rowMarkup = orders.map(
    ({ id, itemName, unit, itemCategory, department, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{itemName}</IndexTable.Cell>
        <IndexTable.Cell>{unit}</IndexTable.Cell>
        <IndexTable.Cell>{itemCategory}</IndexTable.Cell>
        <IndexTable.Cell>{department}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const addCategoriesRowMarkup = addCategories.map(
    ({ id, category, discount, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{category}</IndexTable.Cell>
        <IndexTable.Cell>{discount}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    console.log("removed");
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Status Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [asignHotelChecked, setAsignHotelChecked] = useState(false);

  const asignHotelCheckboxHandleChange = useCallback(
    (newChecked) => setAsignHotelChecked(newChecked),
    []
  );

  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Items</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div>
              <BgOutlineThemeButton
                children={"Units"}
                onClick={() =>
                  setShowAddUnitsMenuActive(!showAddUnitsMenuActive)
                }
              />
            </div>
            <div>
              <BgOutlineThemeButton
                children={"Departments"}
                onClick={() =>
                  setShowAddDepartmentMenuActive(!showAddDepartmentMenuActive)
                }
              />
            </div>
            <div>
              <BgOutlineThemeButton
                children={"Categories"}
                onClick={() => setShowAddModelMenuActive(!showModelMenuActive)}
              />
            </div>

            <div>
              <Link style={{ textDecoration: "none" }} to={`/agent/addprofile`}>
                <BgThemeButton children={"Add Items"} />
              </Link>
            </div>
          </div>
        </div>

        <LegacyCard>
          <IndexTable
            resourceName={resourceName}
            itemCount={orders.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={[
              { title: "Item Name" },
              { title: "Unit" },
              { title: "Item Category" },
              { title: "Department" },
            ]}
          >
            {rowMarkup}
          </IndexTable>

          {/* Single select */}
          {showLowerMenuSingleSelect && (
            <div className=" room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>

                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/agent/editprofile`}
                  >
                    <BgOutlineThemeButton children={"Edit"} />
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* Multiple select */}
          {showLowerMenuMultipleSelect && (
            <div className="room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </LegacyCard>
      </div>

      {/* Add Button Click */}
      {showAddModelMenuActive && (
        <AddCategoryItem
          setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          setShowAddModelMenuActive={setShowAddModelMenuActive}
        />
      )}

      {/* Add Department Click */}
      {showAddDepartmentMenuActive && (
        <AddDepartment
          setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          setShowAddModelMenuActive={setShowAddModelMenuActive}
        />
      )}

      {/* Add Units Click */}
      {showAddUnitsMenuActive && (
        <AddUnits
          setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          setShowAddModelMenuActive={setShowAddModelMenuActive}
        />
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="room-rate-plan-edit-model-container">
            <h4>Edit Rate Plan</h4>
            <div className="edit-rooms-form-container">
              <div className="form-container-div-full">
                <Select
                  options={planTypeOptions}
                  value={planTypeSelected}
                  onChange={planTypeHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Plan Type"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Rack Price"
                  autoComplete="off"
                  placeholder="6500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Bar Price"
                  autoComplete="off"
                  placeholder="7000.00"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Extra Adult"
                  autoComplete="off"
                  placeholder="850.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child With Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child Without Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button primary>Update</Button>
              <Button onClick={() => setShowEditModelMenuActive(false)}>
                Discard
              </Button>
            </div>
          </section>
        </>
      )}
      {showModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="room-type-model-container ">
            <h4>Are You Sure?</h4>
            <p>You Want To Remove?</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                onClick={() => setShowModelMenuActive(!showModelMenuActive)}
              >
                Discard
              </Button>
              <Button onClick={removeItemConfirm} destructive>
                Yes, Remove
              </Button>
            </div>
          </section>
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Items;
