import React from "react";
import Channels from "../Components/Channels";
import ChannelsNew from "../Components/CustomComponents/ChannelsNew/ChannelsNew";

const ChannelsPage = () => {
  return (
    <>
      <div className="main_container">
        {/* <Channels /> */}
        <ChannelsNew/>
      </div>
    </>
  );
};

export default ChannelsPage;
