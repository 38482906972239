import React from "react";
import Sidebar from "../Components/Sidebar";
import RoomTypes from "../Components/RoomTypes";
import NewSidebar from "../Components/NewSidebar";

const RoomsTypePage = () => {
  return (
    <>
      <div className="main_container">
        <RoomTypes />
      </div>
    </>
  );
};

export default RoomsTypePage;
