import { Page } from "@shopify/polaris";
import React from "react";
import { BgOutlineThemeButton, BgThemeButton, PaidButton } from "./UI/Buttons";
import CustomToggleButton from "./UI/CustomToggleButton";

const Agent2 = () => {
  const CustomToggleButtonHandler = () => {};
  const bookingDetails = [
    {
      id: 1,
      tag: 1313343454,
      paymentStatus: "paid",
      day: "January 28, 2022",
      time: "3:10",
      bookedFrom: "MakeMyTrip",
      price: 450,
    },
    {
      id: 2,
      tag: 1313343454,
      paymentStatus: "Unpaid",
      day: "January 28, 2022",
      time: "4:10",
      bookedFrom: "MakeMyTrip",
      price: 450,
    },
    {
      id: 3,
      tag: 1313343454,
      paymentStatus: "paid",
      day: "January 28, 2022",
      time: "5:10",
      bookedFrom: "Goibibo",
      price: 450,
    },
  ];
  return (
    <div className="agent-full-container">
      <div style={{ paddingLeft: "48px" }}>
        <Page
          backAction={{ content: "Products", url: `/roomstype` }}
          title="John Smith"
          compactTitle
          subtitle="Ground Floor, Gupta Niwas, Near Petrol Pump Kasumpti, Vikasnagar, Shimla."
        ></Page>
      </div>

      <div className="agent-full-container-div">
        <div className="agent-full-container-left">
          <div className="agent-full-container-left-div1">
            <div style={{ width: "280px", borderRight: "0.5px solid #CCC" }}>
              <span>Amount Spent</span>
              <div>$8500</div>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <span>Bookings</span>
              <div>25</div>
            </div>
          </div>

          <div className="agent-full-container-left-div2">
            <div className="heading">Last Bookings</div>

            {bookingDetails.map((item) => {
              return (
                <div className="booking-details-div">
                  <div>
                    <div className="booking-details-div-tag">
                      <div>#{item.tag}</div>

                      <div>
                        <PaidButton />
                      </div>
                    </div>
                    <div className="booking-details-div-date">
                      {item.day} at {item.time} from {item.bookedFrom}
                    </div>
                  </div>
                  <div className="booking-details-div-money">${item.price}</div>
                </div>
              );
            })}
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <BgOutlineThemeButton children={"Create Booking"} />
              <BgThemeButton children={"View All Bookings"} />
            </div>
          </div>
        </div>

        <div className="agent-full-container-right">
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Agent
              </div>
              <div>Edit</div>
            </div>
            <div
              style={{
                color: "#333",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              <div>Contact Information</div>
              <div
                style={{
                  fontWeight: "500",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                77788-89990
              </div>
              <div style={{ fontWeight: "500", color: "#3968ED" }}>
                example@agentmail.com
              </div>
            </div>
          </div>
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Business Details
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Company Name
                </div>
                <div>Himalayan Travel Agency</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  GST NO.
                </div>
                <div>4TGG5Y45689HNY</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Business Type
                </div>
                <div>Travel Agency</div>
              </div>
            </div>
          </div>
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Agent Category
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#333",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  General Agent
                </div>
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Malesuada iaculis nibh
                  id mattis semper eleifend iaculis vulputate amet. Nunc eget
                  morbi enim facilisis urna. Volutpat risus iaculis tellus sit
                  in. Neque nibh dui aliquet scelerisque posuere enim.
                </div>
              </div>
            </div>
          </div>
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Temporary Booking
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div>
                <CustomToggleButton func={CustomToggleButtonHandler} />
              </div>
            </div>
          </div>
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Assign Hotels
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div>
                {/* <CustomToggleButton func={CustomToggleButtonHandler} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agent2;
