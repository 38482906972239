import React, { useState, useEffect } from "react";

const CustomProgressBar = ({ progress, duration }) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (width < progress) {
                setWidth((prevWidth) => prevWidth + 1);
            }
        }, duration / (progress - width + 1));

        return () => {
            clearInterval(interval);
        };
    }, [width, progress, duration]);

    return (
        <div className="custom-progress-bar-container">
            <div className="custom-progress-bar" style={{ width: `${width}%` }} />
        </div>
    );
};

export default CustomProgressBar;