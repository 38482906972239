import React from "react";

const LocationSetting = () => {
    return (
        <>
            <div className="setting_location_sec">
                <div className="setting_location">
                    <div className="setting_location_image">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_1596_697)">
                                <path fillRule="evenodd" clip-rule="evenodd" d="M17.4668 8.13366C17.4668 4.00833 14.1254 0.666992 10.0001 0.666992C5.87478 0.666992 2.53345 4.00833 2.53345 8.13366C2.53345 8.27366 2.53345 8.40433 2.54278 8.54433C2.66411 11.8577 4.40011 15.6563 9.19745 19.0817C9.67345 19.4177 10.3268 19.4177 10.8028 19.0817C15.6001 15.6563 17.3361 11.8577 17.4574 8.54433C17.4668 8.40435 17.4668 8.27363 17.4668 8.13366ZM11.9797 10.1133C12.5052 9.58873 12.8001 8.87659 12.8001 8.13366C12.8001 7.39073 12.5042 6.67953 11.9797 6.15406C11.4552 5.62859 10.743 5.33366 10.0001 5.33366C9.25718 5.33366 8.54598 5.62953 8.02051 6.15406C7.49505 6.67859 7.20011 7.39073 7.20011 8.13366C7.20011 8.87659 7.49505 9.58873 8.02051 10.1133C8.54505 10.6387 9.25718 10.9337 10.0001 10.9337C10.7421 10.9337 11.4542 10.6387 11.9797 10.1133Z" fill="#333333" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1596_697">
                                    <rect width="18.6667" height="18.6667" fill="white" transform="translate(0.666748 0.666992)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div>
                       <h4>Golden Fern Resort</h4> 
                       <p>Ground Floor, Gupta Niwas, Near Petrol Pump, Vikas Nagar Shimla, Himachal Pradesh, India 171009</p>
                    </div>
                    <button></button>
                </div>
            </div>
        </>
    );
};

export default LocationSetting;
