import React, { useState } from "react";
import styles from "./pagination.module.scss";

const Pagination = ({ totalPages, currentPage, onPageChange, type }) => {
  const [inputPage, setInputPage] = useState("");

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (+value < 1) {
      return;
    }
    if (+value > totalPages) {
      return;
    }

    setInputPage(e.target.value);
  };

  const handleInputSubmit = () => {
    const pageNum = parseInt(inputPage);
    if (pageNum > 0 && pageNum <= totalPages) {
      onPageChange(pageNum);
    }
  };

  const renderSimple = () => (
    <div className={styles.simple}>
      <button onClick={handlePrev} disabled={currentPage === 1}>
        Previous
      </button>
      <button onClick={handleNext} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );

  const renderBasic = () => (
    <div className={styles.basic}>
      <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
        {"<"}
      </button>
      {[...Array(totalPages)].map((_, idx) => (
        <button
          key={idx + 1}
          onClick={() => handlePageChange(idx + 1)}
          className={currentPage === idx + 1 ? styles.active : ""}
        >
          {idx + 1}
        </button>
      ))}
      <button
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        {">"}
      </button>
    </div>
  );

  const renderAdvance = () => {
    const firstPage = 1;
    const lastPage = totalPages;
    const maxVisiblePages = Math.min(4, totalPages); // Adjust maxVisiblePages based on totalPages

    const visiblePages = [];

    // Show pages based on currentPage and totalPages
    if (totalPages <= maxVisiblePages) {
      // If totalPages is less than or equal to maxVisiblePages, show all pages
      visiblePages.push(
        ...Array.from({ length: totalPages }, (_, idx) => idx + 1)
      );
    } else if (currentPage < maxVisiblePages) {
      // Show first few pages when currentPage is near the start
      visiblePages.push(
        ...Array.from({ length: maxVisiblePages }, (_, idx) => idx + 1)
      );
    } else if (currentPage > totalPages - maxVisiblePages + 1) {
      // Show the last few pages when near the end
      visiblePages.push(
        ...Array.from(
          { length: maxVisiblePages },
          (_, idx) => totalPages - maxVisiblePages + idx + 1
        )
      );
    } else {
      // Show current page in the middle with adjacent pages
      visiblePages.push(currentPage - 1, currentPage, currentPage + 1);
    }

    return (
      <div className={styles.advance}>
        {/* First Page Button */}
        <button
          className={styles.jumpFirstButton}
          onClick={() => handlePageChange(firstPage)}
          disabled={currentPage === firstPage}
        >
          {"<<"}
        </button>

        {/* Always show first page */}
        <button
          onClick={() => handlePageChange(firstPage)}
          className={currentPage === firstPage ? styles.active : ""}
        >
          {firstPage}
        </button>

        {/* Dots if current page is beyond the first few pages */}
        {currentPage > maxVisiblePages + 1 && totalPages > maxVisiblePages && (
          <span>...</span>
        )}

        {/* Middle Page Numbers */}
        {visiblePages.map(
          (page) =>
            page !== firstPage &&
            page !== lastPage && (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={currentPage === page ? styles.active : ""}
              >
                {page}
              </button>
            )
        )}

        {/* Dots if current page is before the last few pages */}
        {currentPage < totalPages - maxVisiblePages &&
          totalPages > maxVisiblePages && <span>...</span>}

        {/* Always show last page */}
        {totalPages > 1 && (
          <button
            onClick={() => handlePageChange(lastPage)}
            className={currentPage === lastPage ? styles.active : ""}
          >
            {lastPage}
          </button>
        )}

        {/* Last Page Button */}
        <button
          className={styles.jumpLastButton}
          onClick={() => handlePageChange(lastPage)}
          disabled={currentPage === lastPage}
        >
          {">>"}
        </button>

        {/* Input field for jumping to a page */}
        <input type="number" value={inputPage} onChange={handleInputChange} />
        <button onClick={handleInputSubmit}>Go</button>
      </div>
    );
  };
  if (!currentPage || !totalPages || currentPage < 1 || totalPages < 1) {
    return null;
  }

  return (
    <div>
      {type === "simple" && renderSimple()}
      {type === "basic" && renderBasic()}
      {type === "advance" && renderAdvance()}
    </div>
  );
};

export default Pagination;
