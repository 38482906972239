import React from "react";
import Sidebar from "../Components/Sidebar";
import Purchase from "../Components/Purchase";
import NewSidebar from "../Components/NewSidebar";

const PurchasePage = () => {
  return (
    <>
      <div className="main_container">
        <Purchase />
      </div>
    </>
  );
};

export default PurchasePage;
