import React from "react";
import Settingsbanner from "./SettingsBanner";
import SettingsContent from "./SettingsContent";

const Settings = () => {
    return (
        <>
            <Settingsbanner />
            <SettingsContent />
        </>
    );
};

export default Settings;
