import { createSlice } from '@reduxjs/toolkit';

const propertiesSlice = createSlice({
  name: 'properties',
  initialState: {
    list: [],
  },
  reducers: {
    setPropertiesList(state, action) {
      state.list = action.payload;
    },
  },
});

export const { setPropertiesList } = propertiesSlice.actions;
export default propertiesSlice.reducer;