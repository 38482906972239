import React from "react";
import AddProperty from "../Components/AddProperty";

const AddPropertyPage = () => {
  return (
    <>
      <div className="main_container">
        <AddProperty />
      </div>
    </>
  );
};

export default AddPropertyPage;
