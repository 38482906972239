import React, { useCallback, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
  ChoiceList,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";

const AddDiscount = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
    const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];
  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag

  const [selectedTags, setSelectedTags] = useState([
    "Rustic",
    "Antique",
    "Vinyl",
    "Refurbished",
    "Singh",
  ]);
  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    []
  );
  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const navigate = useNavigate();

  const [discountMethodSelected, setDiscountMethodSelected] = useState([
    "Discount Code",
  ]);

  const discountMethodSelectedHandleChange = useCallback(
    (value) => setDiscountMethodSelected(value),
    []
  );

  // renderMinimumPurchaseRequirementChildren
  const [
    selectedMinimumPurchaseRequirement,
    setSelectedMinimumPurchaseRequirement,
  ] = useState(["none"]);

  const [
    textMinimumPurchaseRequirementFieldValue,
    setTextMinimumPurchaseRequirementFieldValue,
  ] = useState("");

  const handleChoiceListChange = useCallback(
    (value) => setSelectedMinimumPurchaseRequirement(value),
    []
  );

  const handleTextFieldChange = useCallback(
    (value) => setTextMinimumPurchaseRequirementFieldValue(value),
    []
  );

  const renderMinimumPurchaseRequirementChildren = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          label="Minimum purchase amount ($)"
          labelHidden
          onChange={handleTextFieldChange}
          value={textMinimumPurchaseRequirementFieldValue}
          autoComplete="off"
        />
      ),
    [handleTextFieldChange, textMinimumPurchaseRequirementFieldValue]
  );

  const [selectedChoice, setSelectedChoice] = useState([
    "Limit number of times this discount can be used in total",
  ]);
  const [textFieldValue, setTextFieldValue] = useState("");

  const handleChoiceListChangeChoice = useCallback(
    (value) => setSelectedChoice(value),
    []
  );

  const handleTextFieldChangeChoice = useCallback(
    (value) => setTextFieldValue(value),
    []
  );

  const renderChildren = useCallback(
    (isSelected) =>
      isSelected && (
        <div style={{ width: "100px" }}>
          <TextField
            prefix="$"
            label="Minimum Quantity"
            labelHidden
            onChange={handleTextFieldChangeChoice}
            value={textFieldValue}
            autoComplete="off"
          />
        </div>
      ),
    [handleTextFieldChangeChoice, textFieldValue]
  );

  return (
    <>
      <div className="edit-rooms-full-container">
        <div>
          <Page
            backAction={{ content: "Products", url: `/roomstype` }}
            title="Create Discount"
            compactTitle
            pagination={{
              hasPrevious: true,
              hasNext: true,
            }}
          >
            <div className="edit-rooms-full-container-div">
              <div className="edit-rooms-full-container-left">
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "591px",
                  }}
                >
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle1}
                          ariaExpanded={open1}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Method</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open1}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Discount Code",
                                      value: "Discount Code",
                                    },
                                    {
                                      label: "Automatic Discount",
                                      value: "Automatic Discount",
                                    },
                                  ]}
                                  selected={discountMethodSelected}
                                  onChange={discountMethodSelectedHandleChange}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                                width: "100%",
                              }}
                              className=""
                            >
                              <div style={{ width: "450px" }} className="">
                                <TextField
                                  className="textfield"
                                  focused={false}
                                  label="Discount Code"
                                  onChange={handleChange}
                                  autoComplete="off"
                                />
                              </div>
                              <div>
                                <BgThemeButton children={"Generate"} />
                              </div>
                            </div>
                            <p
                              style={{
                                marginTop: "10px",
                                color: "#888",
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              Customers must enter this code at checkout.
                            </p>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle2}
                          ariaExpanded={open2}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Value</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open2}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Percentage",
                                      value: "Percentage",
                                    },
                                    {
                                      label: "Fixed Amount",
                                      value: "Fixed Amount",
                                    },
                                  ]}
                                  selected={discountMethodSelected}
                                  onChange={discountMethodSelectedHandleChange}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                                width: "100%",
                              }}
                              className=""
                            >
                              <div style={{ width: "100%" }} className="">
                                <TextField
                                  className="textfield"
                                  focused={false}
                                  label="Discount Code"
                                  onChange={handleChange}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <p
                              style={{
                                marginTop: "10px",
                                color: "#888",
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              Customers must enter this code at checkout.
                            </p>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle3}
                          ariaExpanded={open3}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Minimum purchase requirements</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open3}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <ChoiceList
                                  title="Discount minimum requirements"
                                  choices={[
                                    {
                                      label: "No minimum requirement",
                                      value: "No minimum requirement",
                                    },
                                    {
                                      label: "Minimum purchase amount ($)",
                                      value: "Minimum purchase",
                                      renderMinimumPurchaseRequirementChildren,
                                    },
                                  ]}
                                  selected={selectedMinimumPurchaseRequirement}
                                  onChange={handleChoiceListChange}
                                />
                              </div>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle4}
                          ariaExpanded={open4}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Customer eligibility</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open4}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-container">
                            <div className="form-container-div-full">
                              <ChoiceList
                                title="Discount minimum requirements"
                                choices={[
                                  {
                                    label: "All customers",
                                    value: "all_customers",
                                  },
                                  {
                                    label: "Specific customer segments",
                                    value: "Specific customer segments",
                                  },
                                  {
                                    label: "Specific customers",
                                    value: "Specific customers",
                                  },
                                ]}
                                selected={selected}
                                onChange={handleChoiceListChange}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                                width: "100%",
                              }}
                              className=""
                            >
                              <div style={{ width: "450px" }} className="">
                                <TextField
                                  className="textfield"
                                  focused={false}
                                  placeholder="Search Customer Segments"
                                  onChange={handleChange}
                                  autoComplete="off"
                                />
                              </div>
                              <div>
                                <BgThemeButton children={"Browse"} />
                              </div>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle5}
                          ariaExpanded={open5}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Maximum Discount Uses</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open5}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <ChoiceList
                                  title="Discount minimum requirements"
                                  choices={[
                                    {
                                      label:
                                        "Limit number of times this discount can be used in total",
                                      value:
                                        "Limit number of times this discount can be used in total",
                                      renderChildren,
                                    },
                                    {
                                      label: "Limit to one use per customer",
                                      value: "Limit to one use per customer",
                                    },
                                  ]}
                                  selected={selectedChoice}
                                  onChange={handleChoiceListChangeChoice}
                                />
                              </div>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle6}
                          ariaExpanded={open6}
                          ariaControls="basic-collapsible"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          <div>Combinations</div>

                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open6}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#333",
                              marginBottom: "10px",
                            }}
                          >
                            This discount can be combined with:
                          </p>
                          <div className="edit-rooms-form-container">
                            <div className="form-container-div-full">
                              <ChoiceList
                                allowMultiple
                                choices={[
                                  {
                                    label: "Discount Code",
                                    value: "Discount Code",
                                  },
                                  {
                                    label: "Automatic Discount",
                                    value: "Automatic Discount",
                                  },
                                  {
                                    label: "Automatic Discount",
                                    value: "Automatic",
                                  },
                                ]}
                                selected={discountMethodSelected}
                                onChange={discountMethodSelectedHandleChange}
                              />
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle9}
                          ariaExpanded={open9}
                          ariaControls="basic-collapsible"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          <div>Active dates</div>

                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open9}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <TextField
                                type="date"
                                className="textfield"
                                focused={false}
                                label="Start Date"
                                onChange={handleChange}
                                autoComplete="off"
                              />
                            </div>
                            <div className="form-container-div">
                              <TextField
                                type="time"
                                className="textfield"
                                focused={false}
                                label="Start Time"
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="Enter Address"
                              />
                            </div>
                          </div>
                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <TextField
                                type="date"
                                className="textfield"
                                focused={false}
                                label="End Date"
                                onChange={handleChange}
                                autoComplete="off"
                              />
                            </div>
                            <div className="form-container-div">
                              <TextField
                                type="time"
                                className="textfield"
                                focused={false}
                                label="End Time"
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="Enter Address"
                              />
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                </form>
                <div className="rooms-edit-left-container-buttons">
                  <div>
                    <BgThemeButton children={"Update"} />
                  </div>
                  <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                    <BgOutlineThemeButton children={"Discard"} />
                  </div>
                </div>
              </div>

              <div className="edit-rooms-full-container-right">
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle7}
                          ariaExpanded={open7}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Summary</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open7}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div>
                            <p
                              style={{
                                marginBottom: "25px",
                                fontSize: "14px",
                                color: "#888",
                                fontWeight: "500",
                              }}
                            >
                              No discount code yet
                            </p>

                            <ul
                              style={{
                                marginBottom: "25px",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "500",
                              }}
                            >
                              <span
                                style={{
                                  marginBottom: "25px",
                                }}
                              >
                                Type and method
                              </span>
                              <div
                                style={{
                                  marginBottom: "25px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  marginLeft: "30px",
                                }}
                              >
                                <li>Amount off Discount</li>
                                <li>Code</li>
                              </div>
                            </ul>
                            <ul
                              style={{
                                marginBottom: "25px",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "500",
                              }}
                            >
                              Details
                              <div
                                style={{
                                  marginBottom: "25px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  marginLeft: "30px",
                                }}
                              >
                                <li>Can’t combine with other discounts</li>
                              </div>
                            </ul>

                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "500",
                              }}
                            >
                              Performance
                              <p
                                style={{
                                  marginBottom: "25px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                Discount is not active yet
                              </p>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle8}
                          ariaExpanded={open8}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Sales & Channel</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open8}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-container">
                            <div className="form-container-div-full">
                              <ChoiceList
                                choices={[
                                  {
                                    label: "Discount Code",
                                    value: "Discount Code",
                                  },
                                  {
                                    label: "Automatic Discount",
                                    value: "Automatic Discount",
                                  },
                                ]}
                                selected={discountMethodSelected}
                                onChange={discountMethodSelectedHandleChange}
                              />
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                  {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "300px",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      Pricing
                      <Button
                        onClick={handleToggle9}
                        ariaExpanded={open9}
                        ariaControls="basic-collapsible"
                      >
                        <Icon source={ChevronDownMinor} />
                      </Button>
                    </div>
                    <Collapsible
                      open={open9}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Rack Price"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Extra Adults"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Child with Bed"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div">
                          <TextField
                            className="textfield"
                            focused={false}
                            label="Child Without Bed"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
                </form>
              </div>
            </div>
          </Page>
        </div>
      </div>
    </>
  );
};

export default AddDiscount;
