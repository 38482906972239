import React, { useState, useCallback } from "react";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import CustomToggleButton from "../UI/CustomToggleButton";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";

const ProfileSetting = () => {
    const [openDayStatus, setOpenDayStatus] = useState();
    const openDayStatusHandler = useCallback((data) => {
        setOpenDayStatus(data);
    }, []);
    return (
        <>
            <form className="profile_tab_form">
                <h3>General Details</h3>
                <div className="edit-rooms-form-wrapper-container">
                    <div className="form-container-div-half">
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                required={true}
                                name="property_name"
                                focused={false}
                                titleName="Legal Property Name"
                                autoComplete="off"
                                placeholder={"Golden Fern Resort"}
                            />
                        </div>
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                required={true}
                                type={"number"}
                                name="phone_number"
                                focused={false}
                                titleName="Phone Number"
                                autoComplete="off"
                                placeholder={"77788-89990"}
                            />
                        </div>
                    </div>
                    <div className="form-container-div-half">
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                required={true}
                                type={"email"}
                                name="email"
                                focused={false}
                                titleName="Email Address"
                                autoComplete="off"
                                placeholder={"example@email.com"}
                            />
                        </div>
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                required={true}
                                type={"number"}
                                name="room_view_type"
                                focused={false}
                                titleName="Landline Number"
                                autoComplete="off"
                                placeholder={"777-778889990"}
                            />
                        </div>
                    </div>
                    <div className="form-container-div-half">
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                required={true}
                                name="propeerty_type"
                                focused={false}
                                titleName="Property Type"
                                autoComplete="off"
                                placeholder={"Select"}
                            />
                        </div>
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                required={true}
                                type={"number"}
                                name="room_view_type"
                                focused={false}
                                titleName="Landline Number"
                                autoComplete="off"
                                placeholder={"777-778889990"}
                            />
                        </div>
                    </div>
                    <div className="form-container-div-half">
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                type="date"
                                name="check_in"
                                className="textfield"
                                focused={false}
                                titleName="Check-In"
                                autoComplete="off"
                            />
                        </div>
                        <div className="form-container-div">
                            <VerticalInputFieldCustom
                                type="date"
                                name="check_out"
                                className="textfield"
                                titleName="Check-Out"
                                focused={false}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
            </form>
            <div className="profile_bottom">
                <div className="profile_bottom_left">
                    <div className="status_sec">
                        <div>
                            Status
                        </div>
                        <div>
                            <CustomToggleButton
                                func={openDayStatusHandler}
                                positive={"Open"}
                                negative={"Closed"}
                                toggleTextStyle={{
                                    color: "#15AA12",
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                }}
                            />
                        </div>
                    </div>
                    <div className="status_sec">
                        <div className="edit-rooms-form-wrapper-container" style={{ width: '100%' }}>
                            <div className="edit-rooms-form-container" >
                                <div className="form-container-div-full" >
                                    <VerticalInputFieldCustom
                                        name="customer_name"
                                        className="textfield"
                                        titleName="Channel Manager Hotel ID"
                                        focused={false}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex_gap_10">
                    <BgThemeButton children={"Update"} />
                    <BgOutlineThemeButton children={"Discard"} />
                    </div>
                  
                </div>
                <div className="profile_bottom_right">
                    <div className="status_sec">
                        <h3>Bill Prefix</h3>
                        <div className="edit-rooms-form-wrapper-container" style={{ width: '100%' }}>
                            <div className="edit-rooms-form-container" >
                                <div className="form-container-div-full" >
                                    <VerticalInputFieldCustom
                                        name="customer_name"
                                        className="textfield"
                                        titleName="Booking Prefix"
                                        focused={false}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-container-div-full" >
                                    <VerticalInputFieldCustom
                                        name="customer_name"
                                        className="textfield"
                                        titleName="Food Prefix"
                                        focused={false}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ProfileSetting;
