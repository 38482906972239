import React from "react";
import Sidebar from "../Components/Sidebar";
import RoomPlans from "../Components/RoomPlans";
import NewSidebar from "../Components/NewSidebar";

const RoomsPlanPage = () => {
  return (
    <>
      <div className="main_container">
        <RoomPlans />
      </div>
    </>
  );
};

export default RoomsPlanPage;
