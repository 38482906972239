// React Imports
import * as React from "react";

// Polaris Imports
import { LineChart } from "@shopify/polaris-viz";
import { Card, Icon } from "@shopify/polaris";
import "@shopify/polaris-viz/build/esm/styles.css";
import { ArrowDownMinor, ArrowUpMinor } from "@shopify/polaris-icons";

const LineChartDashboard = () => {
  return (
    <div style={{ position: "relative" }}>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            fontFamily: "Inter",
            paddingBottom: "30px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderRadius: "4px",
              padding: "12px",
              background: "#EFF0F2",
            }}
          >
            <p>Total Sales</p>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                borderRadius: "4px",
                color: "#333",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span> $8000</span>

              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  borderRadius: "4px",
                  padding: "12px",
                  color: "#15AA12",
                  display: "flex",
                }}
              >
                <Icon source={ArrowUpMinor} color="base" />
                <span>40%</span>
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderRadius: "4px",
              padding: "12px",
              background: "#EFF0F2",
            }}
          >
            <p>Rooms Sales</p>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                borderRadius: "4px",
                color: "#333",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span> $4500</span>

              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  borderRadius: "4px",
                  padding: "12px",
                  color: "#15AA12",
                  display: "flex",
                }}
              >
                <Icon source={ArrowUpMinor} color="base" />
                <span>36%</span>
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderRadius: "4px",
              padding: "12px",
              background: "#EFF0F2",
            }}
          >
            <p>Food Sales</p>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                borderRadius: "4px",
                color: "#333",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span> $2500</span>

              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  borderRadius: "4px",
                  padding: "12px",
                  color: "red",
                  display: "flex",
                }}
              >
                <Icon source={ArrowDownMinor} color="base" />
                <span>12%</span>
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderRadius: "4px",
              padding: "12px",
              background: "#EFF0F2",
            }}
          >
            <p>Misc. Sales</p>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                borderRadius: "4px",
                color: "#333",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span> $8000</span>

              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  borderRadius: "4px",
                  padding: "12px",
                  color: "red",
                  display: "flex",
                }}
              >
                <Icon source={ArrowDownMinor} color="base" />
                <span>04%</span>
              </span>
            </div>
          </div>
        </div>

        <LineChart
          theme="Light"
          isAnimated={true}
          data={[
            {
              name: "July 28, 2023",
              data: [
                { value: 7, key: "08:00AM" },
                { value: 17, key: "11:00AM" },
                { value: 24, key: "02:00PM" },
                { value: 18, key: "05:00PM" },
                { value: 9, key: "08:00PM" },
                { value: 15, key: "11:00PM" },
              ],
            },
            {
              name: "July 28, 2023",
              data: [
                { value: 32, key: "08:00AM" },
                { value: 36, key: "11:00AM" },
                { value: 28, key: "02:00PM" },
                { value: 30, key: "05:00PM" },
                { value: 28, key: "08:00PM" },
                { value: 22, key: "11:00PM" },
              ],
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default LineChartDashboard;
