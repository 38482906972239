import React, { useState } from "react";
import { BgThemeButton, WithoutBgButtonBlue } from "../UI/Buttons";
import ProfileSetting from "./ProfileSetting";
import LocationSetting from "./LocationSetting";

const SettingsContent = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabs = [
        {
            title: 'Profile',
            content: <ProfileSetting />,
        },
        {
            title: 'Location',
            content: <LocationSetting />,
        },
        {
            title: 'Roles & Permissions',
            content: <p>This is the content of Tab 3.</p>,
        },
        {
            title: 'Brand',
            content: <p>This is the content of Tab 3.</p>,
        },
        {
            title: 'Billing',
            content: <p>This is the content of Tab 3.</p>,
        },
    ];

    return (
        <>
            <div className="settings_sec">
                <div className="container">
                    <div className="settings_main">
                        <div className="settings_sec_left">
                            <div className="settings_sec_left_image">
                                <p>G</p>
                                <div className="image_overlay">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_1623_3753)">
                                            <path d="M18.557 1.75389L23.2468 6.44464L24.0289 5.66233C25.3237 4.36722 25.3237 2.26773 24.0289 0.972615C22.7337 -0.322903 20.6334 -0.322903 19.3382 0.972615L18.557 1.75389Z" fill="white" />
                                            <path d="M21.0935 8.59342L16.4038 3.90267L1.5223 18.7849L0 24.9983L6.21202 23.4757L21.0935 8.59342Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1623_3753">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                            <h3>Golden Fern Resort</h3>
                            <p className="settings_sec_left_address">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <g clip-path="url(#clip0_1646_4130)">
                                        <path fillRule="evenodd" clip-rule="evenodd" d="M13.0999 6.1C13.0999 3.006 10.5939 0.5 7.4999 0.5C4.4059 0.5 1.8999 3.006 1.8999 6.1C1.8999 6.205 1.8999 6.303 1.9069 6.408C1.9979 8.893 3.2999 11.742 6.8979 14.311C7.2549 14.563 7.7449 14.563 8.1019 14.311C11.6999 11.742 13.0019 8.893 13.0929 6.408C13.0999 6.30302 13.0999 6.20498 13.0999 6.1ZM8.9846 7.5847C9.3787 7.1913 9.5999 6.6572 9.5999 6.1C9.5999 5.5428 9.378 5.0094 8.9846 4.6153C8.5912 4.2212 8.0571 4 7.4999 4C6.9427 4 6.4093 4.2219 6.0152 4.6153C5.6211 5.0087 5.3999 5.5428 5.3999 6.1C5.3999 6.6572 5.6211 7.1913 6.0152 7.5847C6.4086 7.9788 6.9427 8.2 7.4999 8.2C8.0564 8.2 8.5905 7.9788 8.9846 7.5847Z" fill="#888888" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1646_4130">
                                            <rect width="14" height="14" fill="white" transform="translate(0.5 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Shimla
                            </p>
                            <div className="flex justify_content_end">
                                <button className="settings_sec_left_edit_btn">Edit</button>
                            </div>
                            <div className="settings_sec_left_content flex justify_content_between">
                                <h5>Total Rooms</h5>
                                <h4>35</h4>
                            </div>
                            <div className="settings_sec_left_content flex justify_content_between">
                                <h5>Average Rate</h5>
                                <h4>INR 4500</h4>
                            </div>
                        </div>
                        <div className="settings_sec_right">
                            <div className="custom_tab_container">
                                <div className="custom_tab_header">
                                    {tabs.map((tab, index) => (
                                        <h3
                                            key={index}
                                            className={`tab_item ${index === activeTab ? 'active' : ''}`}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            {tab.title}
                                        </h3>
                                    ))}
                                </div>
                                <div className="custom-tab-content">
                                    {tabs[activeTab].content}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsContent;
