import React from "react";
import Sidebar from "../Components/Sidebar";
import Segments from "../Components/Segments";
import NewSidebar from "../Components/NewSidebar";

const SegmentPage = () => {
  return (
    <>
      <div className="main_container">
        <Segments />
      </div>
    </>
  );
};

export default SegmentPage;
