import React from "react";
import Sidebar from "../Components/Sidebar";

import EditAgentProfile from "../Components/EditAgentProfile";
import NewSidebar from "../Components/NewSidebar";
import CreateBooking from "../Components/CreateBooking";

const CreateBookingPage = () => {
  return (
    <>
      <div className="main_container">
        <CreateBooking />
      </div>
    </>
  );
};

export default CreateBookingPage;
